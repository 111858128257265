import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-register-player',
  templateUrl: './dialog-confirm-register-player.component.html',
  styleUrls: ['./dialog-confirm-register-player.component.scss']
})
export class DialogConfirmRegisterPlayerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmRegisterPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  async ngOnInit(): Promise<void> {
    // await this.redirect();
  }

  async close(): Promise<void> {
    this.dialogRef.close();
  }

  private async redirect(): Promise<void> {
    if (this.data.url) {
      setTimeout(async() => {
        await this.close();
        window.location.reload();
      }, 3000);
    }
  }

}
