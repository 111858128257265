import { gql } from 'apollo-angular';
import { FRAGMENT_PET } from '@graphql/fragments/pet/fragment.graphql';

/**
 * $NFT Wallet
 * $NFT Stages
 */

/*-------------------------------------------------------------------------*\
  $NFT Wallet
/*-------------------------------------------------------------------------*/

////////// By animal type

export const getNFTStageInWallet = gql`
    query getNFTStageInWallet($wallet: String!, $ageStage: Int!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInWallet(where: { ageStage: $ageStage }) {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// By NFT type and tokenId

export const getNFTStageInWalletAndToken = gql`
    query getNFTStageInWalletAndToken($wallet: String!, $ageStage: Int!, $tokenId: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInWallet(where: { ageStage: $ageStage, tokenId: $tokenId }) {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// All stored in wallet

export const getAllNFTInWallet = gql`
    query getAllNFTInWallet($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInWallet {
                ...fragmentPet,
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// Count pets in wallet

export const getCountNFTInWallet = gql`
    query getCountNFTInWallet($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInPark {
                id
            }
            petsInSchool {
                id
            }
        }
    }
`;

/*-------------------------------------------------------------------------*\
  $NFT Stages
/*-------------------------------------------------------------------------*/

export const getNFTInIncubator = gql`
    query getNFTInIncubator($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInIncubator {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;

export const getNFTInPark = gql`
    query getNFTInPark($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInPark {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;

export const getNFTInSchool = gql`
    query getNFTInSchool($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInSchool {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;

export const getNFTInGym = gql`
    query getNFTInGym($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInGym {
                ...fragmentPet
            }
        }
    }
    ${ FRAGMENT_PET }
`;