<h2>
    {{ data.title | translate }}
    <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>
<div class="container-dialog">
    <p translate>{{ data.message }}</p>

    <ul class="stats-list">
        <li class="stat-strength" [class.is-active]="selectedStat === 'Strength'" [class.is-disabled]="!data.counterByStats?.Strength" (click)="changeStatSelected('Strength')">
            <img src="assets/images/game/stats/strength.svg" alt="{{ 'actions.sort.strength' | translate }}"> 
            <strong translate>stats.strength</strong>
            <span class="txt-counter">{{ data.counterByStats?.Strength }}</span>
            <span class="stat-select">
                <mat-icon class="icon">check</mat-icon>
            </span>
        </li>
        <li class="stat-speed" [class.is-active]="selectedStat === 'Speed'" [class.is-disabled]="!data.counterByStats?.Speed" (click)="changeStatSelected('Speed')">
            <img src="assets/images/game/stats/speed.svg" alt="{{ 'actions.sort.speed' | translate }}"> 
            <strong translate>stats.speed </strong>
            <span class="txt-counter">{{ data.counterByStats?.Speed }}</span>
            <span class="stat-select">
                <mat-icon class="icon">check</mat-icon>
            </span>
        </li>
        <li class="stat-flexibility" [class.is-active]="selectedStat === 'Flexibility'" [class.is-disabled]="!data.counterByStats?.Flexibility" (click)="changeStatSelected('Flexibility')">
            <img src="assets/images/game/stats/flexibility.svg" alt="{{ 'actions.sort.flexibility' | translate }}">
            <strong translate>stats.flexibility</strong> 
            <span class="txt-counter">{{ data.counterByStats?.Flexibility }}</span>
            <span class="stat-select">
                <mat-icon class="icon">check</mat-icon>
            </span>
        </li>
        <li class="stat-endurance" [class.is-active]="selectedStat === 'Endurance'" [class.is-disabled]="!data.counterByStats?.Endurance" (click)="changeStatSelected('Endurance')">
            <img src="assets/images/game/stats/endurance.svg" alt="{{ 'actions.sort.endurance' | translate }}">
            <strong translate>stats.endurance</strong>
            <span class="txt-counter">{{ data.counterByStats?.Endurance }}</span>
            <span class="stat-select">
                <mat-icon class="icon">check</mat-icon>
            </span>
        </li>
    </ul>

    <button appPreventDefault class="btn-confirm" (click)="doClaim()" [disabled]="selectedStat === ''" translate>
        actions.confirm
    </button>
</div>