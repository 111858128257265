<h2 [class.is-error]="data.type === 'error'">
    <span translate [translateParams]="{ name: nft.metadata.uriString.name }" *ngIf="nft?.metadata; else tplTitle">
      {{ data.title }}
    </span>
    <img *ngIf="data.type !== 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
    <img *ngIf="data.type === 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-white.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
  </h2>
  <p translate [translateParams]="{
      name: nft?.metadata?.uriString?.name || 'Eggy',
      limit: data?.limit,
      minXP: data?.minXP,
      statGained: data?.statGained
    }" *ngIf="nft?.metadata; else tplMessage">
    {{ data.message }}
  </p>
  
  <!-- Templates -->
  
  <ng-template #tplTitle>
    <span translate>{{ data.title }}</span>
  </ng-template>
  
  <ng-template #tplMessage>
    <p translate [translateParams]="{ limit: data?.limit }">{{ data.message }}</p>
  </ng-template>
  