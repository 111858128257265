import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConnectionService } from '@services/connection/connection/connection.service';
import { HttpService } from '@services/http/http.service';
import { BEP20Contract, OraclePricesContract } from '@static/abi';
import { contractAddresses } from '@static/contract-address';

@Injectable({
  providedIn: 'root'
})
export class OracleApiService {
  
  private readonly api: { [key: string]: string } = {
    host: 'https://bsc.api.0x.org/swap/v1/price?sellToken=',
    params: '&buyToken=BUSD&sellAmount=1000000000000000000'
  };

  constructor(
    private connectionService: ConnectionService,
    private httpService: HttpService
  ) { }

  /*
  public getTokenPriceOnBusd(tokenAddress: string): Observable<any> | void {
    const api = this.api;
    
    if (tokenAddress !== BEP20Contract.busd) {
      if (tokenAddress === '0x0000000000000000000000000000000000000000') {
        return this.httpService.getAll(api.host + 'BNB' + api.params);
      } else {
        return this.httpService.getAll(api.host + '0xF700D4c708C2be1463E355F337603183D20E0808' + api.params);
      }
    }
  }
  */

  async convertBUSDToBAMBOO(amount: string): Promise<void> {
    return await this.connectionService.readContract(
      contractAddresses.oraclePrices,
      OraclePricesContract.abi,
      'convertBUSDToBAMBOO',
      [ amount ]
    );
  }

  async convertBUSDToBNB(amount: string): Promise<void> {
    return await this.connectionService.readContract(
      contractAddresses.oraclePrices,
      OraclePricesContract.abi,
      'getBNBAmountByStable',
      [ amount ]
    );
  }

}
