import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, of } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private readonly api = environment.api;

  constructor(
    private http: HttpClient
  ) { }

  getAll(url: string): Observable<Object> {
    return this.http.get(`${ this.api }/${ url }`);
  }

  getOne(url: string, options = {}): Observable<Object> {
    return this.http.get(`${ this.api }/${ url }`, options);
  }

  getTokenURI(url: string, options = {}): Observable<Object> {
    return this.http.get(url, options);
  }

  save(url: string, options = {}, headers = {}, excludeApiDefault = false): Observable<Object> {
    const path = (excludeApiDefault) ? url : `${ this.api }/${ url }`;
    return this.http.post(path, options, headers);
  }

  async add(url: string, options = {}, headers = {}, excludeApiDefault = false): Promise<any> {
    const path = (excludeApiDefault) ? url : `${ this.api }/${ url }`;
    const http$ = this.http.post(path, options, headers)
                      .pipe(catchError(err => of(false)));
    return await lastValueFrom(http$);
  }

  async delete(url: string, options = {}): Promise<any> {
    const http$ = this.http.delete(`${ this.api }/${ url }`, options)
                      .pipe(catchError(err => of(false)));
    return await lastValueFrom(http$);
  }

  update(url: string, options = {}): Observable<Object> {
    return this.http.post(`${ this.api }/${ url }`, options);
  }

  testAPI(options = {}): Observable<Object> {
    return this.http.get(this.api, options);
  }

}
