/*------------------------------------------------------------------------------------*\
  $Phases
/*------------------------------------------------------------------------------------*/

export const phaseMessages: { [key: string]: string[] } = {
  databaseMessages: [
    'dialog.database.title',
    'dialog.database.message'
  ],
  depositMessages: [
    'depositSuccess',
  ],
  messages: [
    'actionNeeded',
    'approve',
    'approveIncubatorDeposit',
    'errorForwarding',
    'errorOnApprove',
    'errorUpgrading'
  ],
  phaseBuyMessages: [
    'dialog.burn.waitingForBurnTitle',
    'dialog.approve.title',
    'dialog.buyEggError',
    'dialog.buyEggErrorMessage',
    'dialog.buyWithBurnError'
  ]
};
