<h1>
  {{ 'dialog.waiting' | translate }}
  <img class="ico-close" alt="{{ 'actions.close' | translate }}" src="assets/images/buttons/close.png" (click)="close()">
</h1>
<video autoplay muted loop playsinline>
  <source type="video/mp4" [src]="'assets/videos/buy/' + videoUrl + '.mp4'">
  <!--
  <source *ngIf="!data.buyWithTickets" type="video/mp4" src="assets/videos/buy/waiting-gachapon-bnb.mp4" />
  <source *ngIf="data.buyWithTickets" type="video/mp4" type="video/mp4" class="gif" src="assets/videos/buy/waiting-gachapon-ticket.mp4" />
  -->
</video>
