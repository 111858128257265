import { Injectable } from '@angular/core';
import { waitForTransaction } from '@wagmi/core';

import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogNewEggComponent } from '@dialogs/dialog-new-egg/dialog-new-egg.component';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { TokenService } from '@services/contracts/token/token.service';
import { DialogService } from '@services/dialog/dialog.service';
import { OracleApiService } from '@services/oracle-api/oracle-api.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { contractAddresses } from '@static/contract-address';
import { OraclePricesContract, PetShopContract, PresaleContract } from '@static/abi';

/**
 * 
 * $Prices
 * $Presale
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class PresaleService {

  public dialogPresaleProcess: any;

  constructor(
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private oracleApiService: OracleApiService,
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) { }

  /*------------------------------------------------------------------------------------*\
    $Prices
  /*------------------------------------------------------------------------------------*/

  async getPresalePrices(type: number): Promise<void> {
    return await this.connectionService.readContract(
      contractAddresses.presale,
      PresaleContract.abi,
      'presaleConfigsMap',
      [ type ]
    );
  }

  /*---------------------------------------------------------------*\
    $Presale
  /*---------------------------------------------------------------*/

  async doPaymentSpecieEgg(petType: number, presaleType: number, presalePriceBNB: string): Promise<any> {
    const userAddress = this.connectionService.getWalletAddress();
    const price: any = await this.oracleApiService.convertBUSDToBNB(presalePriceBNB);
    console.log('PRESALE: Price BNB. ', price);
    console.log('PRESALE: Params', petType, presaleType, price);
    
    try {
      const respApprove: boolean = await this.tokenService.tokenApprovement(
        contractAddresses.presale, userAddress, contractAddresses.bamboo, price
      );
      if (respApprove) {
        this.dialogPresaleProcess = this.dialogService.openDialog(
          DialogConfirmationComponent,
          'confirmation-dialog-container',
          {
            title: 'dialog.presale.processing.title', 
            message: 'dialog.presale.processing.message', 
          }
        );
        try {
          const respContract = await this.connectionService.writeContract(
            contractAddresses.presale, PresaleContract.abi, 'buyEggWithTokens', [petType, presaleType], price
          );
          return respContract;
        } catch (error: any) {
          const message = this.walletMessagesService.getMessage(error.message, 'dialog.buyCancel.text');
          console.error('BUY NEW BABY PET', error.message);
          this.dialogPresaleProcess.close();
          this.dialogService.openDialog(
            DialogInfoComponent,
            'error-dialog-container',
            {
              type: 'error',
              title: 'dialog.buyCancel.title',
              message
            }
          );
        }
      }
    } catch (error: any) {
      console.error('BUY NEW BABY PET', error.message);
      /*
      this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.buyBabyPetError.title',
          message: 'dialog.buyBabyPetError.message'
        }
      );
      */
    }
  }

  async buySpecieEgg(selectedType: any, presale: number, presalePriceBNB: string): Promise<boolean> {
    console.log('BUY: Specie. ', selectedType, presale);
    const userAddress = this.connectionService.getWalletAddress();

    try {
      const tx = await this.doPaymentSpecieEgg(selectedType.id, presale, presalePriceBNB); 
      const resp = await waitForTransaction(tx);            
      if (resp) {
        this.dialogPresaleProcess.close();
        this.dialogService.openDialog(
          DialogNewEggComponent,
          'stat-dialog-container',
          {
            type: 'buy',
            presale: true
          }
        );
        /*this.dialogService.openDialog(
          DialogConfirmationComponent,
          'confirmation-dialog-container',
          {
            title: 'dialog.presale.success.title', 
            message: 'dialog.presale.success.message', 
          }
        );*/
        return true;
      }
      return false;
    } catch (error: any) {
      console.error('BUY NEW BABY PET', error.message);
      this.dialogPresaleProcess.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.buyEggPetError.title',
          message: 'dialog.buyEggPetError.message'
        }
      );
      return false;
    }
  }

}
