export const STATIC_WALLET_MESSAGES = [
    {
        key: 'wallet.userRejected.message',
        value: 'User denied transaction signature'
    },
    {
        key: 'wallet.userRejected.message',
        value: 'User rejected the request'
    },
    {
        key: 'wallet.incorrectOwner.message',
        value: 'transfer from incorrect owner'
    },
    {
        key: 'wallet.petNotSchool.message',
        value: 'Pet not in School'
    },
    {
        key: 'wallet.evolutionNotFinished.message',
        value: 'Evolution not finished'
    },
    {
        key: 'wallet.notWithDrawable.message',
        value: 'Not withdrawable'
    },
    {
        key: 'wallet.incubatorUsed.message',
        value: 'Incubator being used'
    },
    {
        key: 'wallet.machineUsed.message',
        value: 'Machine being used'
    },
    {
        key: 'wallet.exceedsAllowance.message',
        value: 'transfer amount exceeds allowance'
    },
    {
        key: 'wallet.connectorNotFound.message',
        value: 'Connector not found'
    },
    {
        key: 'wallet.cooldownFinished.message',
        value: 'Cooldown finished'
    },
    {
        key: 'wallet.cooldownNotFinished.message',
        value: 'Cooldown not finished'
    },
    /*
    {
        key: 'wallet.earlyWithdraw.message',
        value: 'Too early to withdraw'
    },
    */
    {
        key: 'wallet.exceedsBalance.message',
        value: 'transfer amount exceeds balance'
    },
    {
        key: 'wallet.petNoAvailable.message',
        value: 'Pet type not available'
    }
];