import { TournamentStats } from '@interfaces/tournaments/tournament.types';

/**
 * 
 * $Router
 * $Types
 * $Export
 * 
 */

/*------------------------------------------------------------------------------------*\
  $Router
/*------------------------------------------------------------------------------------*/

const STATIC_ROUTER_TOURNAMENT: { [key: string]: string } = {
  'Endurance': 'swimming-pool',
  'Flexibility': 'rhythmic-gym',
  'Speed': 'labyrinth',
  'Strength': 'javelin-launch'
};

/*------------------------------------------------------------------------------------*\
  $Types
/*------------------------------------------------------------------------------------*/

const STATIC_TOURNAMENT: { [key: string]: string }[] = [
  {
    key: '0x0452d00753257ac794da095def71382006f97a491943128f9887bf2b3325691e',
    type: 'Endurance',
  },
  {
    key: '0xac728c4377835e2431f74f271f68a8de29e488a901ba6f6135fab6c8eeff001c',
    type: 'Flexibility',
  },
  {
    key: '0x322699fbf55886a3cf36825530a2f9b443916f25dd4d7649f330f5e18db154b2',
    type: 'Speed',
  },
  {
    key: '0x7bf0253577963495efbbf3e98135c00ec91976256a9578916d37f29a5380bcb3',
    type: 'Strength',
  }
];

const STATIC_TYPES_TOURNAMENT: TournamentStats[] = ['Strength', 'Speed', 'Endurance', 'Flexibility'];

/*------------------------------------------------------------------------------------*\
  $Export
/*------------------------------------------------------------------------------------*/

export {
  STATIC_ROUTER_TOURNAMENT,
  STATIC_TYPES_TOURNAMENT,
  STATIC_TOURNAMENT
}