<div class="alert-info">
    <ng-container [ngTemplateOutlet]="tplNoResultsTournament" *ngIf="isResultTournament"></ng-container>
    <ng-container [ngTemplateOutlet]="tplDefault" *ngIf="!isResultTournament"></ng-container>
</div>

<ng-template #tplDefault>
    <ng-container *ngIf="NFTSize === 0">
        <h2>{{ 'noResults.title' | translate }}</h2>
        <p>
            {{ 'noResults.description' | translate }} 
            <span (click)="goTo()">{{ (isPresale ? 'noResults.presaleMachine' : 'noResults.eggMachine') | translate }} </span>
            {{ 'noResults.descriptionEnd' | translate }} 
        </p>
    </ng-container>
    <ng-container *ngIf="NFTSize > 0">
        <h2>{{ 'noResults.havePetsTitle' | translate }}</h2>
        <p>
            {{ 'noResults.havePets' | translate }} 
            <span (click)="openDialog()">
                {{ 'noResults.inventory' | translate }} 
            </span>
        </p>
    </ng-container>
</ng-template>

<ng-template #tplNoResultsTournament>
    <h2>{{ textData + '.title' | translate }}</h2>
    <p>{{ textData + '.message' | translate }}</p>
</ng-template>