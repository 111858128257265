import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@services/http/http.service';
import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  private account: string | undefined;
  private readonly collection = 'favorites';

  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {
    this.account = this.storageService.getAccount()?.toLowerCase();
  }

  /*------------------------------------------------------------------------------*\
   * $Methods
  /*------------------------------------------------------------------------------*/

  public getAllFavorites(): Observable<Object> {
    return this.httpService.getAll(`${ this.collection }/${ this.account }`);
  }

  public getFavorite(tokenId: number): Observable<Object> {
    return this.httpService.getOne(`${ this.collection }/${ this.account }/${ tokenId }`);
  }

  public async addFavorite(tokenId: number): Promise<boolean> {
    return await this.httpService.add(`${ this.collection }/${ this.account }/${ tokenId }`);
  }

  public async deleteFavorite(tokenId: number): Promise<boolean> {
    return await this.httpService.delete(`${ this.collection }/${ this.account }/${ tokenId }`);
  }

}
