<div class="lds-ellipsis" [class]="customClass">
    <div>
        <img src="/assets/icons/loader-egg.svg" alt="">
    </div>
    <div>
        <img src="/assets/icons/loader-egg.svg" alt="">
    </div>
    <div>
        <img src="/assets/icons/loader-egg.svg" alt="">
    </div>
    <div>
        <img src="/assets/icons/loader-egg.svg" alt="">
    </div>
</div>