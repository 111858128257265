import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultPet'
})
export class DefaultPetPipe implements PipeTransform {

  private readonly defaultImage = '/assets/images/game/pets/default.svg';

  transform(value: string | null): string {
    if (value && value.length > 0) {
      return value;
    } else {
      return this.defaultImage;
    }
  }

}
