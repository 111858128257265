import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MaxUint256 } from '@ethersproject/constants';
import { waitForTransaction } from '@wagmi/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { DialogInfoPopComponent } from '@dialogs/dialog-info-pop/dialog-info-pop.component';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { DialogService } from '@services/dialog/dialog.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { BEP20Contract, ERC721Contract, PetShopContract, TicketERC20Contract } from '@static/abi';
import { contractAddresses } from '@static/contract-address';
import { STATIC_TOKENS_AMOUNT } from '@static/tokens';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) { }

  /**
   * Gets balance of the given token
   * @param tokenAddress the address of the token to read
   * @returns : amount of balance
   */  
  async getBalanceOfToken(tokenAddress: string): Promise<any> {
    const userAddr = this.connectionService.getWalletAddress();
    try {
      // const userAddr = this.connectionService.getWalletAddress();
      return await this.connectionService.readContract(tokenAddress, BEP20Contract.abi,'balanceOf', [userAddr]);
    } catch (error: any) {
      if (!userAddr) {
        return this.translateService.instant('navigation.connectWallet');
      }
      const message = this.walletMessagesService.getMessage(error.message, 'dialog.errorBalance.message');
      this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.errorBalance.title',
          message
        }
      );
      return this.translateService.instant('insufficientLiquidity');
    }
  }

  /**
 * Gets balance of the given token
 * @param tokenAddress the address of the token to read
 * @param spenderAddress the address of the token to read
 * @returns : amount of balance
 */
  async getTokenAllowanceOnSpender(tokenAddress: string, spenderAddress: string): Promise<any> {
    try {
      const userAddr = this.connectionService.getWalletAddress();
      return await this.connectionService.readContract(tokenAddress, BEP20Contract.abi, 'allowance', [userAddr, spenderAddress]);
    } catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'dialog.errorAllowance.message');
      this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.errorAllowance.title',
          message
        }
      );
      console.error('TOKEN: Error token allowance', error);
    }
  } 
  
  /**
 * Gets the total supply of the given token
 * @param tokenAddress the address of the token to read
 * @returns : the total supply
 */
  async getTokenTotalSupply(tokenAddress: string): Promise<any> {
    try {
      return await this.connectionService.readContract(tokenAddress, BEP20Contract.abi, 'totalSupply', []);
    } catch (error: any) {
      console.error('TOKEN: getTokenTotalSupply', error);
    }
  }

  /**
 * Manage the approvement of an ERC20 token, check and if not done, do the approvement
 * @param spender : the contrat is going to spend tokens
 * @param userAddr : user address
 * @param tokenToSpend : token is goint to be spent
 * @returns : true if is approved
 */
  async tokenApprovement(
    spender: string,
    userAddr: string,
    tokenToSpend: number | string,
    amountOnWei: string
  ): Promise<boolean> {
    let isApproved = false;
    if (tokenToSpend !== 'BNB') {
      isApproved = await this.checkApproved(spender, userAddr, tokenToSpend, amountOnWei);
      if (!isApproved) {
        isApproved = await this.tokenApprove(spender, tokenToSpend);
      }
    } else {
      isApproved = true;
    }
    return isApproved;
  }

  /**
 * Check if spender is approved
 * @param spender : the contrat is going to spend tokens
 * @param userAddr : user address
 * @param tokenToSpend : token is goint to be spent
 * @returns : true if is approved
 */
  async checkApproved(
    spender: string,
    userAddr: string,
    tokenToSpend: any,
    amountOnWei: string
  ): Promise<any> {
    try {
      console.log('APPROVE: Amount on wei', amountOnWei);
      let allowance = await this.connectionService.readContract(
        //this.getTokenContractAddress(tokenToSpend),
        tokenToSpend,
        BEP20Contract.abi, 'allowance', [userAddr, spender]
      );

      console.log('ALLOWANCE: ', allowance);
      const pay = this.utilsService.fromWei(amountOnWei);
      const allowed = this.utilsService.fromWei(allowance);
      
      console.log('APPROVE: Pay', parseFloat(pay));

      if (parseFloat(pay) > parseFloat(allowed)) {
        console.log('APPROVE: not allowed!');
        return false;
      } else {
        console.log('APPROVE: Allowed!', parseFloat(allowed));
        return true;
      }
    } catch (error: any) {
      console.error('APPROVE: Check Approved Error', error.message);
      const message = this.walletMessagesService.getMessage(error.message, 'dialog.errorOnApprove.text');
      this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.errorOnApprove.title',
          message
        }
      );
    }
  }

  /**
   * Makes the approvement of the spender
   * @param spender : the contrat is going to spend tokens
   * @param userAddr : user address
   * @param tokenToSpend : token is goint to be spent
   * @returns : true if is approved
   */
  async tokenApprove(spender: string, tokenToSpend: number | string, amount?: string): Promise<any> {
    console.log('APPROVE: To spend tokens with address', tokenToSpend);
    console.log('APPROVE: Checking approvement of the contract', spender);
    const dialog = this.dialogService.openDialog(
      DialogInfoComponent,
      'info-dialog-container',
      { type: 'info', title: 'dialog.approve.title', message: 'dialog.approve.text' }
    );    
    let isApproved = false;
    try {
      let stringAmount = MaxUint256.toString();
      if (amount) {
        stringAmount = amount;
      }
      const contractAddress = this.getTokenContractAddress(tokenToSpend);
      let tx: any;
      tx = await this.connectionService.writeContract(contractAddress, BEP20Contract.abi, 'approve', [spender, stringAmount]);
      dialog.close();
      //dialogRef = this.dialogService.openRegularInfoDialog('allowance', 'waitTransaction', '');
      await waitForTransaction({ hash: tx.hash });
      //dialogRef.close();
      isApproved = true;
    } catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'upgrading.errorOnApprove.text');
      console.error('TOKEN APPROVE: Check Approved Error', error.message);
      dialog.close();
      const errorDialog = this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'upgrading.errorOnApprove.title',
          message
        }
      );    
    }
    return isApproved;
  }

  /**
 * Manage the approvement of an ERC721 token, check and if not done, do the approvement
 * @param spender : the contrat is going to spend tokens
 * @param tokenAddress : token is goint to be spent
 * @returns : true if is approved
 */
  async nftCheckAllowance(
    spender: string,
    tokenAddress: any,
    dialogRefProccess?: MatDialogRef<any>,
    dialogErrorMessages?: any
  ): Promise<boolean> {
    console.log('ALLOWANCE: Token address', tokenAddress);
    const userAddr = this.connectionService.getWalletAddress();
    let allowance = false;
    
    await this.connectionService.readContract(tokenAddress, ERC721Contract.abi, 'isApprovedForAll', [userAddr, spender]).then( async (res: any) => {
      console.log('ALLOWANCE: Nft allowance', res);
      if (res === false) {
        allowance = await this.nftAllowSpender(spender, tokenAddress, dialogRefProccess, dialogErrorMessages);
      } else {
        allowance = true;
      }
    });

    return allowance;
  }

  async checkTicketAllowance(): Promise<boolean> {
    const respTicket = await this.getTicketAmount();
    console.log('TICKETS: Amount', respTicket);
    console.log('ALLOWANCE: Ticket');
    const userAddress = this.connectionService.getWalletAddress();
    let allowance = false;
    const ticketContract = await this.connectionService.getReadContract(TicketERC20Contract.abi, contractAddresses.ticket);
    allowance = await this.checkTokenAllowance(userAddress, ticketContract, contractAddresses.petShop);
    if (!allowance) {
      const dialogInfo = this.dialogService.openDialog(
        DialogInfoPopComponent,
        'info-dialog-container',
        {
          type: 'info',
          phase: 'dialog.approve.title',
          animal: 'dialog.approve.text'
        }
      );
      allowance =  await this.allowToken(userAddress, ticketContract, contractAddresses.petShop);
      dialogInfo.close();
    }
    return allowance;
  }

  /**
 * Makes an approvement for all of the spender
 * @param spender : the contrat is going to spend tokens
 * @param contract : contract of the ERC721 token
 * @returns
 */
  async nftAllowSpender(
    spender: string,
    tokenAddress: any,
    dialogRef?: MatDialogRef<any>,
    dialogErrorMessages?: any
    ): Promise<any> {
    const dialog = dialogRef ?? 
    this.dialogService.openDialog(
      DialogInfoComponent,
      'info-dialog-container',
      { type: 'info', title: 'dialog.approve.title', message: 'dialog.approve.text' }
    ); 
    let allowance = false;
    try {
      const tx = await this.connectionService.writeContract(tokenAddress, ERC721Contract.abi, 'setApprovalForAll', [spender, true]);
      dialog.close();
      //dialog = this.dialogService.openRegularInfoDialog('allowance', 'waitTransaction', '');
      await waitForTransaction(tx);
      //dialog.close();
      allowance = true;
    } catch (error: any) {
      dialog.close();
      const message = this.walletMessagesService.getMessage(
        error.message,
        dialogErrorMessages.message ?? 'upgrading.errorOnApprove.text'
      );
      const errorDialog = this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: dialogErrorMessages.title ?? 'upgrading.errorOnApprove.title',
          message
        }
      );    
      allowance = false;
    }
    return allowance;
  }

  /**
 * Get ERC20 token contract by given token on number or string
 * @param token : token to get its contract
 * @returns : token contract
 */
  getTokenContractAddress(token: number | string): any {
    switch (token) {
      case 0:
        return contractAddresses.busd;
      case 'BUSD':
        return contractAddresses.busd;
      case 'BNB':
        return contractAddresses.bnb;
      case 'BAMBOO':
        return contractAddresses.bamboo;
      default:
        return token;
    }
  }

  async getDetailedData(address:string): Promise<any>{
    const data = await this.connectionService.getReadContract(BEP20Contract.abi, address);
    console.log('CONTRACT: Read', data);
    return data;
  }

  // Get Amount of Tickets of the user
  async getTicketAmount(): Promise<any> {
    const userAddr = this.connectionService.getWalletAddress();
    const contract: any = await this.connectionService.readContract(contractAddresses.ticket, TicketERC20Contract.abi, 'balanceOf', [userAddr]);
    const ticket = contract; // Number(this.utilsService.fromWei(amount._hex));
    STATIC_TOKENS_AMOUNT.ticket = ticket;
    return ticket;
  }

  async checkTokenAllowance(userAddr: string, contract: any, spender: string): Promise<boolean> {
    const allowance = await contract.allowance(userAddr, spender);
    return (allowance === '0') ? false : true;
  }

  async allowToken(userAddr: string, contract: any, spender: string): Promise<boolean> {
    let approved = false;
    try {
      const maxAmount = MaxUint256.toString();
      await contract.approve(spender, maxAmount).send({ from: userAddr });
      approved = true;
    } catch (error: unknown) {
      throw error;
    }
    return approved;
  }

}
