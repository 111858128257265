import BEP20Contract from '@abi/BEP20.json';
import ConfigStorageContract from '@abi/ConfigStorage.json';
import CreatureContract from '@abi/Pet.json';
import EnduranceContract from '@abi/EnduranceTournament.json';
import ERC721Contract from '@abi/ERC721.json';
import FlexibilityContract from '@abi/FlexibilityTournament.json';
import GymContract from '@abi/Gym.json';
import IncubatorContract from '@abi/Incubator.json';
import OraclePricesContract from '@abi/OraclePrices.json';
import PetShopContract from '@abi/PetShop.json';
import PlaygroundContract from '@abi/Park.json';
import SchoolContract from '@abi/School.json';
import SpeedContract from '@abi/SpeedTournament.json';
import StrengthContract from '@abi/StrengthTournament.json';
import TicketERC20Contract from '@abi/Ticket.json';
import TournamentContract from '@abi/TournamentManager.json';
import PresaleContract from '@abi/Presale.json';

export {
    BEP20Contract,
    ConfigStorageContract,
    CreatureContract,
    EnduranceContract,
    ERC721Contract,
    FlexibilityContract,
    IncubatorContract,
    GymContract,
    OraclePricesContract,
    PetShopContract,
    PlaygroundContract,
    SchoolContract,
    SpeedContract,
    StrengthContract,
    TicketERC20Contract,
    TournamentContract,
    PresaleContract
}