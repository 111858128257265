import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { TournamentService } from '@services/contracts/tournament/tournament.service';
import { UpgradeService } from '@services/contracts/upgrade/upgrade.service';
import { STATIC_TOURNAMENT } from '@static/tournaments';

/**
 * 
 * $Dialog
 * $Cooldown
 * $Storage
 * 
 */

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnChanges {

  @Input() counter = '';
  @Input() counterPlayers = 0;
  @Input() customClass = '';
  @Input() disabledButton = false;
  @Input() item: any | undefined;
  @Input() machine = null;
  @Input() type = '';

  public currentUrl = '';
  private isOpenedDialog = false;
  private readonly staticTournament: { [key: string]: string }[] = STATIC_TOURNAMENT;
  private savedNFTSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private nftService: NftService,
    private tournamentService: TournamentService,
    private translateService: TranslateService,
    private upgradeService: UpgradeService
  ) {
    this.currentUrl = this.router.url;
  }

  ngOnInit(): void {
    this.getSavedNFT();
  }

  ngOnDestroy(): void {
    this.savedNFTSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.counter?.includes('Complet') && !this.isOpenedDialog && this.currentUrl.includes('/play/tournaments')) {
        if (!this.item?.tournamentInfo) {
          this.isOpenedDialog = true;
          setTimeout(() => this.openDialog(), 500);
        }
      }
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Dialog
  /*------------------------------------------------------------------------------------*/

  openDialog(): void {
    const currentStat = this.item?.primaryStat?.toLowerCase();
    const tournamentType = this.translateService.instant(`stats.${ currentStat }`);
    const isMainTournamentPage = this.currentUrl.endsWith('/play/tournaments');
    let dialogDataInfo = {};

    if (this.counterPlayers >= 4) {
      dialogDataInfo = {
        title: 'closeTournament.title', 
        message: (isMainTournamentPage ? 'closeTournament.message2' : 'closeTournament.message'), 
        data: {
          currentTournamentType: tournamentType,
          showCancelButton: false,
          showResultsGame: true,
          tournamentEnded: true
        }
      };
      
      // Fix: hide button in main tournament page
      if (!isMainTournamentPage) {
        dialogDataInfo = { ...dialogDataInfo, button: 'buttons.seeResults' };
      }
    } else {
      dialogDataInfo = {
        title: 'cancelTournament.title', 
        message: 'cancelTournament.message', 
        data: {
          currentTournamentType: tournamentType,
          showCancelButton: false
        }
      };
    }

    this.dialogService.openDialog(
      DialogConfirmationComponent,'confirmation-dialog-container', dialogDataInfo
    );
  }

  /*------------------------------------------------------------------------------------*\
    $Cooldown
  /*------------------------------------------------------------------------------------*/

  reduceEvolutionTime(event: any): void {
    this.disabledButton = true;
    this.nftService.saveNFT(Number(this.item.tokenId));

    if (this.type === 'type-tournament') {
      if (this.item?.tournamentInfo?.endTimestamp) {
        const resp = this.staticTournament.find((item) => item.key === this.item.tournamentInfo.tournamentType);
        if (resp?.type) {
          const tournamentType = resp.type;
          this.tournamentService.reduceEvolutionTime(event, this.item, tournamentType);        
        }
      }
    } else {
      this.upgradeService.reduceEvolutionTime(event, this.item, this.machine);
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Get saved
  /*------------------------------------------------------------------------------------*/

  private getSavedNFT() {
    this.savedNFTSubscription = this.nftService.getSavedNFT$()
        .subscribe((tokenIdList: any[]) => {
          const currentTokenId = Number(this.item.tokenId);
          const getToken = tokenIdList.find((tokenId: number) => tokenId === currentTokenId);
          if (getToken !== undefined) {
            if (this.item.isReduced) {
              this.disabledButton = true;
              this.nftService.removeSavedNFT(currentTokenId);
            } else {
              this.disabledButton = getToken;
            }
          }
        });
  }

}
