import {
    getNFTInGym,
    getNFTInIncubator,
    getNFTInPark,
    getNFTInSchool
} from '@graphql/queries/pet/query.graphql';
import { GraphQL } from '@interfaces/graphql';

import { NFTStages, petTypes } from "@interfaces/nft";
import { StatMachine } from "@interfaces/stat-machine";

/**
 * 
 * $Preload time
 * $Stages
 * $Stats
 * $Types
 * $Export
 * 
 */

/*------------------------------------------------------------------------------------*\
  $Preload time
/*------------------------------------------------------------------------------------*/

const STATIC_PRELOAD_TIME = 500;

/*------------------------------------------------------------------------------------*\
  $Stages
/*------------------------------------------------------------------------------------*/

const STATIC_NFT_ENTITIES_STAGES:GraphQL[] = [
    { entity: getNFTInIncubator, prop: 'petsInIncubator', ageStage: 0 },
    { entity: getNFTInPark, prop: 'petsInPark', ageStage: 1 },
    { entity: getNFTInSchool, prop: 'petsInSchool', ageStage: 2 },
    { entity: getNFTInGym, prop: 'petsInGym', ageStage: 3 }
];

const STATIC_NFT_STAGES: NFTStages = {
  egg: 0,
  baby: 1,
  young: 2,
  adult: 3,
  tournaments: 3
};

/*------------------------------------------------------------------------------------*\
  $Stats
/*------------------------------------------------------------------------------------*/

const STATIC_STATS_LIST: { [key: string]: string }[] = [
    { key: 'speed', link: '151c33f9-96de-4bdf-a2b2-bc0e7814110a/ol0RqinmQZ' },
    { key: 'endurance', link: 'c6adfbdd-c1bd-41bb-8626-3fed4c41bdfb/wl9yvQVWJd' },
    { key: 'strength', link: '0f76528c-5e98-4c0e-a6f6-70cc0eb27bc8/L4Mwk1iies' },
    { key: 'flexibility', link: 'bff935b3-126a-4e36-b110-06bd93d3f903/8ol5mgKIFb' }
];

const STATIC_STATS_GYM_LIST: { [key: number]: StatMachine } = {
  0: {
    prop: 'gtl0',
    stat: 'strength'
  },
  1: {
    prop: 'gtl1',
    stat: 'speed'
  },
  2: {
    prop: 'gtl2',
    stat: 'endurance'
  },
  3: {
    prop: 'gtl3',
    stat: 'flexibility'
  }
};

/*------------------------------------------------------------------------------------*\
  $Types
/*------------------------------------------------------------------------------------*/

const STATIC_NFT_TYPES_LIST: string[] = ['ursids', 'felines', 'canids', 'birds'];

const STATIC_NFT_TYPES_SING_LIST: petTypes[] = ['ursid', 'feline', 'canid', 'bird'];
// 'primate', 'reptile', 'marsupial', 'rodent', 'amphibian', 'dinosaur', 'platypus'

/*------------------------------------------------------------------------------------*\
  $Export
/*------------------------------------------------------------------------------------*/

export {
  STATIC_PRELOAD_TIME,
  STATIC_NFT_ENTITIES_STAGES,
  STATIC_NFT_STAGES,
  STATIC_NFT_TYPES_LIST,
  STATIC_NFT_TYPES_SING_LIST,
  STATIC_STATS_LIST,
  STATIC_STATS_GYM_LIST
}