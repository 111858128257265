import { gql } from 'apollo-angular';
import { FRAGMENT_PET } from '@graphql/fragments/pet/fragment.graphql';

/**
 * $NFT Wallet
 * $NFT Tournaments
 */

/*-------------------------------------------------------------------------*\
  $NFT Wallet
/*-------------------------------------------------------------------------*/

export const getWalletTournamentNFT = gql`
    query getWalletTournamentNFT($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            id
            petsInWallet(where: { ageStage_gte: 3 }) {
                ...fragmentPet,
                gymMachineAt
            }
        }
    }
    ${ FRAGMENT_PET }
`;

/*-------------------------------------------------------------------------*\
  $NFT Tournaments
/*-------------------------------------------------------------------------*/

////////// In Tournaments

export const getPetsinTournaments = gql`
    query getPetsinTournaments($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            petsInTournaments {
                ...fragmentPet
                tournamentInfo {
                    bambooPaid
                    bambooRewards
                    depositTime
                    endTimestamp
                    experienceGained
                    id
                    tournamentType
                }
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// Info NFT in Tournament

export const getTournamentsInfoNFT = gql`
    query getTournamentsInfoNFT($wallet: String!) {
        tournamentInfos(where: { id: $wallet }) {
            bambooPaid
            bambooRewards
            depositTime
            endTimestamp
            experienceGained
            id
            tournamentType
        }
    }
`;

////////// Info one NFT in Tournament

export const getNFTTournamentByToken = gql`
    query getNFTinTournamentByToken($wallet: String!, $tokenId: String!) {
        usersInventories(where: { id: $wallet }) {
            petsInTournaments(where: { tokenId: $tokenId }) {
                ...fragmentPet
                tournamentInfo {
                    bambooPaid
                    bambooRewards
                    depositTime
                    endTimestamp
                    experienceGained
                    id
                    tournamentType
                }
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// Info Tournament Type

export const getPetsinTypeTournaments = gql`
    query getPetsinTournaments($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            petsInTournaments {
                tokenId
                tournamentInfo {
                    bambooPaid
                    bambooRewards
                    depositTime
                    endTimestamp
                    experienceGained
                    id
                    tournamentType
                }
            }
        }
    }
    ${ FRAGMENT_PET }
`;

////////// Info Tournament Type & Timestamp

export const getPetsinTournamentsWithTimestamp = gql`
    query getPetsinTournaments($wallet: String!) {
        usersInventories(where: { id: $wallet }) {
            petsInTournaments {
                tokenId
                tournamentInfo {
                    endTimestamp
                    tournamentType
                }
            }
        }
    }
`;