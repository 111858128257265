import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '@services/utils/utils.service';

@Pipe({name: 'fromWei'})
export class FromWeiPipe implements PipeTransform {

    constructor(private utilsService: UtilsService) {}

    transform(value: number | string): string {
      if (typeof value !== 'string') {
        value = value?.toLocaleString('en', {useGrouping: false} );
        // value = value.toString();
      }
      if (value) {
        value = this.utilsService.fromWei(value);
      }
      return value;
    }
}
