import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@modules/shared/shared.module';
import { InteractionButtonsComponent } from '@components/interaction-buttons/interaction-buttons.component';
import { StaticTimerComponent } from '@components/timer/static-timer/static-timer.component';
import { TimerComponent } from '@components/timer/timer.component';
import { UpgradeButtonComponent } from '@components/upgrade-button/upgrade-button.component';

@NgModule({
  declarations: [
    InteractionButtonsComponent,
    StaticTimerComponent,
    TimerComponent,
    UpgradeButtonComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedModule
  ],
  exports: [
    InteractionButtonsComponent,
    StaticTimerComponent,
    TimerComponent,
    UpgradeButtonComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CountdownComponentsModule { }
