import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DepositService } from '@services/contracts/deposit/deposit.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { GymContract } from '@static/abi';
import { contractAddresses } from '@static/contract-address';
import { STATIC_STATS_GYM_LIST } from '@static/nft';

@Component({
  selector: 'app-dialog-confirm-deposit-mode',
  templateUrl: './dialog-confirm-deposit-mode.component.html',
  styleUrls: ['./dialog-confirm-deposit-mode.component.scss']
})
export class DialogConfirmDepositModeComponent implements OnInit {

  public currentPetExperience: any = null;
  public minExperience: any = null;
  public priceTokens: any = null;
  public readonly gymMachineProps = STATIC_STATS_GYM_LIST;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private depositService: DepositService,
    private nftService: NftService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getAmountOfExpForGym();
    await this.getPriceTokens();
  }

  async doExperienceDeposit(): Promise<void> {
    if (this.currentPetExperience >= this.minExperience) {
      this.doDeposit('experience');
    }
  }

  async doDeposit(type: string): Promise<void> {
    await this.close();
    if (type === 'experience') {
      await this.depositService.startExperienceDialog(this.data.item, this.data.machine, type, this.data.amount);
    } else {
      await this.depositService.startDialog(this.data.item, this.data.machine, type, this.data.amount);
    }
  }

  private async getAmountOfExpForGym(): Promise<void> {
    const resp = await this.depositService.getAmountOfExpForGym();
    if (resp) {
      this.minExperience = Number(resp);
      this.currentPetExperience = Number(this.data.item.experience);
    }
  }

  private async getPriceTokens(): Promise<void> {
    const resp: any = await this.nftService.getPrices(contractAddresses.gym);
    if (resp) {
      this.priceTokens = resp.usdAmountForBNB;
    }
    console.log('PRICES: pay with tokens.', resp);
  }

  private async close(): Promise<void> {
    this.dialogRef.close();
  }

}
