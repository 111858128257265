<div class="container-dialog">

    <div class="container-menu">
      <!-- Sort -->
      <div #sortingMenu class="sorting-menu">
        <div id="sort-switch" class="sorting-switch" (click)="sortingSwitch()">
          <mat-icon class="icon" *ngIf="!showSortMenu">sort</mat-icon>
          <img class="icon-close-menu" src="/assets/icons/icon-close-menu.svg" alt="" *ngIf="showSortMenu">
        </div>
  
        <app-filter-sort-menu (changeSort)="setSorting($event)" #filterSortMenu></app-filter-sort-menu>
      </div>
    </div>
  
    <div class="lists" id="lists">
  
      <!-- Custom header -->
      <app-custom-header title="header.inventory"></app-custom-header>
    
      <div class="list" [class.no-results]="NFTData?.length === 0">
        <div class="content" [class.is-empty]="NFTData?.length === 0">
          <app-no-results [isResultTournament]="true" textData="tournamentNoPets" *ngIf="NFTData?.length === 0 && !showLoader"></app-no-results>
          <app-loader *ngIf="showLoader"></app-loader>
          
          <!-- Refresh & close buttons -->
          <app-filter-button-options [showLoader]="showLoader" [customClass]="(NFTData?.length === 0) ? 'no-results' : ''" (refreshAction)="refreshData($event)">
            <button appPreventDefault 
                    class="btn-confirm  btn-claimAll" 
                    [disabled]="!canClaimAll"
                    [title]="'buttons.claimAll' | translate" 
                    (click)="doClaimAll()" *ngIf="NFTData?.length > 0">
              {{ 'buttons.claimAll' | translate }}
            </button>
          </app-filter-button-options>
    
          <!-- Data -->
          <div class="list-category" [class.is-transparent]="showLoader">
            <app-nft-card type="type-tournament" [data]="{ id: i, currentLocation, favorites, petType }" [item]="item" (cooldownActiveItems)="checkCooldownIsActive($event)" *ngFor="let item of NFTData; let i = index; trackBy: trackByID">
              <ng-container inventory>
                <app-inventory-buttons [nft]="item"></app-inventory-buttons>
                <app-nft-awards customClass="awards-card" [tournamentInfo]="item.tournamentInfo"></app-nft-awards>
              </ng-container>
            </app-nft-card>
          </div>
        </div>
        
      </div>
    </div>
  
  </div>