import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NftService } from '@services/contracts/nft/nft.service';
import { phaseMessages } from '@static/i18n';

/**
 * 
 * $Dialog
 * $Phase
 * $Messages
 * $Random
 * 
 */

@Component({
  selector: 'app-dialog-info-pop',
  templateUrl: './dialog-info-pop.component.html',
  styleUrls: ['./dialog-info-pop.component.scss']
})
export class DialogInfoPopComponent implements OnInit {

  public message: string | undefined = '';
  public nft: any;
  public title = '';
  
  private phase = '';
  private stage = '';
  private readonly phaseMessages: { [key: string]: string[] } = phaseMessages;

  constructor(
    public dialogRef: MatDialogRef<DialogInfoPopComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private nftService: NftService
  ) {
    const { animal, phase } = this.data;
    this.nft = animal;
    this.phase = phase;
    this.stage = animal.ageStage;
  }

  ngOnInit(): void {
    this.getPhaseMessages();
  }

  /*--------------------------------------------------------------------------------*\
   * $Dialog
  /*--------------------------------------------------------------------------------*/

  close(): void {
    this.nftService.removeSavedNFT(Number(this.nft.tokenId));
    this.dialogRef.close();
  }

  /*------------------------------------------------------------------------------------*\
    $Phase
  /*------------------------------------------------------------------------------------*/

  private getPhaseMessages(): void {
    const phaseMessages = this.phaseMessages;
    if (phaseMessages.messages.includes(this.phase)) {
      this.setNonDifferentText('upgrading');
    } else if(phaseMessages.depositMessages.includes(this.phase)) {
      this.setDepositDifferentText('deposit');
    } else if (phaseMessages.phaseBuyMessages.includes(this.phase)) {
      this.title = this.phase;
      this.message = this.nft;
    } else if (phaseMessages.databaseMessages.includes(this.phase)) {
      this.title = this.phase;
      this.message = this.nft;
    } else {
      this.setDifferentText();
    }
  }

  private setNonDifferentText(type: string): void {
    this.title = type + '.' + this.phase + '.title';
    this.message = type + '.' + this.phase + '.text';
  }

  /*------------------------------------------------------------------------------------*\
    $Mesages
  /*------------------------------------------------------------------------------------*/

  private setDifferentText(): void {
    this.title = 'upgrading.' + this.phase + '.age_' + this.stage + '.title';
    let num = 0;
    switch (this.phase) {
      case 'startError':
        num = this.generateRandom();
        break;
      case 'forwardProcessing':
        num = this.generateRandom();
        break;
      case 'finishProcessing':
        num = this.generateRandom();
        break;
      default:
        num = 0;
        break;
    }
    this.message = 'upgrading.' + this.phase + '.age_' + this.stage + '.text.text_' + num;
  }

  private setDepositDifferentText(type: string): void {
    this.title = type + '.' + this.phase + '.age_' + this.stage + '.title';
    this.message = type + '.' + this.phase + '.age_' + this.stage + '.message';
  }

  /*------------------------------------------------------------------------------------*\
    $Random
  /*------------------------------------------------------------------------------------*/

  private generateRandom(): number {
    return Math.floor(Math.random() * parseInt(this.stage, 0));
  }

}
