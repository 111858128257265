import { Injectable } from '@angular/core';
import { BigNumber, ethers } from 'ethers';

import { NFT } from '@interfaces/nft';
import { STATIC_NFT_TYPES_SING_LIST } from '@static/nft';
/**
 * 
 * $Sorting
 * $Stage
 * $Routing
 * $Transform
 * $Ethers
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  
  public walletIsConnected = false;

  constructor() { }

  /*--------------------------------------------------------------------------------*\
   * $Sorting
  /*--------------------------------------------------------------------------------*/

  randomSorting(nftData: NFT[]): NFT[] {
    return nftData.sort(() => Math.random() - 0.5);
  }

  /*--------------------------------------------------------------------------------*\
   * $Stage
  /*--------------------------------------------------------------------------------*/

  getType(type: string): string {
    return STATIC_NFT_TYPES_SING_LIST[parseInt(type, 0)];
  }

  /*--------------------------------------------------------------------------------*\
   * $Routing
  /*--------------------------------------------------------------------------------*/

  getAgeTranslationRoute(age: string): string | void {
    switch (age) {
      case '0': return 'list.egg';
      case '1': return 'list.baby';
      case '2': return 'list.young';
      case '3': return 'list.adult';
    }
  }

  goToExternalLink(url: string | null): void {
    if (url) {
      window.open(url, "_blank");
    }
  }

  /*--------------------------------------------------------------------------------*\
   * $Transform
  /*--------------------------------------------------------------------------------*/

  toJSON(prop: any): any {
    let updatedProp = prop;
    if (typeof prop === 'string') {
      updatedProp = JSON.parse(updatedProp);
    }
    return updatedProp;
  }

  toJSONUriString(nftData: NFT[]): any {
    let nftDataUpdated: any[] = [];
    nftData.map((nft: NFT) => {
      let currentNFT: any = nft;
      if (nft?.metadata?.uriString) {
        currentNFT = {
          ...nft,
          metadata: {
            uriString: this.toJSON(nft.metadata.uriString)
          }
        };
        // nft.metadata.uriString = this.toJSON(nft.metadata.uriString);
      }
      nftDataUpdated.push(currentNFT);
      return currentNFT;
    });
    return nftDataUpdated;
  }

  transformAddress(currentAddress: string, size = 4): string {
    return currentAddress.substring(0, size) + '...' + currentAddress.substring(currentAddress.length - size);
  }

  repeatSequence(val: number): Array<number> {
    return Array(val);
  }

  /*--------------------------------------------------------------------------------*\
   * $Ethers
  /*--------------------------------------------------------------------------------*/

  fromWei(weiValue: string): string {
    return ethers.utils.formatEther(weiValue);
  }

  toWei(etherValue: string): BigNumber | string {
    return ethers.utils.parseEther(etherValue).toString();
  }

  getPriceDollars(amount: string) {
    return Number(this.fromWei(amount)).toFixed();
  }

}
