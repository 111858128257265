import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NFTTournament } from '@interfaces/nft';
import { TournamentService } from '@services/contracts/tournament/tournament.service';
import { STATIC_TOURNAMENT } from '@static/tournaments';

@Component({
  selector: 'app-dialog-claim-select-type',
  templateUrl: './dialog-claim-select-type.component.html',
  styleUrls: ['./dialog-claim-select-type.component.scss']
})
export class DialogClaimSelectTypeComponent implements OnInit {

  public selectedStat = '';
  private nftDataByStat: NFTTournament[] = [];
  private readonly staticTournament: { [key: string]: string }[] = STATIC_TOURNAMENT;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tournamentService: TournamentService
  ) { }
  
  ngOnInit(): void { }

  changeStatSelected(stat: string): void {
    this.selectedStat = stat;
    const getStat = this.staticTournament.find((item: any) => item.type === this.selectedStat);
    if (getStat?.key) {
      const res = this.data?.nftData?.filter((nft: NFTTournament) => nft.tournamentInfo.tournamentType === getStat.key);
      this.nftDataByStat = (res?.length > 0) ? res : [];
    }
  }

  async doClaim(): Promise<void> {
    this.tournamentService.currentTournament = `${ this.selectedStat }Tournament`;
    await this.tournamentService.withdrawAll(this.nftDataByStat, this.dialogRef);
  }

  async close(): Promise<void> {
    this.dialogRef.close();
  }

}
