<h2>
    {{ 'dialog.petsLimit.title' | translate }}
    <img class="ico-close" src="/assets/icons/buttons/ico-close-white.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
  </h2>
  <p *ngIf="!data.gymMessage">
    {{ 'dialog.petsLimit.message' | translate: { limit: data.limit } }} 
    <strong>{{ data.section | translate }}</strong>
  </p>

  <p [innerHTML]="data.gymMessage | translate: { limit: data.limit, machine: data.section }" 
     *ngIf="data.gymMessage"></p>
  