import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { NFT } from '@interfaces/nft';
import { BuyService } from '@services/contracts/buy/buy.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { CreatureContract } from '@static/abi';
import { STATIC_TYPES_TOURNAMENT } from '@static/tournaments';
import { contractAddresses } from '@static/contract-address';

/**
 * 
 * $Get Stat
 * $Steps
 * $Dialog
 * $Change XP for Stat
 * $Limit Token
 * $Get Stat gained
 * $Get XP
 * 
 */

@Component({
  selector: 'app-dialog-stats',
  templateUrl: './dialog-stats.component.html',
  styleUrls: ['./dialog-stats.component.scss']
})
export class DialogStatsComponent implements OnInit {

  public currentStep = 1;
  public minXp = 0;
  public statGained = 0;
  public selectedStat = '';
  private currentLimit = 20;
  private currentLimitToken = 0;
  private readonly staticStats: string[] = STATIC_TYPES_TOURNAMENT;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private buyService: BuyService,
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private nftService: NftService
  ) { }
  
  async ngOnInit(): Promise<void> {
    this.currentLimitToken = await this.getLimitToken();
    this.statGained = await this.getStatGained();
    this.minXp = await this.getXP();
  }

  /*-------------------------------------------------------------------------*\
    $Get Stat
  /*-------------------------------------------------------------------------*/

  getStatValue(stat: number, nft: NFT | undefined): number {
    return this.nftService.getStatValue(stat, nft);
  }

  changeStatSelected(stat: string): void {
    this.selectedStat = stat;
  }

  /*-------------------------------------------------------------------------*\
    $Steps
  /*-------------------------------------------------------------------------*/

  async nextStep(): Promise<void> {
    if (this.currentStep < 2) {
      this.currentStep += 1;
      this.data = {
        ...this.data,
        title: 'dialog.statConfirmation.step' + this.currentStep + '.title',
        message: 'dialog.statConfirmation.step' + this.currentStep + '.message',
        subtitle: this.data.item?.name
      };
    } else {
      await this.initChangeXPForStat();
    }
  }

  /*-------------------------------------------------------------------------*\
    $Dialog
  /*-------------------------------------------------------------------------*/

  async close(): Promise<void> {
    this.dialogRef.close();
  }

  private openDialog(): void {
    this.dialogService.openDialog(
      DialogInfoComponent,
      ['error-dialog-container'],
      {
        type: 'error',
        title: 'dialog.burn.xp.changeXP.errorLimit.title', 
        message: 'dialog.burn.xp.changeXP.errorLimit.message',
        limit: this.currentLimit,
        minXP: this.minXp,
        statGained: this.statGained
      }
    );
  }

  /*-------------------------------------------------------------------------*\
    $Change XP for Stat
  /*-------------------------------------------------------------------------*/

  private async initChangeXPForStat(): Promise<void> {
    const getPos: number = this.staticStats.findIndex((item) => item.toLowerCase() === this.selectedStat.toLowerCase());
    await this.close();
    if (this.currentLimitToken < this.currentLimit) {
      const numTimes = (this.currentLimit - this.currentLimitToken);
      await this.buyService.changeXPforStat(
        this.data.item?.tokenId,
        getPos,
        this.selectedStat,
        this.data.item?.name,
        numTimes,
        this.minXp,
        this.statGained
      );
    } else {
      this.openDialog();
    }
  }

  /*-------------------------------------------------------------------------*\
    $Limit Token
  /*-------------------------------------------------------------------------*/

  private async getLimitToken(): Promise<number> {
    const limit = await this.connectionService.readContract(
      contractAddresses.pet, CreatureContract.abi, 'changeXPForStatUsed', [this.data.item?.tokenId]
    );
    return Number(limit);
  }

  /*-------------------------------------------------------------------------*\
    $Get Stat Gained
  /*-------------------------------------------------------------------------*/

  private async getStatGained(): Promise<number> {
    const limit = await this.connectionService.readContract(
      contractAddresses.pet, CreatureContract.abi, 'statAmountGainedWithXP'
    );
    return Number(limit);
  }

  /*-------------------------------------------------------------------------*\
    $Get XP
  /*-------------------------------------------------------------------------*/

  private async getXP(): Promise<number> {
    const limit = await this.connectionService.readContract(
      contractAddresses.pet, CreatureContract.abi, 'xpAmountForUpgradingStat'
    );
    return Number(limit);
  }

}
