import { Injectable } from '@angular/core';

/**
 * 
 * $Account
 * $Connection
 * $Lang
 * $Opened MyPets
 * $Generics
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private readonly keepConnection = 'keepConnection';
  private readonly openedMyPets = 'openedMyPets';
  private readonly selectedLang = 'selectedLang';

  constructor() { }

  /*------------------------------------------------------------------------------------*\
    $Account
  /*------------------------------------------------------------------------------------*/

  getAccount(): string {
    const data: any = localStorage.getItem('account');
    return JSON.parse(data);
  }

  /*------------------------------------------------------------------------------------*\
    $Connection
  /*------------------------------------------------------------------------------------*/

  getConnection(): boolean {
    const data: any = localStorage.getItem(this.keepConnection);
    return Boolean(data);
  }

  /*------------------------------------------------------------------------------------*\
    $Lang
  /*------------------------------------------------------------------------------------*/

  getLang(): string {
    const data: any = localStorage.getItem(this.selectedLang);
    return JSON.parse(data);
  }

  setLang(value: any): void {
    return localStorage.setItem(this.selectedLang, JSON.stringify(value));
  }

  /*------------------------------------------------------------------------------------*\
    $Opened MyPets
  /*------------------------------------------------------------------------------------*/

  getOpenedMyPets(): string {
    const data: any = localStorage.getItem(this.openedMyPets);
    return JSON.parse(data);
  }

  removeOpenedMyPets(): void {
    return localStorage.removeItem(this.openedMyPets);
  }

  setOpenedMyPets(value: any): void {
    return localStorage.setItem(this.openedMyPets, JSON.stringify(value));
  }

  /*------------------------------------------------------------------------------------*\
    $Generics
  /*------------------------------------------------------------------------------------*/

  getItem(key: string): any {
    const data: any = localStorage.getItem(key);
    return JSON.parse(data);
  }

  setItem(key: string, value: any): void {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  removeItem(key: string): void {
    return localStorage.removeItem(key);
  }

  clear(): void {
    return localStorage.clear();
  }

}
