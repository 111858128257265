import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogAnimalListComponent } from '@dialogs/dialog-animal-list/dialog-animal-list.component';

/**
 * 
 * $Inventory
 * $Open
 * $Close all
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  private elWrapper = 'gamescreen';

  constructor(
    public dialog: MatDialog,
  ) { }

  /*---------------------------------------------------------------*\
    $Inventory
  /*---------------------------------------------------------------*/

  openPetsListDialog(
    url: string,
    petType: number | null | undefined = null,
    machine: number | null | undefined = null,
    presale = false
  ): MatDialogRef<any> {
    
    let location: string;
    
    if (url.includes('playground')) {
      location = 'baby';
    } else if (url.includes('school')) {
      location = 'young';
    } else if (url.includes('gym')) {
      location = 'adult';
    } else if (url.includes('tournaments')) {
      location = 'tournaments';
    } else {
      location = 'egg';
    }

    const dialogRef = this.dialog.open(DialogAnimalListComponent, {
      panelClass: 'list-dialog-container',
      disableClose: false,
      data: {
        cat: 'cat-' + location,
        location,
        petType,
        machine,
        presale,
        tab: 'tab-' + location
      }
    });

    const doc: any = document;
    doc.getElementById(this.elWrapper).style.filter = 'blur(5px)';

    dialogRef.afterClosed().subscribe(result => {
      doc.getElementById(this.elWrapper).style.filter = 'unset';
    });
    
    return dialogRef;
  }

  /*---------------------------------------------------------------*\
    $Open
  /*---------------------------------------------------------------*/

  public openDialog(component: any, panelClass: string | string[], data = {}): MatDialogRef<any> {
    const dialogRef = this.dialog.open(component, {
      panelClass,
      disableClose: false,
      data
    });
    return dialogRef;
  }

  /*---------------------------------------------------------------*\
    $Close all
  /*---------------------------------------------------------------*/

  public closeAll(): void {
    this.dialog.closeAll();
  }

}
