import { Injectable } from '@angular/core';
import { NFT } from '@interfaces/nft';

/**
 * 
 * $Sort NFT
 * $Sort by favorites
 * $Get param for sorting
 * $Sort by endurance
 * $Sort by flexibility
 * $Sort by name
 * $Sort by speed
 * $Sort by stats
 * $Sort by strength
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  public sortBy = 'stats';
  public sortType = 'asc';

  constructor() { }

  /*-------------------------------------------------------------------------*\
    $Sort NFT
  /*-------------------------------------------------------------------------*/

  public sortNFT(NFTData: NFT[]): NFT[] {
    return NFTData.sort((a: NFT, b: NFT) => this.getParamForSort(a, b));
  }

  /*-------------------------------------------------------------------------*\
    $Sort by favorites
  /*-------------------------------------------------------------------------*/

  public sortByFavorites(NFTData: NFT[], favorites: number[]): NFT[] {
    return NFTData.sort((a, b) => {
      const tokenA = favorites.indexOf(Number(a.tokenId));
      const tokenB = favorites.indexOf(Number(b.tokenId));
      
      if (tokenA === -1 && tokenB === -1) {
        return 0;
      } else if (tokenA === -1) {
        return 1;
      } else if (tokenB === -1) {
        return -1;
      }
      
      return tokenA - tokenB;     
    });
  }

  /*-------------------------------------------------------------------------*\
    $Get param for sorting
  /*-------------------------------------------------------------------------*/

  private getParamForSort(a: NFT, b: NFT): any {
    switch (this.sortBy) {
      case 'endurance':
        return this.sortByEndurance(a, b);
      case 'flexibility':
        return this.sortByFlexibility(a, b);
      case 'name':
        return this.sortByName(a, b);
      case 'speed':
        return this.sortBySpeed(a, b);
      case 'stats':
        return this.sortByStats(a, b);
      case 'strength':
        return this.sortByStrength(a, b);
    }
  }

  /*-------------------------------------------------------------------------*\
    $Sort by endurance
  /*-------------------------------------------------------------------------*/

  private sortByEndurance(a: NFT, b: NFT): number {
    const aEnduranceTotal = (parseInt(a.endurance, 0));
    const bEnduranceTotal = (parseInt(b.endurance, 0));
    return (this.sortType === 'asc') ? (bEnduranceTotal - aEnduranceTotal) : (aEnduranceTotal - bEnduranceTotal);
  }

  /*-------------------------------------------------------------------------*\
    $Sort by flexibility
  /*-------------------------------------------------------------------------*/

  private sortByFlexibility(a: NFT, b: NFT): number {
    const aFlexibilityTotal = (parseInt(a.flexibility, 0));
    const bFlexibilityTotal = (parseInt(b.flexibility, 0));
    return (this.sortType === 'asc') ? (bFlexibilityTotal - aFlexibilityTotal) : (aFlexibilityTotal - bFlexibilityTotal);
  }

  /*-------------------------------------------------------------------------*\
    $Sort by name
  /*-------------------------------------------------------------------------*/

  private sortByName(a: NFT, b: NFT): number {
    if (this.sortType === 'asc') {
      if (a?.metadata?.uriString?.name > b?.metadata?.uriString?.name) { return 1; }
      if (a?.metadata?.uriString?.name < b?.metadata?.uriString?.name) { return -1; }
    } else {
      if (a?.metadata?.uriString?.name < b?.metadata?.uriString?.name) { return 1; }
      if (a?.metadata?.uriString?.name > b?.metadata?.uriString?.name) { return -1; }
    }
    return 0;
  }

  /*-------------------------------------------------------------------------*\
    $Sort by speed
  /*-------------------------------------------------------------------------*/

  private sortBySpeed(a: NFT, b: NFT): number {
    const aSpeed = (parseInt(a.speed, 0));
    const bSpeed = (parseInt(b.speed, 0));
    return (this.sortType === 'asc') ? (bSpeed - aSpeed) : (aSpeed - bSpeed);
  }

  /*-------------------------------------------------------------------------*\
    $Sort by stats
  /*-------------------------------------------------------------------------*/

  private sortByStats(a: NFT, b: NFT): number {
    const aStatsTotal = parseInt(a.strength, 0) + parseInt(a.endurance, 0) + parseInt(a.flexibility, 0) + parseInt(a.speed, 0);
    const bStatsTotal = parseInt(b.strength, 0) + parseInt(b.endurance, 0) + parseInt(b.flexibility, 0) + parseInt(b.speed, 0);
    return (this.sortType === 'asc') ? (bStatsTotal - aStatsTotal) : (aStatsTotal - bStatsTotal);
  }

  /*-------------------------------------------------------------------------*\
    $Sort by strength
  /*-------------------------------------------------------------------------*/

  private sortByStrength(a: NFT, b: NFT): number {
    const aStrengthTotal = (parseInt(a.strength, 0));
    const bStrengthTotal = (parseInt(b.strength, 0));
    return (this.sortType === 'asc') ? (bStrengthTotal - aStrengthTotal) : (aStrengthTotal - bStrengthTotal);
  }

}
