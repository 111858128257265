import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroPadding'
})
export class ZeroPaddingPipe implements PipeTransform {
  private readonly limitChars = 10;

  transform(value: number): string {
    return value < this.limitChars ? ('0' + value) : ('' + value);
  }

}
