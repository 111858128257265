import { Injectable } from '@angular/core';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';


@Injectable()
export class CustomPageTitleStrategy extends TitleStrategy {

  private titleSubscription$: Subscription | undefined;
  private readonly defaultTitle = 'Hashapon | ';

  constructor(
    private translateService: TranslateService,
    private readonly title: Title
  ) {
    super();
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.titleSubscription$ = this.translateService.get(title).subscribe((translatedTitle) => {
        this.title.setTitle(this.defaultTitle + translatedTitle);
      })
    } else {
      this.title.setTitle(this.defaultTitle + 'metadata.home.title');
    }
  }
}