import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@services/storage/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  private readonly supportedLanguages: string[] = ['en', 'es'];

  constructor(
    private storageService: StorageService,
    private translateService: TranslateService
  ) {
    this.checkUserLanguage();
  }

  /*--------------------------------------------------------------------------*\
    $Multilanguage
  /*--------------------------------------------------------------------------*/

  private checkUserLanguage(): void {
    this.translateService.addLangs(this.supportedLanguages);

    const navigatorLanguage = this.getNavigatorLanguage();
    const selectedLanguage = this.storageService.getLang();

    if (selectedLanguage) {
      this.setLanguage(selectedLanguage);
    } else if (navigatorLanguage !== '') {
      this.setLanguage(navigatorLanguage);
    } else {
      this.setLanguage('en');
    }
  }

  private setLanguage(lang: string): void {
    this.translateService.setDefaultLang(lang);
    this.translateService.use(lang);
  }

  private getNavigatorLanguage(): string {
    const navLang = navigator.language.substring(0, 2);
    if (this.supportedLanguages.includes(navLang)) {
      return navLang;
    } else {
      return '';
    }
  }
}
