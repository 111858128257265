<!-- Header -->

<app-custom-header [title]="'results'" customClass="c-light-header">
    <app-close-button></app-close-button>
</app-custom-header>

<!-- Content -->

<section class="content" [class.is-empty]="totalBrackets === 0 && !showLoader">
    <app-no-results [isResultTournament]="true" textData="tournamentNoResults" *ngIf="totalBrackets === 0 && !showLoader"></app-no-results>
    <app-loader customClass="full-width" *ngIf="showLoader"></app-loader>
    <ng-container [ngTemplateOutlet]="tplResults" *ngIf="totalBrackets > 0 && !showLoader"></ng-container>
</section>

<!-- Template -->

<ng-template #tplResults>
    <header class="results-header">
        <h3 class="winner-heading">{{ 'header.winners' | translate }}</h3>
        <!--<button (click)="close()">{{ 'buttons.back' | translate }}</button>-->
        <h3 class="loser-heading">{{ 'header.losers' | translate }}</h3>
        <img class="ico-vs" src="assets/icons/tournaments/icon-vs.svg" alt="">
    </header>

    <div class="results-container">
        <ng-container *ngFor="let bracket of brackets | keyvalue">
            <div [class]="'bracket-container ' + bracket.key + '-container'" *ngIf="bracket.value.length > 0">
                <h3 class="bracket-header">{{ ('brackets.' + bracket.key) | translate }}</h3>
                <ul class="results-list">
                    <li *ngFor="let nft of bracket.value; let i = index; trackBy: trackByID">
                        <app-nft-card-result [attr.data-is-winner]="nft?.creatureA?.isWinner" [attr.data-medal]="getMedal(bracket?.key, nft?.creatureA?.isWinner) ?? 'no-medal'" [image]="getImage(nft?.creatureA?.tokenId)" [medal]="getMedal(bracket?.key, nft?.creatureA?.isWinner)" [nft]="nft?.creatureA" [stats]="getStats(nft?.creatureA?.tokenId)"></app-nft-card-result>
                        <app-nft-card-result [attr.data-is-winner]="nft?.creatureB?.isWinner" [attr.data-medal]="getMedal(bracket?.key, nft?.creatureB?.isWinner) ?? 'no-medal'" [image]="getImage(nft?.creatureB?.tokenId)" [medal]="getMedal(bracket?.key, nft?.creatureB?.isWinner)" [nft]="nft?.creatureB" [stats]="getStats(nft?.creatureB?.tokenId)"></app-nft-card-result>
                    </li>
                </ul>
            </div>
        </ng-container>
    </div>
</ng-template>