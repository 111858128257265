import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-waiting-gachapon',
  templateUrl: './dialog-waiting-gachapon.component.html',
  styleUrls: ['./dialog-waiting-gachapon.component.scss']
})
export class DialogWaitingGachaponComponent implements OnInit {

  public videoUrl = 'waiting-gachapon-bnb';

  constructor(
    private dialogRef: MatDialogRef<DialogWaitingGachaponComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

  ngOnInit(): void {
    this.videoUrl = this.checkBuyTickets();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private checkBuyTickets(): string {
    if (this.data.buyWithTickets) {
      return 'waiting-gachapon-ticket';
    }
    return this.videoUrl;
  }

}
