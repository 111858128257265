import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription, interval, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TimerService } from '@services/timer/timer.service';

@Component({
  selector: 'app-static-timer',
  templateUrl: './static-timer.component.html',
  styleUrls: ['./static-timer.component.scss']
})
export class StaticTimerComponent implements OnInit, OnDestroy {

  @Input() counter: Date | string = 'December 11, 2023 23:59:00';
  
  public counterTimestamp = 0;
  public presaleIsClosed = false;
  private counterSubscription: Subscription | undefined;

  constructor(
    private timerService: TimerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (!this.presaleIsClosed) {
      this.counterSubscribe();
    }
  }

  ngOnDestroy(): void {
    this.counterSubscription?.unsubscribe();
  }

  /*---------------------------------------------------------------------------*\
    $Countdown actions
  /*---------------------------------------------------------------------------*/

  ////////////// Counter subscription //////////////

  private counterSubscribe(): void {
    this.counterSubscription = this.startCounter()
        .subscribe((time: string) => {
          this.counter = time;
          if (time === '00:00:00' || time.includes('-')) {
            this.counter = this.translateService.instant('countdown.presale.ended');
            this.presaleIsClosed = true;
            this.counterSubscription?.unsubscribe();
          }
        });
  }

  ////////////// Counter interval //////////////

  private startCounter(): Observable<string> {
    return interval(1000).pipe(
      map((num: number) => {
        return this.checkCounter();
      })
    );
  }

  ////////////// Check counter //////////////

  private checkCounter(): string {
    if (this.counterTimestamp === 0) {
      this.counter = new Date(this.counter);
      this.counterTimestamp = this.counter.getTime(); // timestamp in miliseconds
    }
    return this.timerService.countDown(this.counterTimestamp, 'days');
  }

}
