import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MaxUint256 } from '@ethersproject/constants';
import { waitForTransaction } from '@wagmi/core';

import { ConnectionService } from '@services/connection/connection/connection.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { TokenService } from '@services/contracts/token/token.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { DialogBurnAndMintComponent } from '@dialogs/dialog-burn-and-mint/dialog-burn-and-mint.component';
import { CreatureContract, OraclePricesContract, PetShopContract, TicketERC20Contract } from '@static/abi';
import { DialogWaitingGachaponComponent } from '@dialogs/dialog-waiting-gachapon/dialog-waiting-gachapon.component';
import { DialogNewEggComponent } from '@dialogs/dialog-new-egg/dialog-new-egg.component';
import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { contractAddresses } from '@static/contract-address';
import { DialogBuyProcessComponent } from '@pages/play/gachapon/components/dialog/dialog-buy-process/dialog-buy-process.component';

/**
 * 
 * $Buy Egg
 * $Burn pet and get tickets
 * $Change XP for stat
 * $Burn pets 
 * $Buy specie
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class BuyService {

  private readonly openedMyPetsClass = 'fix-center';
  private videoDialog: any;

  constructor(
    private dialogService: DialogService,
    private connectionService: ConnectionService,
    private tokenService: TokenService,
    private nftService: NftService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) { }

  /*---------------------------------------------------------------*\
    $Buy Egg
  /*---------------------------------------------------------------*/

  async buyGachapon(buyWithTickets = false): Promise<void> {
    let dialog: any;

    try {
      dialog = this.dialogService.openDialog(
        DialogBuyProcessComponent,
        ['info-dialog-container', 'buyproccess-dialog-container'],
        {
          title: 'dialog.buyProccess.title',
          message: 'dialog.buyProccess.' + (buyWithTickets ? 'messageTickets' : 'message'),
          note: 'dialog.buyProccess.note',
          buyWithTickets
        }
      );
      dialog.afterClosed().subscribe(async (result: boolean) => {
        if (result) {
          dialog.close();
          this.videoDialog = this.dialogService.openDialog(
            DialogWaitingGachaponComponent,
            'buy-gachapon-dialog-container',
            { buyWithTickets }
          );
          const tx = await this.doPayment(buyWithTickets); 
          const resp = await waitForTransaction(tx);
          if (resp) {
            dialog.close();
            this.videoDialog.close();
            if (!document.getElementById('new_egg_bought')) {
              this.dialogService.openDialog(
                DialogNewEggComponent,
                'stat-dialog-container',
                { type: 'buy' }
              );
            }
          }
        }
      });
    } catch (error: any) {
      console.error('BUY NEW EGG', error.message);
      dialog.close();
      this.videoDialog.close();
      const errorDialog = this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.buyEggError',
          message: 'dialog.buyEggErrorMessage'
        }
      );
    }
  }

  async doPayment(withTickets: boolean): Promise<any> {
    const userAddr = this.connectionService.getWalletAddress();
    const eggPrice: any = await this.nftService.getPrices(contractAddresses.petShop);
    // eggPrice.usdAmountForBNB.toString(), return a Wei value format. Add toString to remove the n end letter
    const price = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [eggPrice.usdAmountForBNB.toString()]);
    let stringAmount = MaxUint256.toString();

    try {
      if (withTickets) {
        const respApproveTicket: boolean = await this.tokenService.tokenApprovement(
          contractAddresses.petShop, userAddr, contractAddresses.ticket, price
        );
        // const respApproveTicketAllowance: boolean = await this.tokenService.checkTicketAllowance();
        if (respApproveTicket) {
          try {
            const respContract = await this.connectionService.writeContract(
              contractAddresses.petShop, PetShopContract.abi, 'buyEggWithTickets', []
            );
            return respContract;
          } catch (error: any) {
            console.error('BUY NEW EGG WITH TICKETS', error.message);
            this.videoDialog.close();
            const errorDialog = this.dialogService.openDialog(
              DialogInfoComponent,
              'error-dialog-container',
              {
                type: 'error',
                title: 'dialog.buyCancelTickets.title',
                message: 'dialog.buyCancelTickets.text'
              }
            );
          }
        } else {
          this.videoDialog.close();
        }
      } else {
        const respApprove: boolean = await this.tokenService.tokenApprovement(
          contractAddresses.petShop, userAddr, contractAddresses.bamboo, price
        );
        if (respApprove) {
          try {
            const respContract = await this.connectionService.writeContract(
              contractAddresses.petShop, PetShopContract.abi, 'buyEggWithTokens', [], price
            );
            return respContract;
          } catch (error: any) {
            const message = this.walletMessagesService.getMessage(error.message, 'dialog.buyCancel.text');
            this.videoDialog.close();
            const errorDialog = this.dialogService.openDialog(
              DialogInfoComponent,
              'error-dialog-container',
              {
                type: 'error',
                title: 'dialog.buyCancel.title',
                message
              }
            );
            // console.error('BUY NEW EGG', error.message);
          }
        } else {
          this.videoDialog.close();
        }
      }
    } catch (error: any) {
      console.error('PAYMENT', error.message);
      const errorDialog = this.dialogService.openDialog(
        DialogInfoComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'dialog.buyEggError',
          message: 'dialog.buyEggErrorMessage'
        }
      );
    }
  }

  /*---------------------------------------------------------------*\
    $Burn pet and get tickets
  /*---------------------------------------------------------------*/

  async burnAndGetTickets(originDialog: MatDialogRef<DialogBurnAndMintComponent>, burnElem: string, tickets: number): Promise<void> {
    await this.connectionService.syncAccount();
    const userAddress = this.connectionService.getWalletAddress();
    const contractAddress = contractAddresses.petShop;
    const stagePrice: any = await this.nftService.getPrices(contractAddress);
    const dialogProccess = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'dialog.burn.xp.waiting.title', 
        message: 'dialog.burn.xp.waiting.message', 
        action: 'burnTickets',
        price: this.utilsService.getPriceDollars(stagePrice.usdAmountForBAMBOO)
      }
    );
    const dialogError = {
      title: 'dialog.buyTicketsWithParadiseError.title',
      message: 'dialog.buyTicketsWithParadiseError.message'
    };
    const NFTBurn = parseInt(burnElem);
    console.log('NFT: Burnt and get tickets NFT', NFTBurn);
    try {
      const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
        contractAddress, contractAddresses.pet, dialogProccess, dialogError
      );
      if (respNFTApprove) {
        const tx = await this.connectionService.writeContract(contractAddresses.petShop, PetShopContract.abi, 'buyTicketsWithParadise', [NFTBurn]);
        const resp = await waitForTransaction(tx);
        if (resp) {
          dialogProccess.close();
          // originDialog.close();
          const dialogSuccess = this.dialogService.openDialog(
            DialogConfirmationComponent,
            ['confirmation-dialog-container', this.openedMyPetsClass],
            {
              title: 'dialog.burn.xp.success.title', 
              message: 'dialog.burn.xp.success.message', 
              button: 'buttons.accept',
              tickets,
              data: {
                showCancelButton: false
              },
            }
          );
        }
      }
    } catch (error: any) {
      dialogProccess.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', this.openedMyPetsClass],
        {
          type: 'error',
          title: 'dialog.buyTicketsWithParadiseError.title',
          message: 'dialog.buyTicketsWithParadiseError.message'
        }
      );
    }
  }

  /*---------------------------------------------------------------*\
    $Change XP for stat
  /*---------------------------------------------------------------*/

  async changeXPforStat(
    tokenId: string,
    stat: number,
    statName: string,
    nftName: string,
    currentLimitToken: number,
    minXP: number,
    statGained: number
  ): Promise<void> {
    console.log('CHANGE XP FOR STAT: ', tokenId, stat, statName, nftName);
    const userAddress = this.connectionService.getWalletAddress();
    let dialog: any;

    try {
      dialog = this.dialogService.openDialog(
        DialogConfirmationComponent,
        ['confirmation-dialog-container', this.openedMyPetsClass],
        {
          title: 'dialog.burn.xp.changeXP.processing.title', 
          message: 'dialog.burn.xp.changeXP.processing.message', 
          limit: currentLimitToken,
          minXP,
          statGained
        }
      );
      const tx = await this.connectionService.writeContract(
        contractAddresses.pet, CreatureContract.abi, 'changeXPforStat', [tokenId, stat]
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        dialog.close();
        dialog = this.dialogService.openDialog(
          DialogConfirmationComponent,
          ['confirmation-dialog-container', this.openedMyPetsClass],
          {
            title: 'dialog.burn.xp.changeXP.confirm.title', 
            message: 'dialog.burn.xp.changeXP.confirm.message',
            nftName,
            stat: statName?.toLowerCase(),
            minXP,
            statGained
          }
        );
      }
    } catch (error: any) {
      dialog.close();
      const message = this.walletMessagesService.getMessage(error.message, 'dialog.burn.xp.changeXP.error.message');
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', this.openedMyPetsClass],
        {
          type: 'error',
          title: 'dialog.burn.xp.changeXP.error.title',
          message
        }
      );
    }
  }

  /*---------------------------------------------------------------*\
    $Burn pets
  /*---------------------------------------------------------------*/

  async burnAndMint(originDialog: MatDialogRef<DialogBurnAndMintComponent>, burnElems: string[]): Promise<void> {
    await this.connectionService.syncAccount();
    const userAddress = this.connectionService.getWalletAddress();
    const contractAddress = contractAddresses.petShop;
    const stagePrice: any = await this.nftService.getPrices(contractAddress);

    const dialogProccess = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'dialog.burn.waitingForBurnTitle', 
        message: 'dialog.burn.waitingForBurnText',
        action: 'burnTokens',
        price: this.utilsService.getPriceDollars(stagePrice.usdAmountForBAMBOO)
      }
    );

    const NFTBurn: number[] = burnElems.map((elem) => parseInt(elem));
    
    console.log('NFT: Burnt and mint NFT', NFTBurn);
    
    try {
      const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
        contractAddress, contractAddresses.pet, dialogProccess
      );
      if (respNFTApprove) {
        const tx = await this.connectionService.writeContract(
          contractAddresses.petShop, PetShopContract.abi, 'buyEggWithParadise', [NFTBurn]
        );
        const resp = await waitForTransaction(tx);
        if (resp) {
          dialogProccess.close();
          // originDialog.close();
          const dialogSuccess = this.dialogService.openDialog(
            DialogConfirmationComponent,
            ['confirmation-dialog-container', this.openedMyPetsClass],
            {
              title: 'dialog.burn.successTitle', 
              message: 'dialog.burn.successMessage', 
              button: 'buttons.accept',
              data: {
                showCancelButton: false
              },
            }
          );
        }
      }
    } catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'upgrading.errorForwarding.text');
      dialogProccess.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', this.openedMyPetsClass],
        {
          type: 'error',
          title: 'upgrading.errorForwarding.title',
          message
        }
      );
    }
  }

  async getAmountToBurnForEgg(): Promise<void> {
    return await this.connectionService.readContract(contractAddresses.pet, CreatureContract.abi, 'amountToBurnForEgg', []);
  }

  async getOwnerOf(tokenId: string): Promise<void> {
    return await this.connectionService.readContract(contractAddresses.pet, CreatureContract.abi, 'ownerOf', [tokenId]);
  }

}
