import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FavoriteService } from '@services/api/favorite/favorite.service';

@Component({
  selector: 'app-favorite-status',
  templateUrl: './favorite-status.component.html',
  styleUrls: ['./favorite-status.component.scss']
})
export class FavoriteStatusComponent implements OnInit {

  @Input() customClass = '';
  @Input() isFavorite = false;
  @Input() item: any | undefined;
  @Output() favoriteStatusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private favoriteService: FavoriteService
  ) { }

  ngOnInit(): void { }

  async setFavorite(): Promise<void> {
    if (this.isFavorite) {
      await this.favoriteService.deleteFavorite(this.item.tokenId);
      this.isFavorite = false;
    } else {
      await this.favoriteService.addFavorite(this.item.tokenId);
      this.isFavorite = true;
    }
    this.favoriteStatusChanged.emit(this.isFavorite);
  }

}
