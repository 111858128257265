import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { petTypes } from '@interfaces/nft';
import { Presale, PresaleType } from '@interfaces/presale';
import { PresaleService } from '@services/contracts/presale/presale.service';
import { UtilsService } from '@services/utils/utils.service';
import { STATIC_NFT_TYPES_SING_LIST } from '@static/nft';

/**
 * 
 * $Dialog
 * $Selected type
 * $Steps
 * $Buy
 * $Presale info
 * 
 */

@Component({
  selector: 'app-dialog-presale',
  templateUrl: './dialog-presale.component.html',
  styleUrls: ['./dialog-presale.component.scss']
})
export class DialogPresaleComponent {

  public currentStep = 1;
  public selectedPresale: any = null;
  public selectedType: { id?: number, type?: string } = {};
  public petTypesList: petTypes[] = STATIC_NFT_TYPES_SING_LIST;
  public presaleInfo: PresaleType | any = {};
  private readonly presaleTypes: string[] = ['basic','normal','premium'];

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private presaleService: PresaleService,
    private utilsService: UtilsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getPresaleInfo();
  }

  goToExternalLink(url: string): void {
    this.utilsService.goToExternalLink(url);
  }

  /*------------------------------------------------------------------------------------*\
    $Dialog
  /*------------------------------------------------------------------------------------*/

  close(): void {
    this.dialogRef.close();
  }

  /*------------------------------------------------------------------------------------*\
    $Selected type
  /*------------------------------------------------------------------------------------*/

  selectPresale(type: number): void {
    this.selectedPresale = type;
    console.log('Selected presale: ', this.selectedPresale);
  }

  selectType(type: petTypes, id: number): void {
    this.selectedType = { id, type };
  }

  /*------------------------------------------------------------------------------------*\
    $Steps
  /*------------------------------------------------------------------------------------*/

  goBack(): void {
    this.currentStep = 1;
    this.selectedPresale = null;
  }

  nextStep(): void {
    this.currentStep ++;
  }

  /*------------------------------------------------------------------------------------*\
    $Buy
  /*------------------------------------------------------------------------------------*/

  async buySpecieEgg(): Promise<void> {
    const priceBNB = this.presaleInfo[this.presaleTypes[this.selectedPresale]].busdInBNB;
    const resp = await this.presaleService.buySpecieEgg(this.selectedType, this.selectedPresale, priceBNB);
    if (resp) {
      this.currentStep = 1;
      this.selectedPresale = null;
      this.selectedType = {};
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Presale info
  /*------------------------------------------------------------------------------------*/

  private async getPresaleInfo(): Promise<void> {
    const presalePricesBasic: any = await this.presaleService.getPresalePrices(0);
    const presalePricesNormal: any = await this.presaleService.getPresalePrices(1);
    const presalePricesPremium: any = await this.presaleService.getPresalePrices(2);
    this.presaleInfo = {
      basic: presalePricesBasic,
      normal: presalePricesNormal,
      premium: presalePricesPremium
    };
    console.log('PRESALE: getPresaleInfo ', this.presaleInfo);
  }

}
