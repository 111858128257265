import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    title: 'metadata.home.title',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'legal',
    loadChildren: () => import('./pages/legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'play',
    loadChildren: () => import('./pages/play/play.module').then(m => m.PlayModule),
  },
  {
    path: 'play/tournaments',
    loadChildren: () => import('./pages/tournaments/tournaments.module').then(m => m.TournamentsModule)
  },
  {
    path: 'not-found',
    pathMatch: 'full',
    title: 'metadata.notFound.title',
    loadChildren: () => import('./pages/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: '_health',
    title: 'metadata.health.title',
    loadChildren: () => import('./pages/health/health.module').then(m => m.HealthModule)
  },
  { path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
