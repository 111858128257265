<div class="container-dialog" [class.is-dialog-presale]="data.presale">

  <div class="container-menu">
    <!-- Sort -->
    <div #sortingMenu class="sorting-menu" [class.is-transparent]="!showSorting" *ngIf="!data.presale">
      <div id="sort-switch" class="sorting-switch" (click)="sortingSwitch()">
        <mat-icon class="icon" *ngIf="!showSortMenu">sort</mat-icon>
        <img class="icon-close-menu" src="/assets/icons/icon-close-menu.svg" alt="" *ngIf="showSortMenu">
      </div>

      <app-filter-sort-menu (changeSort)="setSorting($event)" #filterSortMenu></app-filter-sort-menu>
    </div>

    <!-- Paradise -->
    <app-paradise-menu [currentLocation]="currentLocation" *ngIf="!data.presale && currentLocation !== 'egg'"></app-paradise-menu>
  </div>

  <div class="lists" id="lists">

    <header class="presale-header" *ngIf="data.presale">
      <h2>
        {{ 'dialog.yourEggs' | translate }}
        <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
      </h2>
    </header>
  
    <!-- Tabs Menú -->
    <app-tablist #tablist (changeTabAction)="changeTab($event)" *ngIf="!data.presale"></app-tablist>
  
    <div class="list" [class.no-results]="NFTData?.length === 0">
      <div class="content" [class.is-empty]="NFTData?.length === 0">
        <app-no-results [isPresale]="data.presale" *ngIf="NFTData?.length === 0 && !showLoader"></app-no-results>
        <app-loader *ngIf="showLoader"></app-loader>
        
        <!-- Refresh & close buttons -->
        <app-filter-button-options [showLoader]="showLoader" [customClass]="(NFTData?.length === 0) ? 'no-results' : ''" 
                                   (refreshAction)="refreshData($event)" [isPresale]="data.presale"></app-filter-button-options>
  
        <!-- Data -->
        <div class="list-category" [class.is-transparent]="showLoader">
          <app-nft-card [data]="{ id: i, currentLocation, favorites, machine, petType, presale: data.presale }" [item]="item" *ngFor="let item of NFTData; let i = index; trackBy: trackByID">
            <app-nft-stats [item]="item" *ngIf="currentLocation !== 'egg'"></app-nft-stats>
            <app-nft-action-buttons [item]="item" [favorites]="favorites" [currentLocation]="currentLocation" *ngIf="currentLocation === 'adult' && !item.tournamentInfo"></app-nft-action-buttons>
          </app-nft-card>
          <ng-container *ngIf="currentLocation === 'adult'">
            <h4 translate *ngIf="NFTTournamentData?.length > 0">header.inventory</h4>
            <app-nft-card [data]="{ id: i, currentLocation, favorites, machine, petType, presale: data.presale }" [item]="item" *ngFor="let item of NFTTournamentData; let i = index; trackBy: trackByID">
              <app-nft-stats [item]="item"></app-nft-stats>
              <app-nft-action-buttons [item]="item" [favorites]="favorites" [currentLocation]="currentLocation" [disabledPlay]="true"></app-nft-action-buttons>
            </app-nft-card>
          </ng-container>
        </div>
      </div>
      
    </div>
  </div>

</div>