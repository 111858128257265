import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from '@environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  localStorage.setItem('connected', 'disconnected');
  if (window) {
    // window.console.error = () => { };
    window.console.log = () => { };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
