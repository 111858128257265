import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dialog-info-network',
  templateUrl: './dialog-info-network.component.html',
  styleUrls: ['./dialog-info-network.component.scss']
})
export class DialogInfoNetworkComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogInfoNetworkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit(): void { }

  close(): void {
    if (this.data.url) {
      this.router.navigate([this.data.url]);
    }
    this.dialogRef.close();
  }

}
