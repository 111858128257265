import { gql } from 'apollo-angular';

type Pet = { }; // Prevent error

export const FRAGMENT_PET = gql`
    fragment fragmentPet on Pet {
        id
        ageStage
        endTimestamp
        petType
        tokenId
        metadata {
            id
            uriString
        }
        endurance
        flexibility
        speed
        strength 
        experience
        pedigree
        breedAmount
        collection
        generation 
        gymMachineAt
        isReduced
        gtl0
        gtl1
        gtl2
        gtl3   
    }
`;