<app-custom-header customClass="c-custom-header  txt-capitalize" title="header.depositGymMode" [machine]="gymMachineProps[data?.machine]?.stat">
    <app-close-button customClass="claim-close"></app-close-button>
</app-custom-header>

<div class="confirmation">
    <p translate>deposit.age_3.depositMode</p>
    <div class="buttons">
      <button appPreventDefault class="btn-large" [disabled]="currentPetExperience < minExperience" (click)="doExperienceDeposit()">
        {{ minExperience }} {{ 'header.experience' | translate }}
      </button>
      <button appPreventDefault class="btn-large" (click)="doDeposit('tokens')">
        {{ priceTokens | fromWei | number: '1.2-3' }}$ <span>{{ 'dialog.in' | translate }}</span> BNB
      </button>
    </div>
</div>