import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInventoryTournamentComponent } from '@pages/tournaments/components/dialog/dialog-inventory-tournament/dialog-inventory-tournament.component';

import { DialogGameResultsComponent } from '@pages/tournaments/type-tournament/components/dialog/dialog-game-results/dialog-game-results.component';
import { BuyService } from '@services/contracts/buy/buy.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { DialogService } from '@services/dialog/dialog.service';
import { STATIC_TYPES_TOURNAMENT } from '@static/tournaments';

/**
 * 
 * $Dialog
 * 
 */

@Component({
  selector: 'app-dialog-confirmation',
  templateUrl: './dialog-confirmation.component.html',
  styleUrls: ['./dialog-confirmation.component.scss']
})
export class DialogConfirmationComponent implements OnInit {

  private readonly statsList: string[] = STATIC_TYPES_TOURNAMENT;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private buyService: BuyService,
    private dialogService: DialogService,
    private nftService: NftService
  ) { }

  ngOnInit(): void {
    console.log('Data: ', this.data);
    if (this.data?.machine || this.data?.machine === 0) { // Fix
      this.data.machine = this.statsList[this.data.machine];
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Dialog
  /*------------------------------------------------------------------------------------*/

  accept(accepted: boolean): void {
    if (this.data?.data?.showResultsGame) {
      this.openDialog();
    } else {
      if (accepted && this.data?.data?.selectedItems) {
        this.buyService.burnAndMint(this.dialogRef, this.data.data.selectedItems);
      }
    }
    this.dialogRef.close(accepted);
  }

  close(): void {
    if (this.data.tournamentEnded) {
      this.dialogService.openDialog(
        DialogInventoryTournamentComponent,
        'list-dialog-container',
        {
          location: 'tournaments'
        }
      );
    }

    if (this.data.start) {
      this.nftService.removeSavedNFT(Number(this.data.nft.tokenId));
    }

    this.dialogRef.close();
  }

  private openDialog(): void {
    this.dialogService.openDialog(DialogGameResultsComponent, 'results-dialog-container');
  }

}
