<h2>
    <span translate>{{ data.title }}</span>
    <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>

<p translate [translateParams]="{ amount: tickets.myTickets }">{{ data.message }}</p>

<div class="price-container">
    <div class="price-block  ticket-block" [class.has-ticket]="data.buyWithTickets" *ngIf="data.buyWithTickets">        
        <strong class="txt-tickets">{{ this.tickets.minTickets }}</strong>
        <img class="icon-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
        <img class="icon-arrow" src="/assets/icons/icon-arrow-right.svg" alt="">
        <img class="icon-egg" src="/assets/images/eggs/egg-ursid.svg" alt="Egg">
    </div>

    <div class="price-block" *ngIf="!data.buyWithTickets">
        <div class="price-info">
            <span class="txt-price">
                <strong>{{ this.prices?.bnbDollars | fromWei | number: '1.2-3' }} $</strong> 
                {{ 'dialog.in' | translate }} BNB
            </span>
            <span class="txt-price-type">
                {{ this.prices?.bnb | fromWei | number: '1.3-5' }}
                <img class="icon-bnb  no-margin-r" src="/assets/images/logo-bnb.svg" alt="BNB">
            </span>
        </div>
        <mat-icon class="ico-add">add</mat-icon>
        <div class="price-info">
            <span class="txt-price">
                <strong>{{ this.prices?.bambooDollars | fromWei | number: '1.0-0' }} $</strong> 
                {{ 'dialog.in' | translate }} Bamboo
            </span>
            <span class="txt-price-type">
                {{ this.prices?.bamboo | fromWei | number: '1.2-3' }} 
                <img class="icon-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
            </span>
        </div>
        <img class="icon-arrow" src="/assets/icons/icon-arrow-right.svg" alt="">
        <img class="icon-egg" src="/assets/images/eggs/egg-ursid.svg" alt="Egg">
    </div>
</div>
    
<small translate *ngIf="!data.buyWithTickets">{{ data.note }}</small>

<div class="txt-warning" *ngIf="tickets.myTickets < tickets.minTickets && isLoaded">
    <h4 translate>dialog.buyWithTicketsDisabled.title</h4>
    <small translate [translateParams]="{ amount: tickets.minTickets }">dialog.buyWithTicketsDisabled.message</small>
</div>

<button appPreventDefault class="btn-confirm" [disabled]="tickets.myTickets < tickets.minTickets || !isLoaded" (click)="close(true)" translate>actions.confirm</button>
