import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@modules/shared/shared.module';
import { SwiperSliderComponent } from '@components/swiper-slider/swiper-slider.component';
import { NoResultsComponent } from '@components/no-results/no-results.component';
import { LoaderComponent } from '@components/loader/loader.component';
import { CountdownComponentsModule } from './countdown.module';
import { CloseButtonComponent } from '@pages/play/components/close-button/close-button.component';
import { DialogStatsComponent } from '@components/dialog/dialog-stats/dialog-stats.component';

@NgModule({
  declarations: [
    SwiperSliderComponent,
    NoResultsComponent,
    LoaderComponent,
    CloseButtonComponent,
    DialogStatsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CountdownComponentsModule,
    SharedModule
  ],
  exports: [
    CountdownComponentsModule,
    SwiperSliderComponent,
    NoResultsComponent,
    LoaderComponent,
    CloseButtonComponent,
    DialogStatsComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ComponentsModule { }
