import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogSelectPlayerComponent } from '@dialogs/dialog-select-player/dialog-select-player.component';
import { DialogService } from '@services/dialog/dialog.service';

@Component({
  selector: 'app-dialog-info-tournament',
  templateUrl: './dialog-info-tournament.component.html',
  styleUrls: ['./dialog-info-tournament.component.scss']
})
export class DialogInfoTournamentComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<DialogInfoTournamentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void { }

  async openDialog(approve = true): Promise<void | boolean> {
    if (approve && this.data.player) {
      this.close(approve);
      return approve;
    } else {
      this.dialogService.openDialog(
        DialogSelectPlayerComponent,
        'select-player-dialog-container',
        { tournament: this.data.tournament }
      );
    }
    this.close(approve);
  }

  close(approve = false): void {
    this.dialogRef.close(approve);

    if (!approve) {
      this.dialogService.openDialog(
        DialogSelectPlayerComponent,
        'select-player-dialog-container',
        { tournament: this.data.tournament }
      );      
    }
  }

}
