import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NftService } from '@services/contracts/nft/nft.service';

/**
 * 
 * $Dialog
 * 
 */

@Component({
  selector: 'app-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss']
})
export class DialogInfoComponent implements OnInit {

  public nft: any;

  constructor(
    public dialogRef: MatDialogRef<DialogInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private nftService: NftService
  ) {
    if (data.nft) {
      this.nft = data.nft;
    }
  }

  ngOnInit(): void { }

  /*--------------------------------------------------------------------------------*\
   * $Dialog
  /*--------------------------------------------------------------------------------*/

  close(): void {
    if (this.nft) {
      this.nftService.removeSavedNFT(Number(this.nft.tokenId));
    }
    this.dialogRef.close();
  }

}
