import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PreventDefaultDirective } from '@directives/prevent-default/prevent-default.directive';
import { StopPropagationDirective } from '@directives/stop-propagation/stop-propagation.directive';
import { DefaultPetPipe } from '@pipes/default-pet/default-pet.pipe';
import { DefaultPetNamePipe } from '@pipes/default-pet-name/default-pet-name.pipe';
import { FromWeiPipe } from '@pipes/from-wei/from-wei.pipe';
import { SafePipe } from '@pipes/safe/safe.pipe';
import { TransformAddressPipe } from '@pipes/transform-address/transform-address.pipe';
import { ZeroPaddingPipe } from '@pipes/zero-padding/zero-padding.pipe';


@NgModule({
  declarations: [
    PreventDefaultDirective,
    StopPropagationDirective,
    FromWeiPipe,
    DefaultPetPipe,
    DefaultPetNamePipe,
    SafePipe,
    TransformAddressPipe,
    ZeroPaddingPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    PreventDefaultDirective,
    StopPropagationDirective,
    FromWeiPipe,
    DefaultPetPipe,
    DefaultPetNamePipe,
    SafePipe,
    TransformAddressPipe,
    ZeroPaddingPipe,
  ]
})
export class SharedModule { }
