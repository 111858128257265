import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { DialogInfoPetLimitComponent } from '@dialogs/dialog-info-pet-limit/dialog-info-pet-limit.component';
import { DialogConfirmDepositModeComponent } from '@dialogs/dialog-confirm-deposit-mode/dialog-confirm-deposit-mode.component';
import { NFT } from '@interfaces/nft';
import { DepositService } from '@services/contracts/deposit/deposit.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { StorageService } from '@services/storage/storage.service';
import { UpgradeService } from '@services/contracts/upgrade/upgrade.service';
import { STATIC_STATS_GYM_LIST } from '@static/nft';

@Component({
  selector: 'app-upgrade-button',
  templateUrl: './upgrade-button.component.html',
  styleUrls: ['./upgrade-button.component.scss']
})
export class UpgradeButtonComponent implements OnInit, OnDestroy {

  @Input() id?: string | number | undefined;
  @Input() action = 'claim';
  @Input() item: any;
  @Input() isHidden = false;
  @Input() machine: any = null;
  @Input() customClass = '';
  @Input() type = '';

  public isDisabled = false;
  public NFTSize: { [key: string]: number } = {};

  private openedMyPetsClass = 'fix-center';
  private petsLimit = 0;
  private readonly gymMachineProps = STATIC_STATS_GYM_LIST;
  private savedNFTSubscription: Subscription | undefined;

  constructor(
    private depositService: DepositService,
    private dialogService: DialogService,
    private nftService: NftService,
    private storageService: StorageService,
    private upgradeService: UpgradeService
  ) { }

  ngOnInit(): void {
    this.getSavedNFT();
  }

  ngOnDestroy(): void {
    this.savedNFTSubscription?.unsubscribe();
  }

  async doAction(event: any): Promise<void> {
    this.isDisabled = true;
    this.nftService.saveNFT(Number(this.item.tokenId));
    
    if (this.action === 'deposit') {
      await this.doDeposit();
    }
    if (this.action === 'train') {
      await this.doDepositGym();
    }
    if (this.action === 'incubate') {
      await this.doIncubate();
    }
    if (this.action === 'claim' || this.action === 'upgrade') {
      await this.doUpgrade(event);
    }
  }

  private getSavedNFT() {
    this.savedNFTSubscription = this.nftService.getSavedNFT$()
        .subscribe((tokenIdList: any[]) => {
          const getToken = tokenIdList.find((tokenId: number) => tokenId === Number(this.item.tokenId));
          this.isDisabled = getToken; 
        });
  }

  private async doDeposit(): Promise<void> {
    this.NFTSize = this.getCountPetsWallet();
    this.petsLimit = Number(await this.getPetLimit());
    if (this.item?.ageStage === 1) {
      await this.checkLimit('petsInPark', 'navigation.playground');
    }
    if (this.item?.ageStage === 2) {
      await this.checkLimit('petsInSchool', 'navigation.school');          
    }
  }

  private async doDepositGym(): Promise<void> {
    const getMachineProp = this.gymMachineProps[this.machine].prop;
    const getTrainType = this.gymMachineProps[this.machine].stat;

    if (this.item[getMachineProp] === 0) {
      this.dialogService.openDialog(
        DialogInfoPetLimitComponent,
        ['info-dialog-container', this.openedMyPetsClass], 
        {
          // limit: 10,
          gymMessage: 'dialog.petsLimit.messageTrain',
          section: getTrainType
        }
      );
    } else {
      this.dialogService.openDialog(
        DialogConfirmDepositModeComponent,
        ['claim-dialog-container', this.openedMyPetsClass],
        {
          item: this.item,
          machine: this.machine,
          amount: this.item[getMachineProp]
        }
      );
    }
  }

  private async doIncubate(): Promise<void> {
    await this.depositService.startDialog(this.item, null, null);
  }

  private async doUpgrade(event: any): Promise<void> {
    await this.upgradeService.upgradeStart(event, this.item, this.item?.gymMachineAt);
  }

  private async getPetLimit(): Promise<number> {
    return await this.depositService.getPetLimit(this.item?.ageStage);
  }

  private getCountPetsWallet(): { [key: string]: number } {
    return JSON.parse(this.storageService.getItem('countPetsWallet'));
  }

  private async checkLimit(prop: string, section: string): Promise<void> {
    if (this.NFTSize[prop] < this.petsLimit) {
      await this.depositService.startDialog(this.item);
    } else {
      this.dialogService.openDialog(
        DialogInfoPetLimitComponent,
        'info-dialog-container',
        { limit: this.petsLimit, section }
      );
    }
  }

}
