<h2 [class.is-error]="data.type === 'error'">
    {{ data.title | translate }}
    <img *ngIf="data.type !== 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
    <img *ngIf="data.type === 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-white.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>
<p>{{ data.message | translate: { time: data.time } }}</p>

<ul *ngIf="data?.animal && data?.showRewards">
    <li>
        <img class="ico-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
        {{ 'rewards.tickets' | translate }}: 
        <strong>+{{ data?.tickets | fromWei | number: '1.0-0' }}</strong>
    </li>
    <li>
        <img class="icon-xp" src="assets/icons/tournaments/icon-xp.svg" alt="">
        {{ 'rewards.experienceGained' | translate }}: 
        <strong>{{ data?.animal?.tournamentInfo?.experienceGained }}</strong>
    </li>
    <li>
        <img class="ico-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
        {{ 'rewards.bamboo' | translate }}: 
        <strong>{{ (data?.animal?.tournamentInfo?.bambooRewards | fromWei | number: '1.0-0') ?? 0 }}</strong>
    </li>
</ul>

<ul class="list-allrewards" *ngIf="data?.showAllRewards">
    <li>
        <img class="ico-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
        {{ 'rewards.tickets' | translate }}: 
        <strong>+{{ data?.tickets | fromWei | number: '1.0-0' }}</strong>
    </li>
    <li>
        <img class="icon-xp" src="assets/icons/tournaments/icon-xp.svg" alt="">
        {{ 'rewards.experienceGained' | translate }}
    </li>
    <li>
        <img class="ico-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
        {{ 'rewards.bamboo' | translate }}
    </li>
</ul>