<h2>
  {{ 'dialog.newEgg.title' | translate }}
  <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>

<ng-container *ngIf="data.type === 'buy'">
  <p>
    {{ 'dialog.newEgg.buyText1' | translate }}
    <span (click)="openInventory()">{{ 'dialog.newEgg.buyText2' | translate }}</span>
    <ng-container *ngIf="!data?.presale">
      {{ 'or' | translate }}
      <span (click)="goTo('/play/incubators/')">{{ 'dialog.newEgg.buyText3' | translate }}</span>
    </ng-container>
      {{ 'dialog.newEgg.buyText4' | translate }}
  </p>
</ng-container>