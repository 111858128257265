import { Component, Inject, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';

import { Favorite } from '@interfaces/favorite';
import { GraphQL } from '@interfaces/graphql';
import { NFT, NFTStages, NFTStagesKeys, NFTTournament } from '@interfaces/nft';
import { getCountNFTInWallet } from '@graphql/queries/pet/query.graphql';
import { FavoriteService } from '@services/api/favorite/favorite.service';
import { GraphQLService } from '@services/graphql/graphql.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { SortingService } from '@services/sorting/sorting.service';
import { StorageService } from '@services/storage/storage.service';
import { STATIC_NFT_ENTITIES_STAGES, STATIC_NFT_STAGES, STATIC_PRELOAD_TIME } from '@static/nft';
import { TablistComponent } from '@pages/play/components/tablist/tablist.component';
import { FavoriteStatusComponent } from '@shared/components/inventory/favorite-status/favorite-status.component';

/**
 * 
 * $Refresh
 * $Tabs
 * $Sort
 * $Filter
 * $Favorites
 * $NFT
 * 
 */

@Component({
  selector: 'app-dialog-animal-list',
  templateUrl: './dialog-animal-list.component.html',
  styleUrls: ['./dialog-animal-list.component.scss']
})
export class DialogAnimalListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(FavoriteStatusComponent) favoriteStatusCP: FavoriteStatusComponent | undefined;
  @ViewChild('sortingMenu') sortingMenu: ElementRef | undefined;
  @ViewChild('tablist') tablist!: TablistComponent;

  currentLocation: NFTStagesKeys = 'baby';
  favorites: number[] = [];
  NFTData: NFT[] = [];
  NFTTournamentData: any[] = [];
  machine = null;
  petType: unknown = null;
  showLoader = false;
  showSortMenu = false;
  showSorting: boolean | undefined;

  private activeClass = 'active';
  private sortBy = 'stats';
  private sortType = 'asc';
  private favoritesSubscription: Subscription | undefined;
  private NFTSubscription: Subscription | undefined;
  private NFTStageSubscription: Subscription | undefined;
  private NFTSizeSubscription: Subscription | undefined;
  private NFTTournamentSubscription: Subscription | undefined;
  private readonly staticNftEntitiesStages: GraphQL[] = STATIC_NFT_ENTITIES_STAGES;
  private readonly staticNftStages: NFTStages = STATIC_NFT_STAGES;

  constructor(
    public dialogRef: MatDialogRef<DialogAnimalListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private favoriteService: FavoriteService,
    private graphQLService: GraphQLService,
    private nftService: NftService,
    private sortingService: SortingService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.currentLocation = this.data.location;
    this.machine = this.data.machine;
    this.petType = this.data.petType;
    this.setShowSorting(this.currentLocation);
    this.loadNFT();
    this.countNFTInWalletByType();
    // this.getFavorites();
    this.storageService.setOpenedMyPets(true);
  }

  ngOnDestroy(): void {
    this.favoritesSubscription?.unsubscribe();
    this.NFTSubscription?.unsubscribe();
    this.NFTStageSubscription?.unsubscribe();
    this.NFTSizeSubscription?.unsubscribe();
    this.NFTTournamentSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.tablist) {
      setTimeout(() => { // Fix: Unit tests
        this.tablist.activeTab = this.currentLocation;
      });
    }
  }

  trackByID(index: number, NFT: NFT): string {
    return NFT.tokenId;
  }

  openDialog(): void { }

  close(): void {
    this.dialogRef.close();
    this.storageService.removeOpenedMyPets();
  }

  /*-------------------------------------------------------------------------*\
    $Refresh
  /*-------------------------------------------------------------------------*/

  refreshData(status: boolean): void {
    if (status) {
      this.loadNFT();
      // this.getFavorites();
      this.countNFTInWalletByType();
    }
  }

  /*-------------------------------------------------------------------------*\
    $Tabs
  /*-------------------------------------------------------------------------*/

  changeTab(event: any): void {
    this.NFTSubscription?.unsubscribe();
    this.NFTStageSubscription?.unsubscribe();
    const selectedID = event.target.id.replace('tab-', '');
    this.currentLocation = selectedID;
    this.tablist.activeTab = selectedID;
    this.loadNFT();
    // this.getFavorites();
    this.setShowSorting(selectedID);
  }

  /*-------------------------------------------------------------------------*\
    $Sort
  /*-------------------------------------------------------------------------*/

  setSorting(params: { [key: string]: string }): void {
    const { sort, sortType } = params;
    this.sortBy = sort;
    this.sortType = sortType;
    this.sortNFT();
  }

  sortingSwitch(): void {
    this.showSortMenu = !this.showSortMenu;
    const doc: any = document;
    this.sortingMenu?.nativeElement.classList.toggle(this.activeClass);
    doc.getElementById('sort-menu').classList.toggle(this.activeClass);
  }

  private setShowSorting(tab: string): void {
    const compare = (tab === 'egg');
    this.showSorting = !compare;
  }

  private sortNFT(): void {
    this.sortingService.sortBy = this.sortBy;
    this.sortingService.sortType = this.sortType;
    this.NFTData = this.sortingService.sortNFT(this.NFTData);
    this.NFTTournamentData = this.sortingService.sortNFT(this.NFTTournamentData);
    if (this.sortBy === 'stats') {
      this.NFTData = this.sortingService.sortByFavorites(this.NFTData, this.favorites);
      if (this.NFTTournamentData.length > 0) {
        this.NFTTournamentData = this.sortingService.sortByFavorites(this.NFTTournamentData, this.favorites);
      }
    }
  }

  /*-------------------------------------------------------------------------*\
    $Filter
  /*-------------------------------------------------------------------------*/

  private filterByPetType(): void {
    if (this.currentLocation === 'egg' &&  this.data.petType !== null) {
      this.NFTData = this.NFTData.filter((nft: NFT) => nft.petType === this.petType);
    }
  }

  /*-------------------------------------------------------------------------*\
    $Favorites
  /*-------------------------------------------------------------------------*/

  private getFavorites(isTournament = false): void {
    this.favoritesSubscription = this.favoriteService.getAllFavorites()
        .pipe(
          finalize(() => console.log('FAVORITES', this.favorites))
        ).subscribe((favorites: any) => {
          const getFavorites = this.getFavoritesID(favorites);
          this.favorites = getFavorites;
          if (isTournament) {
            this.NFTTournamentData = this.sortingService.sortByFavorites(this.NFTTournamentData, this.favorites);
          } else {
            this.NFTData = this.sortingService.sortByFavorites(this.NFTData, this.favorites);
          }
        });
  }

  private getFavoritesID(favorites: Favorite[]): number[] {
    if (favorites.length > 0) {
      return favorites.map((favorite: Favorite) => favorite.tokenId);
    }
    return [];
  }

  /*-------------------------------------------------------------------------*\
    $NFT
  /*-------------------------------------------------------------------------*/

  private loadNFT(): void {
    this.NFTData = []; // Fix
    this.showLoader = true;
    this.NFTSubscription = this.loadInitialNFT()
        .subscribe((nftWallet: NFT[]) => {
          nftWallet.map((nft: NFT) => this.nftService.removeSavedNFT(Number(nft.tokenId))); // Fix
          // this.nftService.removeAllSavedNFT(); // Fix 
          this.loadNFTStage(nftWallet);
        });
  }

  private loadInitialNFT(): Observable<NFT[]> {
    return this.nftService.loadAllNFTWallet(this.staticNftStages[this.currentLocation]);
  }

  private loadNFTStage(nftWallet: NFT[]): void {
    const currentStage = this.staticNftEntitiesStages[this.staticNftStages[this.currentLocation]];
    const { entity, prop, ageStage } = currentStage;
    
    this.NFTStageSubscription = this.nftService.loadAllNFTStage(entity, prop)
        .subscribe((NFTData: NFT[]) => {
          this.NFTData = []; // Fix
          this.NFTData = nftWallet;
          console.log('NFT: Load NFT in Wallet', this.NFTData);
          const results: NFT[] = this.NFTData.concat(NFTData);
          this.NFTData = this.removeDuplicatedNFT(results);
          if (this.NFTData.length > 0) {
            this.filterByPetType();
            if (this.currentLocation === 'adult') {
              this.loadAllNFTTournament();
            }
            this.getFavorites();
            this.sortNFT();
          }
          console.log('NFT: Load NFT in Stage', this.NFTData);
          setTimeout(() => this.showLoader = false, STATIC_PRELOAD_TIME);
        });
  }

  private removeDuplicatedNFT(results: NFT[]): NFT[] {
    const filteredResults: NFT[] = results.reduce((acc: any, current: any) => {
      const duplicateIndex = acc.findIndex((item: NFT) => item.tokenId === current.tokenId);
      if (duplicateIndex !== -1) {
        acc.splice(duplicateIndex, 1, current);
      } else {
        acc.push(current);
      }
      return acc;
    }, []);
    return filteredResults;
  }
  
  private countNFTInWalletByType(): void {
    this.NFTSizeSubscription = this.graphQLService.getAll(getCountNFTInWallet)
        .subscribe((data: ApolloQueryResult<any>) => {
          const results = data.data.usersInventories[0];
          if (results) {
            const resultsData = {
              petsInPark: results?.petsInPark?.length,
              petsInSchool: results?.petsInSchool?.length
            };
            this.storageService.setItem('countPetsWallet', JSON.stringify(resultsData));
          }
        });
  }

  ////////////// Tournament NFTs //////////////

  private loadAllNFTTournament(): void {
    this.NFTTournamentSubscription = this.nftService.loadAllNFTTournament()
        .subscribe((NFTTournament: NFTTournament[]) => {
          this.NFTTournamentData = NFTTournament;
          if (this.NFTTournamentData.length > 0) {
            this.getFavorites(true);
          }
          console.log('TOURNAMENTS: Load NFT', NFTTournament);
        });
  }

}
