import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { waitForTransaction } from '@wagmi/core';

import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { DialogInfoPopComponent } from '@dialogs/dialog-info-pop/dialog-info-pop.component';
import { IEvolutionInfo } from '@interfaces/evolution-info';
import { NFT } from '@interfaces/nft';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { StorageService } from '@services/storage/storage.service';
import { TokenService } from '@services/contracts/token/token.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { contractAddresses } from '@static/contract-address';
import {
  CreatureContract,
  GymContract,
  IncubatorContract,
  OraclePricesContract,
  PlaygroundContract,
  SchoolContract
} from '@static/abi';

/**
 * 
 * $Upgrade Start
 * $Forward Upgrade
 * $Get Contract
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class UpgradeService {

  public contractAdresses: string[] = [];
  private currentNFT: any;
  private currentPriceStage = 0;
  private currentStage = 0;
  private machine: string | number | null = null;
  private stageAddress = '';
  private userAddress = '';
  private readonly openedMyPetsClass = 'fix-center';
  private readonly AbiGroup: { [key: string]: any }[] = [
    { price: 0, type: IncubatorContract, address: contractAddresses.incubator },
    { price: 0, type: PlaygroundContract, address: contractAddresses.park },
    { price: 0, type: SchoolContract, address: contractAddresses.school },
    { price: 0, type: GymContract, address: contractAddresses.gym },
  ];

  constructor(
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private nftService: NftService,
    private storageService: StorageService,
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) {
    this.contractAdresses = [
      contractAddresses.incubator,
      contractAddresses.park,
      contractAddresses.school,
      contractAddresses.gym
    ];
  }

  /*------------------------------------------------------------------------------------*\
    $Upgrade start
  /*------------------------------------------------------------------------------------*/

  async upgradeStart(event: any, nft: NFT | undefined, machine: number | null = null): Promise<void> {
    event.stopPropagation();
    if (machine !== null) {
      this.machine = Number(machine);
    }
    this.currentNFT = nft;
    this.currentStage = (parseInt(nft?.ageStage, 0));
    this.stageAddress = this.contractAdresses[this.currentStage];
    this.userAddress = this.connectionService.getWalletAddress();
    const stagePrice: any = await this.nftService.getPrices(this.stageAddress);
    this.currentPriceStage = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [stagePrice.usdAmountForBNB.toString()]);
    
    const openedMyPets = this.storageService.getOpenedMyPets();
    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
      {
        title: 'upgrading.start.age_' + this.currentStage + '.title', 
        message: 'upgrading.start.age_' + this.currentStage + '.text', 
        // button: 'upgrading.start.age_' + this.currentStage + '.button',
        hideButton: true,
        nft: this.currentNFT
      }
    );

    try {
      const respApprove: boolean = await this.tokenService.tokenApprovement(
        this.stageAddress, this.userAddress, contractAddresses.bamboo, this.currentPriceStage.toString()
      );
      if (respApprove) {
        const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
          this.stageAddress, contractAddresses.pet
        );
        if (respNFTApprove) {
          // confirmationDialog.close();
          await this.depositNFT(confirmationDialog);
        }
      }
    } catch (error: unknown) {
      this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
      this.dialogService.openDialog(
        DialogInfoPopComponent,
        ['error-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
        { type: 'error', phase: 'startError', animal: nft }
      );
    }
  }

  async depositNFT(dialog: MatDialogRef<DialogConfirmationComponent>): Promise<void> {
    console.log('DEPOSIT NFT: Selected TokenID', this.currentNFT?.tokenId);
    const openedMyPets = this.storageService.getOpenedMyPets();

    try {
      const selectedMethod = this.getWithdrawMethod();
      const tx = await this.connectionService.writeContract(
        this.AbiGroup[this.currentStage].address,
        this.AbiGroup[this.currentStage].type.abi,
        selectedMethod.method,
        selectedMethod.params
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
        dialog.close();
        // dialogInfo.close();
        this.dialogService.openDialog(
          DialogConfirmationComponent,
          ['confirmation-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
          {
            title: 'upgrading.startSuccess.age_' + this.currentStage + '.title', 
            message: 'upgrading.startSuccess.age_' + this.currentStage + '.text', 
            nft: this.currentNFT
            // button: 'actions.confirm'
          }
        );
      }
    } catch (error: any) {
      this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
      const message = this.walletMessagesService.getMessage(error.message, 'upgrading.errorUpgrading.text');
      dialog.close();
      // dialogInfo.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
        {
          type: 'error',
          title: 'upgrading.errorUpgrading.title',
          nft: this.currentNFT,
          message
        }
      );
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Forward Upgrade
  /*------------------------------------------------------------------------------------*/

  async reduceEvolutionTime(event: any, nft: NFT | undefined, machine = null): Promise<void> {
    event.stopPropagation();

    this.currentNFT = nft;
    this.currentStage = parseInt(nft?.ageStage, 0);
    this.machine = machine;
    this.stageAddress = this.contractAdresses[this.currentStage];
    
    const stagePrice: any = await this.nftService.getPrices(this.stageAddress);
    const openedMyPets = this.storageService.getOpenedMyPets();
    
    let evolutionInfo: IEvolutionInfo = await this.nftService.getEvolutionInfo(this.stageAddress);
    const priceBNB = this.utilsService.fromWei(evolutionInfo.usdAmountForBNB);
    console.log('REDUCE TIME: Evolution info', evolutionInfo);

    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
      {
        title: 'upgrading.forwardStart.age_' + this.currentStage + '.title', 
        message: 'upgrading.forwardStart.age_' + this.currentStage + '.text', 
        button: 'upgrading.forwardStart.age_' + this.currentStage + '.button',
        hideButton: true,
        machine: this.machine,
        nft: this.currentNFT,
        price: this.utilsService.getPriceDollars(evolutionInfo.usdAmountForBAMBOO),
        priceBNB,
        start: true,
        time: evolutionInfo.time,
        timeReduced: evolutionInfo.timeReduced
      }
    );

    confirmationDialog.afterClosed().subscribe(async (result: boolean) => {
      const userAddress = this.connectionService.getWalletAddress();
      this.currentPriceStage = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [evolutionInfo.usdAmountForBNB.toString()]);
      if (result) {
        confirmationDialog.close();
        const dialogInfo = this.dialogService.openDialog(
          DialogConfirmationComponent,
          ['confirmation-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
          {
            title: 'upgrading.forwardProcessing.age_' + this.currentStage + '.title', 
            message: 'upgrading.forwardProcessing.age_' + this.currentStage + '.text', 
            // button: 'upgrading.forwardProcessing.age_' + this.currentStage + '.button',
            hideButton: true,
            machine: this.machine,
            nft: this.currentNFT,
            price: this.utilsService.getPriceDollars(evolutionInfo.usdAmountForBAMBOO),
            start: true
          }
        );

        try {
          const respApprove: boolean = await this.tokenService.tokenApprovement(
            this.stageAddress, userAddress, contractAddresses.bamboo, this.currentPriceStage.toString()
          );
          if (respApprove) {
            const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
              this.stageAddress, contractAddresses.pet
            );
            if (respNFTApprove) {
              const evolutionParam = (machine === null) ? this.currentNFT?.tokenId : Number(machine);
              const tx = await this.connectionService.writeContract(
                this.AbiGroup[this.currentStage].address,
                this.AbiGroup[this.currentStage].type.abi,
                'reduceEvolutionTime',
                [ evolutionParam ],
                this.currentPriceStage.toString() 
              );
              const resp = await waitForTransaction(tx);
              if (resp) {
                this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
                confirmationDialog.close();
                dialogInfo.close();
                this.dialogService.openDialog(
                  DialogConfirmationComponent,
                  ['confirmation-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
                  {
                    title: 'upgrading.forwardSuccess.age_' + this.currentStage + '.title', 
                    message: 'upgrading.forwardSuccess.age_' + this.currentStage + '.text', 
                    // button: 'upgrading.forwardSuccess.age_' + this.currentStage + '.button',
                    hideButton: true,
                    machine: this.machine,
                    nft: this.currentNFT,
                    time: evolutionInfo.time,
                    timeReduced: evolutionInfo.timeReduced
                  }
                );
              }
            }
          }
        } catch (error: any) {
          this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
          const message = this.walletMessagesService.getMessage(error.message, 'upgrading.errorForwarding.text');
          confirmationDialog.close();
          dialogInfo.close();
          console.error('REDUCE TIME: forwardUpgrade Error', error);
          this.dialogService.openDialog(
            DialogInfoComponent,
            ['error-dialog-container', openedMyPets ? this.openedMyPetsClass : ''],
            {
              type: 'error',
              title: 'upgrading.errorForwarding.title',
              nft: this.currentNFT,
              message
            }
          );
        }
      }
    });
  }

  /*------------------------------------------------------------------------------------*\
    $Get Contract
  /*------------------------------------------------------------------------------------*/

  private getWithdrawMethod(): any {
    switch(this.currentStage) {
      case 0:
        return {
          method: 'withdraw',
          params: [this.currentNFT.tokenId]
        };
      case 1:
        return {
          method: 'parkWithdraw',
          params: [this.currentNFT.tokenId]
        }
      case 2:
        return {
          method: 'schoolWithdraw',
          params: [this.currentNFT.tokenId]
        }
      case 3:
        return {
          method: 'withdraw',
          params: [this.machine]
        }
    }
  }

}
