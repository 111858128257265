<div class="container-buttons" [class]="customClass">
    <!-- Countdown -->
    <ng-container [ngTemplateOutlet]="tplCountDown"></ng-container>

    <!-- Buttons -->
    <ng-container [ngTemplateOutlet]="tplInventoryButtons" *ngIf="type === 'animal-list' || currentLocation === 'tournaments'"></ng-container>
    <ng-container [ngTemplateOutlet]="tplSectionButtons" *ngIf="type !== 'animal-list' && currentLocation !== 'tournaments'"></ng-container>
</div>

<!-- Template CountDown -->

<ng-template #tplCountDown>
    <app-timer [disabledButton]="disabledButton" [counter]="counter"  [counterPlayers]="counterPlayers" [customClass]="timerClass" [item]="item" [machine]="machine" [type]="type" *ngIf="!isCompleted && endTimestamp > 0 && !item.tournamentInfo"></app-timer>
    <app-timer [disabledButton]="disabledButton" [counter]="counter"  [counterPlayers]="counterPlayers" [customClass]="timerClass" [item]="item" [machine]="machine" [type]="type" *ngIf="cooldownActive && currentLocation === 'tournaments'"></app-timer>
</ng-template>

<!-- Template button -->

<ng-template #tplInventoryButtons>
    <!-- Incubate -->
    <app-upgrade-button action="incubate" [customClass]="buttonClass" [item]="item" *ngIf="endTimestamp === 0 && currentLocation === 'egg' && !hideUpgrade"></app-upgrade-button>

    <!-- Deposit -->
    <app-upgrade-button action="deposit" [customClass]="buttonClass" [item]="item" *ngIf="endTimestamp === 0 && (currentLocation !== 'egg' && currentLocation !== 'adult') && !hideUpgrade"></app-upgrade-button>
    
    <!-- Train -->
    <app-upgrade-button action="train" [customClass]="buttonClass" [item]="item" [machine]="machine" *ngIf="endTimestamp === 0 && (!item.tournamentInfo) && (machine !== null) && currentLocation === 'adult' && !hideUpgrade"></app-upgrade-button>    

    <!-- Upgrade -->
    <app-upgrade-button [customClass]="buttonClass" [item]="item" [type]="type" *ngIf="item.endTimestamp > 0 && isCompleted && currentLocation !== 'adult' && !hideUpgrade"></app-upgrade-button>

    <!-- Claim -->
    <app-upgrade-button action="claim" [type]="type" [customClass]="buttonClass" [item]="item" [machine]="machine" *ngIf="(item.endTimestamp > 0) && isCompleted && currentLocation === 'adult'"></app-upgrade-button>
</ng-template>

<ng-template #tplSectionButtons>
    <!-- Claim -->
    <app-upgrade-button action="claim" [type]="type" [customClass]="buttonClass" [item]="item" [machine]="machine" *ngIf="isCompleted && type === 'gym'"></app-upgrade-button>

    <!-- Upgrade -->
    <app-upgrade-button [customClass]="buttonClass" [item]="item" [type]="type" *ngIf="isCompleted && type !== 'gym'"></app-upgrade-button>
</ng-template>