import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { waitForTransaction } from '@wagmi/core';
import { BehaviorSubject } from 'rxjs';

import { NFT } from '@interfaces/nft';
import { IEvolutionInfo } from '@interfaces/evolution-info';
import { TournamentInfo } from '@interfaces/tournaments/tournament-info';
import { DialogClaimSelectTypeComponent } from '@pages/tournaments/components/dialog/dialog-claim-select-type/dialog-claim-select-type.component';
import { DialogConfirmRegisterPlayerComponent } from '@pages/tournaments/components/dialog/dialog-confirm-register-player/dialog-confirm-register-player.component';
import { DialogInfoTournamentComponent } from '@pages/tournaments/components/dialog/dialog-info-tournament/dialog-info-tournament.component';
import { DialogInfoTournamentMessagesComponent } from '@pages/tournaments/components/dialog/dialog-info-tournament-messages/dialog-info-tournament-messages.component';
import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { TokenService } from '@services/contracts/token/token.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { contractAddresses } from '@static/contract-address';
import { STATIC_TOURNAMENT } from '@static/tournaments';
import {
  ConfigStorageContract,
  CreatureContract,
  EnduranceContract,
  FlexibilityContract,
  OraclePricesContract,
  SpeedContract,
  StrengthContract,
  TournamentContract
} from '@static/abi';

/**
 * 
 * $Set NFT Tournaments
 * $Start Dialog
 * $Cooldown
 * $Withdraw pet in tournament
 * $Withdraw all pets in tournament
 * $Deposit
 * $Get hours
 * $Get Tournament Ticket
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class TournamentService {

  public currentTournamentKey = '';
  public currentTournamentKeyObs = new BehaviorSubject(this.currentTournamentKey);
  public currentTournament = '';
  private currentPriceTournament = 0;
  private NFTInTournaments: NFT[] = [];
  private selectedNFT: NFT | undefined = undefined;
  private selectedTournament = '';
  private tournamentAddress = '';
  private userAddress = '';
  private readonly staticTournamentsList: { [key: string]: string }[] = STATIC_TOURNAMENT;
  private readonly contractGroup: { [key: string]: any } = {
    endurance: { contract: EnduranceContract, address: contractAddresses.enduranceTournament },
    flexibility: { contract: FlexibilityContract, address: contractAddresses.flexibilityTournament },
    speed: { contract: SpeedContract, address: contractAddresses.speedTournament },
    strength: { contract: StrengthContract, address: contractAddresses.strengthTournament },
    manager: { contract: TournamentContract, address: contractAddresses.tournamentManager }
  };

  constructor(
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private nftService: NftService,
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) {
    this.userAddress = this.connectionService.getWalletAddress();
  }

  /*------------------------------------------------------------------------------*\
   * $Set NFT Tournaments
  /*------------------------------------------------------------------------------*/

  public getNFTInTournaments(): NFT[] {
    return this.NFTInTournaments;
  }

  public setNFTInTournaments(nft: NFT[]): void {
    this.NFTInTournaments = nft;
  }
  
  /*------------------------------------------------------------------------------*\
   * $Start Dialog
  /*------------------------------------------------------------------------------*/

  async tournamentStart(tournament: { name: string, type: string }, nft: NFT): Promise<void> {

    this.selectedTournament = tournament.type;    
    this.tournamentAddress = this.contractGroup[this.selectedTournament.toLowerCase()].address;

    const stagePrice: any = await this.nftService.getPrices(this.tournamentAddress);
    this.currentPriceTournament = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [stagePrice.usdAmountForBNB.toString()]);
    this.userAddress = this.connectionService.getWalletAddress();

    const textData = {
      title: 'tournament.hasPlayer.title',
      message: 'tournament.hasPlayer.message',
      button: 'tournament.hasPlayer.button'
    };
    const dialog = this.dialogService.openDialog(
      DialogInfoTournamentComponent,
      'info-tournament-dialog-container',
      {
        textData,
        nft,
        player: nft.tokenId,
        tournament: tournament,
        price: this.utilsService.getPriceDollars(stagePrice.usdAmountForBAMBOO)
      }
    ); 
    dialog.afterClosed().subscribe(async(result: boolean) => {
      if (result) {
        try {
          this.selectedNFT = nft;
          const respApprove: boolean = await this.tokenService.tokenApprovement(
            this.tournamentAddress, this.userAddress, contractAddresses.bamboo, this.currentPriceTournament.toString()
          );
          if (respApprove) {
            const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
              this.tournamentAddress, contractAddresses.pet
            );
            if (respNFTApprove) {
              dialog.close();
              await this.depositNFT();
            }
          }
        } catch (error: any) {
          const message = this.walletMessagesService.getMessage(error.message, 'tournamentInfo.startError.message');
          const textData = {
            title: 'tournamentInfo.startError.title',
            message
          };
          this.dialogService.openDialog(
            DialogInfoTournamentMessagesComponent,
            ['error-dialog-container', 'fix-center'],
            { animal: nft, ...textData, type: 'error' }
          );
        }
      }
    });
  }

  /*------------------------------------------------------------------------------*\
   * $Cooldown
  /*------------------------------------------------------------------------------*/

  async reduceEvolutionTime(event: any, nft: NFT, tournamentType: string): Promise<any> {
    event.stopPropagation();

    this.selectedTournament = tournamentType;
    this.tournamentAddress = this.contractGroup[this.selectedTournament.toLowerCase()].address;
    const stagePrice: any = await this.nftService.getPrices(this.tournamentAddress);
    const evolutionInfo: IEvolutionInfo = await this.nftService.getEvolutionInfo(this.tournamentAddress);
    this.currentPriceTournament = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [evolutionInfo.usdAmountForBNB.toString()]);
    this.userAddress = this.connectionService.getWalletAddress();
    
    const cooldownTime = await this.getCooldownHours();

    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      'confirmation-dialog-container',
      {
        title: 'tournament.reduceCooldown.title', 
        message: 'tournament.reduceCooldown.text', 
        button: 'tournament.reduceCooldown.button',
        price: this.utilsService.getPriceDollars(evolutionInfo.usdAmountForBAMBOO)
      }
    );

    confirmationDialog.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        confirmationDialog.close();
        const textDataResult = {
          title: 'tournamentInfo.forwardProcessing.title',
          message: 'tournamentInfo.forwardProcessing.message'
        };
        const dialogInfo = this.dialogService.openDialog(
          DialogInfoTournamentMessagesComponent,
          'info-dialog-container',
          { type: 'info', ...textDataResult, animal: nft }
        );
        
        try {
          const respApprove: boolean = await this.tokenService.tokenApprovement(
            this.tournamentAddress, this.userAddress, contractAddresses.bamboo, this.currentPriceTournament.toString()
          );
          if (respApprove) {
            const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
              this.tournamentAddress, contractAddresses.pet
            );
            
            if (respNFTApprove) {
              const tx = await this.connectionService.writeContract(
                contractAddresses.tournamentManager,
                TournamentContract.abi,
                'reduceCooldownTime',
                [nft.tokenId, this.selectedTournament + 'Tournament'],
                this.currentPriceTournament.toString()
              );

              const resp = await waitForTransaction(tx);
              if (resp) {
                dialogInfo.close();
                this.nftService.removeSavedNFT(Number(nft.tokenId));
                const textData = {
                  title: 'tournamentInfo.forwardSuccess.title',
                  message: 'tournamentInfo.forwardSuccess.message'
                };
                this.dialogService.openDialog(
                  DialogInfoTournamentMessagesComponent,
                  'success-dialog-container',
                  {
                    type: 'success',
                    ...textData,
                    animal: nft,
                    time: cooldownTime
                  }
                );
              }
            }
          }
        } catch (error: any) {
          this.nftService.removeSavedNFT(Number(nft.tokenId));
          const message = this.walletMessagesService.getMessage(error.message, 'tournamentInfo.errorForwarding.message');
          dialogInfo.close();
          console.error('REDUCE TIME: forwardUpgrade Error', error);
          const textData = {
            title: 'tournamentInfo.errorForwarding.title',
            message
          };
          this.dialogService.openDialog(
            DialogInfoTournamentMessagesComponent,
            ['error-dialog-container', 'fix-center'],
            { type: 'error', ...textData, animal: nft }
          );
        }
      }
    });

  }

  /*------------------------------------------------------------------------------*\
   * $Withdraw pet in tournament
  /*------------------------------------------------------------------------------*/

  async withdrawOne(nft: NFT, tournamentType: string): Promise<void> {
    console.log('WITHDRAW ONE NFT: Selected TokenID', nft.tokenId);

    this.selectedTournament = tournamentType;    
    const tickets = await this.getTournamentTicket();
    const textDataInfo = {
      title: 'tournamentInfo.finishProcessing.title',
      message: 'tournamentInfo.finishProcessing.message'
    };
    const dialogInfo = this.dialogService.openDialog(
      DialogInfoTournamentMessagesComponent, 
      'info-dialog-container', 
      {
        type: 'info',
        ...textDataInfo,
        animal: nft,
        showRewards: true,
        tickets
      }
    );
    
    try {
      const tx = await this.connectionService.writeContract(
        contractAddresses.tournamentManager,
        TournamentContract.abi,
        'withdrawPetAndGetRewards',
        [nft.tokenId, this.selectedTournament + 'Tournament']
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        dialogInfo.close();
        const textData = { title: 'tournamentInfo.startSuccess.title', message: 'tournamentInfo.startSuccess.message' };
        this.dialogService.openDialog(
          DialogInfoTournamentMessagesComponent, 
          'info-dialog-container', 
          {
            type: 'info',
            ...textData,
            animal: nft,
            showRewards: true,
            tickets
          }
        );
      }
    }
    catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'tournamentInfo.errorWithdrawing.message');
      dialogInfo.close();
      const textData = {
        title: 'tournamentInfo.errorWithdrawing.title',
        message 
      };
      this.dialogService.openDialog(
        DialogInfoTournamentMessagesComponent,
        ['error-dialog-container', 'fix-center'],
        { type: 'error', ...textData, animal: nft }
      );
    }
  }

  /*------------------------------------------------------------------------------*\
   * $Withdraw all pets in tournament
  /*------------------------------------------------------------------------------*/

  async withdrawAll(nft: any[], dialogRef?: MatDialogRef<DialogClaimSelectTypeComponent>): Promise<void> {
    const nftAllTokenId = nft.map((nft:NFT) => Number(nft.tokenId));
    console.log('WITHDRAW: nft all token id. ', nftAllTokenId);

    const tournamentInfo: TournamentInfo = nft[0]?.tournamentInfo; 
    const getValueTournament: any = (this.staticTournamentsList.find(tournament => tournament.key === tournamentInfo?.tournamentType))?.type;
    this.selectedTournament = getValueTournament;
    const tickets = await this.getTournamentTicket();
    
    const textDataInfo = {
      title: 'tournamentInfo.finishAllProcessing.title',
      message: 'tournamentInfo.finishAllProcessing.message'
    };
    const dialogInfo = this.dialogService.openDialog(
      DialogInfoTournamentMessagesComponent,
      'info-dialog-container',
      {
        type: 'info',
        ...textDataInfo,
        animal: nft,
        showAllRewards: true,
        tickets
      }
    );
    try {
      const tx = await this.connectionService.writeContract(
        contractAddresses.tournamentManager,
        TournamentContract.abi,
        'withdrawPetsAndGetRewards',
        [nftAllTokenId, this.currentTournament]
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        dialogInfo.close();
        dialogRef?.close();
        const textData = {
          title: 'tournamentInfo.startAllSuccess.title',
          message: 'tournamentInfo.startAllSuccess.message'
        };
        this.dialogService.openDialog(
          DialogInfoTournamentMessagesComponent,
          'info-dialog-container',
          {
            type: 'info',
            ...textData,
            animal: nft,
            showAllRewards: true,
            tickets
          }
        );
      }
    }
    catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'tournamentInfo.errorWithdrawing.message');
      dialogInfo.close();
      const textData = {
        title: 'tournamentInfo.errorWithdrawing.title',
        message 
      };
      this.dialogService.openDialog(
        DialogInfoTournamentMessagesComponent,
        ['error-dialog-container', 'fix-center'],
        { type: 'error', ...textData, animal: nft }
      );
    }
  }

  /*------------------------------------------------------------------------------*\
   * $Deposit
  /*------------------------------------------------------------------------------*/

  private async depositNFT(): Promise<void> {
    const tokenId = Number(this.selectedNFT?.tokenId);
    console.log('DEPOSIT NFT: Selected TokenID', tokenId);
    console.log('TOURNAMENT: Selected tournament', this.selectedTournament);
    const textData = {
      title: 'tournament.registerPlayer.title',
      message: 'tournament.registerPlayer.message',
      button: 'tournament.registerPlayer.button'
    };
    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmRegisterPlayerComponent,
      'info-tournament-dialog-container',
      { textData, url: this.selectedTournament }
    );
    try {
      const tx = await this.connectionService.writeContract(
        contractAddresses.tournamentManager,
        TournamentContract.abi,
        'registerPet',
        [tokenId, this.selectedTournament + 'Tournament'],
        this.currentPriceTournament.toString()
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        confirmationDialog.close();
        const textData = {
          title: 'tournamentInfo.depositSuccess.title',
          message: 'tournamentInfo.depositSuccess.message'
        };
        this.dialogService.openDialog(
          DialogInfoTournamentMessagesComponent,
          'success-dialog-container',
          { type: 'success', ...textData, animal: this.selectedNFT }
        );
      };
    } catch (error: any) {
      const message = this.walletMessagesService.getMessage(error.message, 'tournament.registerPlayer.error.message');
      console.error('DEPOSIT NFT', error.message);
      confirmationDialog.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', 'fix-center'],
        {
          type: 'error',
          title: 'tournament.registerPlayer.error.title',
          message,
          button: 'tournament.registerPlayer.button'
        }
      );
    }
  }

  /*------------------------------------------------------------------------------*\
   * $Get Hours
  /*------------------------------------------------------------------------------*/

  private async getCooldownHours(): Promise<number> {
    const cooldownTime = await this.connectionService.readContract(contractAddresses.configStorage, ConfigStorageContract.abi, 'getEvolutionInfo', [this.tournamentAddress]);
    const timeToString = cooldownTime.time.toString();
    const seconds = parseInt(timeToString, 10);
    const hours = (seconds / 3600);
    const modifiedTime: any = hours.toFixed(2);
    const cooldownTimeReduced = (modifiedTime / 3).toFixed(2);
    return Number(cooldownTimeReduced);
  }

  /*------------------------------------------------------------------------------*\
   * $Get Tournament Ticket
  /*------------------------------------------------------------------------------*/

  private async getTournamentTicket(): Promise<string> {
    const getCurrentTournament = this.contractGroup[this.selectedTournament.toLowerCase()];
    return await this.connectionService.readContract(
      getCurrentTournament.address,
      getCurrentTournament.contract.abi,
      'ticketAmountForTournament'
    );
  }

}
