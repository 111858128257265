<h2>
    {{ data.title | translate }}
    <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>
<div class="container-dialog">
    <p>{{ data.message | translate: { minXP: minXp, statGained, type: ('stats.' + selectedStat.toLowerCase() | translate) } }}</p>
    
    <ng-container [ngTemplateOutlet]="TplStep1" *ngIf="currentStep === 1 && data.item?.experience >= minXp"></ng-container>
    <ng-container [ngTemplateOutlet]="TplStep2" *ngIf="currentStep === 2"></ng-container>
    <ng-container [ngTemplateOutlet]="TplStep3" *ngIf="currentStep === 3"></ng-container>
    
    <button *ngIf="data.item?.experience < minXp" appPreventDefault (click)="close()" translate>
        actions.close
    </button>
</div>

<!-- Templates -->

<ng-template #TplStep1>
    <h3>{{ data.subtitle | translate }}</h3>
    <ul class="stats-list">
        <li class="stat-strength" [class.is-active]="selectedStat === 'Strength'" (click)="changeStatSelected('Strength')">
            <img src="assets/images/game/stats/strength.svg" alt="{{ 'actions.sort.strength' | translate }}"> 
            <strong translate>stats.strength</strong>
            <span class="stat-select"></span>
        </li>
        <li class="stat-speed" [class.is-active]="selectedStat === 'Speed'" (click)="changeStatSelected('Speed')">
            <img src="assets/images/game/stats/speed.svg" alt="{{ 'actions.sort.speed' | translate }}"> 
            <strong translate>stats.speed</strong>
            <span class="stat-select"></span>
        </li>
        <li class="stat-flexibility" [class.is-active]="selectedStat === 'Flexibility'" (click)="changeStatSelected('Flexibility')">
            <img src="assets/images/game/stats/flexibility.svg" alt="{{ 'actions.sort.flexibility' | translate }}">
            <strong translate>stats.flexibility</strong> 
            <span class="stat-select"></span>
        </li>
        <li class="stat-endurance" [class.is-active]="selectedStat === 'Endurance'" (click)="changeStatSelected('Endurance')">
            <img src="assets/images/game/stats/endurance.svg" alt="{{ 'actions.sort.endurance' | translate }}">
            <strong translate>stats.endurance</strong>
            <span class="stat-select"></span>
        </li>
    </ul>
    <button *ngIf="data.item?.experience >= minXp" appPreventDefault (click)="nextStep()" 
            [disabled]="selectedStat === ''" translate>
        actions.confirm
    </button>
</ng-template>

<ng-template #TplStep2>
    <h3>{{ data.subtitle }}</h3>
    <ul class="summary-list">
        <li>
            <strong>{{ minXp }}</strong>
            <img class="icon-xp" src="assets/icons/tournaments/icon-xp.svg" alt="">
        </li>
        <li>
            <img class="icon-arrow" src="assets/icons/icon-arrow-right.svg" alt="">
        </li>
        <li>
           <strong>+{{ statGained }}</strong>
           <img class="icon-stat" [src]="'assets/images/game/stats/' + selectedStat.toLowerCase() + '.svg'" [alt]="'stats.' + selectedStat.toLowerCase() | translate">
        </li>
    </ul> 
    <button appPreventDefault (click)="nextStep()" translate>
        actions.confirm
    </button>
</ng-template>

<ng-template #TplStep3>
    <button appPreventDefault (click)="close()" translate>
        actions.close
    </button>
</ng-template>