import { Injectable } from '@angular/core';
import { ConnectionService } from '@services/connection/connection/connection.service';

/**
 * $Date now in unix format
 * $Difference hours between two dates
 * $Countdown
 * $Check limit hours
 * $Render date to format hh:mm:ss
 */

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  constructor(
    private readonly connectionService: ConnectionService,
  ) { }

  /*----------------------------------------------------------------------------*\
    $Date now in unix format
  /*----------------------------------------------------------------------------*/

  async currentTimestamp(): Promise<unknown> {
    const getBlockNumber: number = await this.connectionService.ethers.getBlockNumber();
    const resBlock = await this.connectionService.ethers.getBlock(getBlockNumber);
    return resBlock;
  }

  /*----------------------------------------------------------------------------*\
    $Difference hours between two dates
  /*----------------------------------------------------------------------------*/

  calculateTimeDifferenceHours(endTimestamp: number): boolean {
    const now: Date = new Date(Date.now());
    const timestamp: Date = new Date(Number(endTimestamp) * 1000);
    return now > timestamp;
  }

  /*----------------------------------------------------------------------------*\
    $Countdown
  /*----------------------------------------------------------------------------*/

  countDown(endTimestamp: string | number, includeDays = '', onlyHours = false): string {
    const countDownDate = endTimestamp;
    const date = new Date();
    const now = date.getTime();
    const targetDate = new Date(countDownDate);
    const targetTime = targetDate.getTime();
    const difference = targetTime - now;
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = (24 * days) + Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    if (onlyHours) {
      return hours.toString();
    }
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    let output = '';
    if (includeDays.length > 0) {
      output = this.convertFormatWithDays({ days, hours, minutes, seconds });
    } else {
      output = this.convertFormat({ hours, minutes, seconds });
    }
    return output;
  }

  /*----------------------------------------------------------------------------*\
    $Check limit hours(for tournament counters)
  /*----------------------------------------------------------------------------*/

  checkLimitHours(date: number, limit = 12): boolean {
    const hours = Number(this.countDown(date * 1000, '', true));
    return limit > hours;
  }

  /*----------------------------------------------------------------------------*\
    $Render date to format hh:mm:ss
  /*----------------------------------------------------------------------------*/

  private convertFormat(date: Date | { [key: string]: number }): string {
    let output = '';
    if (date instanceof Date) {
      output = ('0' + (date.getHours())).slice(-2) + ':' + ('0' + (date.getMinutes())).slice(-2) + ':' + ('0' + (date.getSeconds())).slice(-2);
    } else {
      const { hours, minutes, seconds } = date;
      output = ('0' + (hours)).slice(-2) + ':' + ('0' + (minutes)).slice(-2) + ':' + ('0' + (seconds)).slice(-2);      
    }
    return output;
  }

  private convertFormatWithDays(date: { [key: string]: number }): string {
    const { days, hours, minutes, seconds } = date;
    const output = 
      ('0' + (days)).slice(-2) + 'd ' +
      ('0' + (hours)).slice(-2) + 'h ' +
      ('0' + (minutes)).slice(-2) + 'm ' + 
      ('0' + (seconds)).slice(-2) + 's';      
    return output;
  } 

}
