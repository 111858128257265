import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-swiper-slider',
  templateUrl: './swiper-slider.component.html',
  styleUrls: ['./swiper-slider.component.scss']
})
export class SwiperSliderComponent implements OnInit {

  @Input() customClass?: string | undefined;
  @Output() moveSliderChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  moveSlider(val: boolean): void {
    this.moveSliderChanged.emit(val);
  }

}
