import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { TitleStrategy } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

import { CustomPageTitleStrategy } from '@strategies/custom-title/custom-title.strategy';
import { environment } from '@environments/environment';

const geti18nPaths = () => {
  const i18nFolder = '/assets/i18n';
  const i18nPaths: string[] = [
    `${ i18nFolder }/components/`,
    `${ i18nFolder }/deposit/`,
    `${ i18nFolder }/dialog/`,
    `${ i18nFolder }/landing/`,
    `${ i18nFolder }/legal/`,
    `${ i18nFolder }/metadata/`,
    `${ i18nFolder }/navigation/`,
    `${ i18nFolder }/nft/`,
    `${ i18nFolder }/subscribe-newsletter/`,
    `${ i18nFolder }/tournament/`,
    `${ i18nFolder }/upgrade/`,
    `${ i18nFolder }/wallet/`
  ];
  return i18nPaths;
};

export function httpTranslateLoaderFactory(_httpBackend: HttpBackend) {
  const i18nPaths: string[] = geti18nPaths();
  return new MultiTranslateHttpLoader(_httpBackend, i18nPaths);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.defaultLang,
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpBackend]
      }
    }),
  ],
  exports: [TranslateModule],
  providers: [
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy }
  ],
})
export class NGXTranslateModule { }
