import { Environment } from '@interfaces/environments';

export const environment: Environment = {
  production: false,
  network: 'testnet',
  chainId: 97,
  infuraId: '',
  pinata_api_key: '',
  pinata_api_secret_key: '',
  projectId: '1fdd3da123fbd73d63ac62c32ae8318d',
  api: 'https://nld007.backend.ica.ne2.dev-nexxyolabs.net',
  apiGraphQL: 'https://api.thegraph.com/subgraphs/name/nexxyolabsmikelariceta/hashapon-testing',
  defaultLang: 'en',
  mobileProvider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  mailchimp: {
    apiUrl: 'https://nld004.backend.mailer.webapi.dev-nexxyolabs.net/api/mailchimp/requests/subscribe',
    apiKey: 'gMx6Hi9LqGZ97NhTugUiXLTYiG+82g',
    project: 'Hashapon'
  }
};