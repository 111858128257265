import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { bsc, bscTestnet } from 'viem/chains';
import {
  configureChains,
  createConfig,
  getAccount,
  getNetwork,
  switchNetwork,
  readContract,
  writeContract
} from '@wagmi/core';
import { publicProvider } from '@wagmi/core/providers/public';
import { ethers } from 'ethers';

import Web3 from 'web3';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/html';

import { DialogInfoNetworkComponent } from '@dialogs/dialog-info-network/dialog-info-network.component';
import { environment } from '@environments/environment';
import { DialogService } from '@services/dialog/dialog.service';
import { StorageService } from '@services/storage/storage.service';
import { UtilsService } from '@services/utils/utils.service';
import { activeUser } from '@static/user';

/**
 * 
 * $Connect Account
 * $Check status connect
 * $Disconnect Account
 * $Get Wallet Address and Status
 * $Check Account
 * $Contracts
 * $Change RPC
 * $Init Ethers
 * $Set Token On Metamask
 * 
 */

declare let window: any;

// Set chain with selected environment
let chain: any;
chain = (environment.production) ? [bsc] : [bscTestnet];
// const chains = chain; // Chain switcher is always to chain on 0 position
const projectId = environment.projectId;

const { chains, publicClient } = configureChains(chain, [w3mProvider({ projectId })])
// const { publicClient } = configureChains(chains, [ publicProvider() ]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains } as any),
  publicClient
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
const web3modal = new Web3Modal({ projectId }, ethereumClient);
web3modal.setDefaultChain(chain[0]);

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  public ethers: any | undefined;
  public accounts: string[] = [];
  public userAccountObs: any = new BehaviorSubject<{}>({});
  public userAccount: any = this.userAccountObs.asObservable();
  private changingNetwork = false;
  private readonly mobileProvider = environment.mobileProvider;

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private storageService: StorageService,
    private utilsService: UtilsService
  ) {
    this.ethers = this.setEthers();
    this.checkWalletChanges();
    this.checkChangeRPC();
  }

  /*------------------------------------------------------------------------------------*\
    $Connect Account
  /*------------------------------------------------------------------------------------*/

  public async connectAccount(keepConnection = false): Promise<any> {
    try {
      console.log('WALLET: Open wallet connect modal', null, 'info');
      await this.openModal();
    } catch (error: any) {
      this.dialogService.openDialog(DialogInfoNetworkComponent, 'error-dialog-container', { type: 'error', title: 'error.connection.title', message: 'error.connection.description' });
      console.error('error.connection.description', error.message);
    }
  }

  async openModal(): Promise<any> {
    await web3modal.openModal();
  }

  /*------------------------------------------------------------------------------------*\
    $Check status connect
  /*------------------------------------------------------------------------------------*/
  
  async subscribeModal(): Promise<any> {
    web3modal.subscribeModal( () => {
      this.userAccountObs.next(getAccount());
      this.checkChangeRPC();
    });
    this.userAccountObs.next(getAccount());
  }

  /*------------------------------------------------------------------------------------*\
    $Disconnect Account
  /*------------------------------------------------------------------------------------*/

  public async disconnectAccount(path = '/play'): Promise<any> {
    try {
      // this.web3js = null;
      this.accounts = [];
      // this.accountStatusSource.next(this.accounts);
      this.storageService.clear();
      this.utilsService.walletIsConnected = false;
      this.router.navigate([path]);
      return true;
    } catch (error: unknown) {
      this.dialogService.openDialog(
        DialogInfoNetworkComponent,
        'error-dialog-container',
        {
          type: 'error',
          title: 'error.disconnection.title',
          message: 'error.disconnection.description'
        }
      );
      console.log('error.disconnection.description', null, 'error');
      return false;
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Get Wallet Address and Status
  /*------------------------------------------------------------------------------------*/

  getWalletAddress(): string {
    // return this.accounts[0];
    return this.userAccountObs.value.address;
  }

  autoConnectWallet(): void {
    wagmiConfig.autoConnect();
    this.checkChangeRPC();
  }

  /*------------------------------------------------------------------------------------*\
    $Check Account
  /*------------------------------------------------------------------------------------*/

  public async checkSyncAccount(): Promise<boolean> {
    await this.syncAccount();
    return (this.accounts[0] !== undefined);
  }

  public async syncAccount(): Promise<any> {
    /*if (this.accounts.length === 0) {
      console.log('WALLET: Please connect your wallet', null, 'info');
    }*/
    if (!this.userAccountObs.value.address) {
      console.log('Please connect your wallet.');
      this.openModal();
      return false;
    } else {
      return true;
    }

  }

  /**
   * Checks if wallet is connected or not
   * @returns true if wallet is connected
  */
  async isWalletConnected(): Promise<boolean> {
    if (this.userAccountObs.value.address) {
      return true;
    } else {
      return false;
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Contracts
  /*------------------------------------------------------------------------------------*/

  /**
   * Writes on given smart contract
   * @param contractAddress the address of the contract
   * @param abi the abi of the contract
   * @param functionName the name of the function
   * @param args the params of the function, empty array for no params
   */
  async writeContract(
    contractAddress: string,
    abi: any,
    functionName: string,
    args: any[] = [],
    overrideValue?: any
  ): Promise<any> {
    try {
      const userAddr = this.getWalletAddress();
      if (userAddr) {
        let value = 0;
        if (overrideValue) {
          value = overrideValue;
        }
        try {
          const chain: number = chains[0].id;
          const config: any = {
            address: contractAddress,
            abi,
            functionName,
            args,
            value,
            account: userAddr,
            chainId: chain,
          };
          return await writeContract(config);
        } catch (error: any) {
          console.error('WRITE CONTRACT: Error', error.message);
          throw error;
        }
      } else {
        this.openModal();
        console.error('WALLET: Please connect your wallet.');
        throw Error;
      }
    } catch (error: any) {
      // console.error('WRITE CONTRACT: Error', error.message);
      // repeat if rpc returns error 400
      if (error.toString().toLowerCase().includes('status: 400')) {
        console.log('Repeating write request to RPC');
        if (overrideValue) {
          await this.writeContract(
            contractAddress,
            abi,
            functionName,
            args,
            overrideValue
          );
        } else {
          await this.writeContract(contractAddress, abi, functionName, args);
        }
      } else {
        // console.error('WRITE CONTRACT: Error', error.message);
        throw error;
      }
    }
  }

  /**
   * Writes on given smart contract
   * @param contractAddress the address of the contract
   * @param abi the abi of the contract
   * @param functionName the name of the function
   * @param args the params of the function, empty array for no params
   */
  async readContract(
    contractAddress: string,
    abi: any,
    functionName: string,
    args: any[] = []
  ): Promise<any> {
    try {
      const chain: number = chains[0].id;
      const config: any = {
        address: contractAddress,
        abi,
        functionName,
        args,
        chainId: chain,
      };
      const res: any = await readContract(config);
      // Process result depending on type
      if (typeof res === 'object') {
        return await this.processReadContractResult(abi, functionName, res);
      } else if (typeof res === 'bigint') {
        return res.toString();
      } else {
        return res;
      }
    } catch (error: any) {
      // repeat if rpc returns error 400
      if (error.toString().toLowerCase().includes('status: 400')) {
        console.log('Repeating read request to RPC');
        await this.readContract(contractAddress, abi, functionName, args);
      } else {
        throw error;
      }
      console.error('CONTRACT: Error read contract', error.message);
    }
  }

  async processReadContractResult(
    abi: any,
    functionName: string,
    res: any
  ): Promise<any> {
    const outputs = abi.find((item: any) => item.name === functionName).outputs;
    const processedResult: any = {};
    if (outputs.length === res.length) {
      for (let i = 0; i < res.length; i++) {
        if (typeof res[i] === 'bigint') {
          processedResult[outputs[i].name] = res[i].toString();
        } else {
          processedResult[outputs[i].name] = res[i];
        }
      }
      return processedResult;
    } else {
      return res;
    }
  }

  /**
 * Returns a read-only instance of a smart contract
 * @param abi the contract abi
 * @param address the contract address
 * @returns instance of the contract
 */
  async getReadContract(abi: any, address: string): Promise<any> {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        chains[0].rpcUrls.default.http[0]
      );
      const contract = new ethers.Contract(address, abi, provider);
      return contract;
    } catch (error: unknown) {
      console.error('CONTRACT: Error read contract', error);
    }
  }

  /**
   * Gets the signature of the given data
   * @param dataToSign data to sign
   * @returns signature
   */
  async signData(dataToSign: string): Promise<any> {
    try {
      const provider = new ethers.providers.Web3Provider(Web3.givenProvider);
      const signer = provider.getSigner();
      return await signer.signMessage(dataToSign);
    } catch (error: unknown) {
      console.log(error);
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Change RCP
  /*------------------------------------------------------------------------------------*/

  private checkWalletChanges(): void {
    wagmiConfig.subscribe((res: any) => {
      try {
        console.log('Wagmi Config: ', res);
        if (res.status === 'connected' && res.data.account) {
          const newAccount = res.data.account;
          const newChain = res.data.chain;
          // Opens modal if detect change from one wallet to another
          if (activeUser[0] && activeUser[0] !== '' && activeUser[0].toLowerCase() !== newAccount.toLowerCase()) {
            this.openModal();
          }
          if (newChain.id !== chains[0].id) { this.checkChangeRPC(); }
          // Sets active user
          activeUser[0] = res.data.account;
          this.userAccountObs.next(getAccount());
        }
      } catch (error: unknown) {
        this.openModal();
        console.log('Wagmi Config Error. ', error);
      }
    });

  }
  
  // Checks if connected RPC corresponds with environment network

  private async checkChangeRPC(): Promise<any> {
    const network = getNetwork();
    console.log('Actual network: ', network);
    if (network.chain) {
      if (chains[0].id === network.chain.id) {
        return true;
      } else {
        return await this.changeRPC();
      }
    }
  }

  // Changes the wallet connected RPC, if not saved, calls to addRPC

  private async changeRPC(): Promise<any> {
    return new Promise(async () => {
      try {
        if (!this.changingNetwork) {
          this.changingNetwork = true;
          await switchNetwork({chainId: chains[0].id});
          this.changingNetwork = false;
        }
      } catch (error: unknown) {
        console.error('RPC: Change RPC Error', error);
        this.changingNetwork = false;
      }
    });
  }

  /*------------------------------------------------------------------------------------*\
    $Init Ethers
  /*------------------------------------------------------------------------------------*/

  private setEthers(): unknown {
    if (window.ethereum) {
      return new ethers.providers.Web3Provider(Web3.givenProvider);
    } else {
      return new ethers.providers.JsonRpcProvider(this.mobileProvider);
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Set Token On Metamask
  /*------------------------------------------------------------------------------------*/
  
  private async setTokenOnMetamask(options: { [key: string]: string }): Promise<any> {
    const { address, decimals, image, type, symbol } = options;
    this.ethers.currentProvider.sendAsync({
      method: 'wallet_watchAsset',
      params: {
        type, // Chain ERC20
        options: {
          address,
          symbol,
          decimals,
          image,
        },
      },
      id: Math.round(Math.random() * 100000)
    }, (error: any, data: any) => {
      if (!error) {
        if (data.result) {
          console.log('TOKEN: Token added');
        } else {
          console.log('TOKEN: Set Token Metamask', data);
          console.error('TOKEN: Set Token Metamask Error', error);
        }
      } else {
        console.error('TOKEN: Set Token Metamask Error', error.message);
      }
    });
  }
  

}
