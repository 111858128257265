import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentNode } from 'graphql';
import { TypedDocumentNode } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';

import {
  getNFTStageInWallet,
  getNFTStageInWalletAndToken
} from '@graphql/queries/pet/query.graphql';
import {
  getNFTTournamentByToken,
  getPetsinTournaments,
  getPetsinTournamentsWithTimestamp,
  getWalletTournamentNFT
} from '@graphql/queries/tournament/query.graphql';
import { DialogInfoPopComponent } from '@dialogs/dialog-info-pop/dialog-info-pop.component';
import { IEvolutionInfo } from '@interfaces/evolution-info';
import { NFT, NFTTournament } from '@interfaces/nft';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { GraphQLService } from '@services/graphql/graphql.service';
import { UtilsService } from '@services/utils/utils.service';
import { TimerService } from '@services/timer/timer.service';
import { contractAddresses } from '@static/contract-address';
import {
  ConfigStorageContract,
  CreatureContract,
  ERC721Contract,
  PetShopContract,
  TicketERC20Contract
} from '@static/abi';

/**
 * 
 * $Prices
 * $Tickets
 * $NFT Stats
 * $NFT Metadata
 * $Load NFT
 * $Can Upgrade
 * $Saved NFT
 * $Get Evolution Info
 * $Get Token URI
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class NftService {

  private $savedNFT = new BehaviorSubject([]);

  constructor(
    public dialogRef: MatDialogRef<DialogInfoPopComponent>,
    private connectionService: ConnectionService,
    private graphQLService: GraphQLService,
    private timerService: TimerService,
    private utilsService: UtilsService
  ) { }

  /*------------------------------------------------------------------------------------*\
    $Prices
  /*------------------------------------------------------------------------------------*/

  async getPrices(address: string): Promise<number[]> {
    return await this.connectionService.readContract(
      contractAddresses.configStorage,
      ConfigStorageContract.abi,
      'getPrices',
      [ address ]
    );
  }

  /*------------------------------------------------------------------------------------*\
    $Tickets
  /*------------------------------------------------------------------------------------*/

  async getTickets(): Promise<any> {
    return await this.connectionService.readContract(contractAddresses.petShop, PetShopContract.abi, 'petShopInfo', []);
  }

  async getTicketsByAddress(userAddress: string): Promise<any> {
    return await this.connectionService.readContract(contractAddresses.ticket, TicketERC20Contract.abi, 'balanceOf', [userAddress]);
  }

  /*------------------------------------------------------------------------------------*\
    $NFT Stats
  /*------------------------------------------------------------------------------------*/

  // Get the full value stats

  getStatValue(stat: number, animal: any): number {
    const statsGroup: string[] = ['strength', 'endurance', 'flexibility', 'speed'];
    const currentStat = Number(stat);
    const currentValue: number = parseInt(animal[statsGroup[currentStat]], 0);
    return currentValue;
  }

  /*------------------------------------------------------------------------------------*\
    $NFT Metadata
  /*------------------------------------------------------------------------------------*/

  // Get NFT Metadata

  async getNftData(tokenId: number | string, nftContractAddress: string): Promise<any> {
    // get token metadata URI and add owner and id
    const contract = await this.connectionService.readContract(nftContractAddress, ERC721Contract.abi, 'tokenURI', [tokenId]);
    if (contract) {
      const tokenMetadata = await fetch(contract).then((response) => response.json());
      const owner = await this.connectionService.readContract(nftContractAddress, ERC721Contract.abi, 'ownerOf', [tokenId]);
      tokenMetadata.owner = owner;
      tokenMetadata.tokenId = tokenId;
      return tokenMetadata;
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Load NFT
  /*------------------------------------------------------------------------------------*/

  ////////////// NFT Stage //////////////

  loadAllNFTStage(
    entity: DocumentNode | TypedDocumentNode<unknown, { [key: string]: unknown; }>,
    prop: string
  ): Observable<NFT[]> {
    return this.graphQLService.getAll(entity)
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0][prop]);
            return NFTResult;
            // return usersInventories[0][prop];
          }
          return usersInventories;
        })
      );
  }

  loadOneNFTStage(
    entity: DocumentNode | TypedDocumentNode<unknown, { [key: string]: unknown; }>,
    prop: string,
    tokenId: string
  ): Observable<NFT[]> {
    return this.graphQLService.getAll(entity, { tokenId })
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0][prop]);
            return NFTResult;
            // return usersInventories[0][prop];
          }
          return usersInventories;
        })
      );
  }

  ////////////// NFT Tournament //////////////

  loadAllNFTTournamentWithTimestamp(): Observable<NFTTournament[]> {
    return this.graphQLService.getAll(getPetsinTournamentsWithTimestamp)
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFTTournament[] = this.utilsService.toJSONUriString(usersInventories[0].petsInTournaments);
            return NFTResult;
            // return usersInventories[0].petsInTournaments;
          }
          return usersInventories;
        })
      );
  }

  loadAllNFTTournament(): Observable<NFTTournament[]> {
    return this.graphQLService.getAll(getPetsinTournaments)
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFTTournament[] = this.utilsService.toJSONUriString(usersInventories[0].petsInTournaments);
            return NFTResult;
            // return usersInventories[0].petsInTournaments;
          }
          return usersInventories;
        })
      );
  }

  loadOneNFTTournament(tokenId: string): Observable<NFT[]> {
    return this.graphQLService.getAll(getNFTTournamentByToken, { tokenId })
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0].petsInTournaments);
            return NFTResult;
            // return usersInventories[0].petsInTournaments;
          }
          return usersInventories;
        })
      );
  }

  selectNFT(): Observable<NFT[]> {
    return this.graphQLService.getAll(getWalletTournamentNFT, { ageStage_gte: 3 })
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0].petsInWallet);
            return NFTResult;
            // return usersInventories[0].petsInWallet;
          }
          return usersInventories;
        })
      );
  }
  
  ////////////// NFT Wallet //////////////

  loadAllNFTWallet(ageStage: number): Observable<NFT[]> {
    return this.graphQLService.getAll(getNFTStageInWallet, { ageStage })
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0].petsInWallet);
            return NFTResult;
            // return usersInventories[0].petsInWallet;
          }
          return usersInventories;
        })
      );
  }

  loadOneNFTWallet(ageStage: number, tokenId: string): Observable<NFT[]> {
    return this.graphQLService.getAll(getNFTStageInWalletAndToken, { ageStage, tokenId })
      .pipe(
        map((data: ApolloQueryResult<any>) => {
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            const NFTResult: NFT[] = this.utilsService.toJSONUriString(usersInventories[0].petsInWallet);
            return NFTResult;
            // return usersInventories[0].petsInWallet;
          }
          return usersInventories;
        })
      );
  }

  /*------------------------------------------------------------------------------------*\
    $Can Upgrade
  /*------------------------------------------------------------------------------------*/

  async canUpgrade(NFTEndTimestamp: number): Promise<boolean> {
    const endTimestamp = NFTEndTimestamp;
    const diffHours = this.timerService.calculateTimeDifferenceHours(endTimestamp);
    return diffHours;
  }

  /*------------------------------------------------------------------------------------*\
    $Saved NFT
  /*------------------------------------------------------------------------------------*/

  ////////////// Tx Progress //////////////

  getSavedNFT$(): Observable<number[]> {
    return this.$savedNFT;
  }

  removeAllSavedNFT(): void {
    this.$savedNFT.next([]);
  }

  removeSavedNFT(id: string | number): boolean {
    const getValues: any = this.$savedNFT.getValue();
    const tokenId = Number(id);
    const filteredData = getValues.filter((val: any) => val !== tokenId);
    if (getValues.includes(tokenId)) {
      this.$savedNFT.next(filteredData);
      return true;
    }
    return false;
  }

  saveNFT(id: string | number): boolean {
    const getValues: number[] = this.$savedNFT.getValue();
    const tokenId: number = Number(id);
    if (!getValues.includes(tokenId)) {
      this.$savedNFT.next([...getValues, tokenId] as any);
      return true;
    }
    return false;
  }

  /*------------------------------------------------------------------------------------*\
    $Get Evolution info
  /*------------------------------------------------------------------------------------*/

  async getEvolutionInfo(stageAddress: string): Promise<IEvolutionInfo> {
    let evolutionInfo: any;

    const getEvolutionInfo = await this.connectionService.readContract(
      contractAddresses.configStorage, ConfigStorageContract.abi, 'getEvolutionInfo', [stageAddress]
    );
    if (getEvolutionInfo) {
      evolutionInfo = getEvolutionInfo;
      const timeToString = evolutionInfo.time.toString();
      const seconds = parseInt(timeToString, 10);
      const hours = seconds / 3600;
      evolutionInfo.time = hours.toFixed(2);
      evolutionInfo.timeReduced = (hours / 3).toFixed(2);
      console.log('REDUCE TIME: Evolution Info. ', evolutionInfo);
    }

    return evolutionInfo;
  }

  /*------------------------------------------------------------------------------------*\
    $Get Token URI
  /*------------------------------------------------------------------------------------*/

  async tokenURI(tokenId: string): Promise<string> {
    return await this.connectionService.readContract(
      contractAddresses.pet, CreatureContract.abi, 'tokenURI', [tokenId]
    );
  }

}
