import { environment } from '@environments/environment';
import { ContractAddress } from '@interfaces/contract-address';

/*------------------------------------------------------------------------------------*\
  $Address
/*------------------------------------------------------------------------------------*/

const STATIC_CONTRACT_ADDRESS_TESTNET: ContractAddress = {
  bamboo: '0x47a881F4F3a6067ee0eBE24e2418a9E4E6f84e9B',
  bnb: '0x0000000000000000000000000000000000000000',
  busd: '0x78867bbeef44f2326bf8ddd1941a4439382ef2a7',
  configStorage: '0x96001F7A5Ff580C3E6205003C3b47F169b92fcf4',
  oraclePrices: '0xbBE62485b3e232445bed6CA87168d712fbc9A41e',
  pet: '0x2b198123b8648d073c259251B4Bc703116C3278C',
  petShop: '0x9d113E059Baf3f4232AEAFA416FD71516647D884',
  presale: '0xD05E2b7B03Ca45d241564Aa15Feb976bF012767A',
  ticket: '0x51f616FF51dC942E8c8A524C6485D9583d7d78aF',
  gym: '0xdACe01B300559342187231ea3c3Ee514ba128A15',
  incubator: '0xA4956b98a1B025D9d4C99c5C8a6CD851D0C1d566',
  park: '0x14Ed47a6986d6946eB60C139A3CA55F1EB41446a',
  school: '0x2E36beF7DA8B3e7466c06e71069086569a51db24',
  enduranceTournament: '0xfcEaDdD8639F96b84C6AA7e6d4f941E70f148377',
  flexibilityTournament: '0xFC345e37979F484407A47Dac9c89C65EEe751AdC',
  speedTournament: '0x6928D4A85E88Eee7cB63DF892E7438E85AE03087',
  strengthTournament: '0xb59E102B834a1a41E46a07417eba8C8E3b54BD93',
  tournamentManager: '0x6285C41f40b79fed1A02B70743aDBa11876e3504'
};

const STATIC_CONTRACT_ADDRESS_MAINNET: ContractAddress = {
  bamboo: '0x198abb2d13faa2e52e577d59209b5c23c20cd6b3',
  bnb: '0x0000000000000000000000000000000000000000',
  busd: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  configStorage: '0x8fb52B231aD53E6D9d483A7e291afce8F67d6ca6',
  oraclePrices: '0xF71e2e5a52874C07c79A39174f65a60D38625E48',
  pet: '0x4bB9d1827c792315868315bf053Ec2c27aF30B52',
  petShop: '0x4bBCB3F759715e4c51B065e19a89546c2350E1eF',
  presale: '0x0d86978333b5f3afD6Aa2A7aD48DFBe38ed13885',
  ticket: '0x70b139A94a226edB1A04c437cf1C102Eb5983bC5',
  gym: '0x3Bf691B96d0DE3492ba2174D46c7032bf1c4cA76',
  incubator: '0x0C5853a0a70742fE94173620f7281C03E75A25A2',
  park: '0x60415AcC424E05144265B39445A4f989F0bF896d',
  school: '0x76767d4Bb888d04C971AF1d66E518E5A36369325',
  enduranceTournament: '0x2aF01Bfec2f8171EC95AD481CFd0AC7951578e64',
  flexibilityTournament: '0x445fA769198CEcb678BeF4e209994F0c41ffDdbD',
  speedTournament: '0x0BBf53B7b906DA623bd9F293095f5BEacB3EffE4',
  strengthTournament: '0x4c459a69730253b98595c31B7B4F6D14990853dE',
  tournamentManager: '0x3Bdf55ABc488F00B16ff9dD4A5a9663eC1bBd729'
};

// Get testnet/mainnet contracts
export const contractAddresses: any = getTokenAddress();

function getTokenAddress(): ContractAddress | void {
  if (environment.network === 'testnet') {
    return STATIC_CONTRACT_ADDRESS_TESTNET;
  } else if (environment.network === 'bsc') {
    return STATIC_CONTRACT_ADDRESS_MAINNET;
  }
  // return STATIC_CONTRACT_ADDRESS_TESTNET;
}