import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defaultPetName'
})
export class DefaultPetNamePipe implements PipeTransform {

  private readonly defaultName = 'Eggy';

  transform(value: string | null): string {
    if (value && value.length > 0) {
      return value;
    } else {
      return this.defaultName;
    }
  }

}
