import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subscription, interval } from 'rxjs';
import { map } from 'rxjs/operators';

import { NFT } from '@interfaces/nft';
import { NftService } from '@services/contracts/nft/nft.service';
import { TimerService } from '@services/timer/timer.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * 
 * $Set timestamp
 * $Can upgrade
 * $Countdown actions
 * $Check Cooldown status
 * 
 */

@Component({
  selector: 'app-interaction-buttons',
  templateUrl: './interaction-buttons.component.html',
  styleUrls: ['./interaction-buttons.component.scss']
})
export class InteractionButtonsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() action = 'upgrade';
  @Input() disabledButton = false;
  @Input() item: any;
  @Input() buttonClass = '';
  @Input() counterPlayers = 0;
  @Input() customClass = '';
  @Input() timerClass = 'sk-blue';
  @Input() currentLocation = '';
  @Input() hideUpgrade = false;
  @Input() machine = null;
  @Input() petType = null;
  @Input() type = '';
  @Output() isCompletedAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isCooldownActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public cooldownActive = false;
  public counter: any = null;
  public endTimestamp: any;
  public isCompleted = false;

  private counterCooldownSubscription: Subscription | undefined;
  private counterSubscription: Subscription | undefined;

  constructor(
    private nftService: NftService,
    private timerService: TimerService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // Fix ts linter
    if (!this.item?.upgrading) {
      this.item = { ...this.item, upgrading: false }; 
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes) {
      await this.canUpgrade();
      this.setTimestamp();
      // Counter for items in stage
      if (!this.item?.upgrading && this.endTimestamp > 0) {
        this.counterSubscribe();
      }
      // Counter for items in tournaments
      if (!this.item?.isReduced && Number(this.item?.tournamentInfo?.endTimestamp) > 0) {
        this.counterCooldownSubscribe();
      }
    }
  }

  ngOnDestroy(): void {
    this.counterCooldownSubscription?.unsubscribe();
    this.counterSubscription?.unsubscribe();
  }

  /*-------------------------------------------------------------------------*\
    $Set timestamp
  /*-------------------------------------------------------------------------*/

  private setTimestamp(): void {
    if (this.type === 'type-tournament') {
      // this.endTimestamp = Number(this.item?.nextTournamentDate); 
      if (this.item?.nextTournamentDate) {
        this.endTimestamp = Number(this.item?.nextTournamentDate); 
      } else {
        this.endTimestamp = Number(this.item?.tournamentInfo.endTimestamp); 
      }
    } else {
      if (this.item?.tournamentInfo && this.type === 'animal-list') {
        this.endTimestamp = Number(this.item?.tournamentInfo.endTimestamp); 
      } else {
        this.endTimestamp = Number(this.item?.endTimestamp);
      }
    }
  }

  /*-------------------------------------------------------------------------*\
    $Can upgrade
  /*-------------------------------------------------------------------------*/

  private async canUpgrade(): Promise<void> {
    if (!this.item?.upgrading) {
      let timestamp = Number(this.item?.endTimestamp);
      if (this.item?.tournamentInfo && this.type === 'animal-list') {
        timestamp = Number(this.item?.tournamentInfo?.endTimestamp);
      }
      const respUpgrade: boolean = await this.nftService.canUpgrade(timestamp);
      if (respUpgrade) {
        this.item = { ...this.item, upgrading: respUpgrade };
      }
    }
    this.isCompleted = this.item?.upgrading;
  }

  /*---------------------------------------------------------------------------*\
    $Countdown actions
  /*---------------------------------------------------------------------------*/

  ////////////// Counter for items without tournamentInfo field //////////////

  private counterSubscribe(): void {
    this.counterSubscription = this.startCounter()
        .subscribe((time: string) => {
          this.counter = time;
          if (time === '00:00:00' || time.includes('-')) {
            this.counterSubscription?.unsubscribe();
            if (this.type === 'type-tournament') {
              this.counter = this.translateService.instant('countdown.ended');
            } else {
              this.isCompleted = true;
              this.item = { ...this.item, upgrading: true };
    
              if (this.item?.tournamentInfo && this.type === 'animal-list') {
                this.isCompletedAction.emit(true);
              }
            }
          }
        });
  }

  ////////////// Counter for items in tournaments //////////////

  private counterCooldownSubscribe(): void {
    this.counterCooldownSubscription = this.startCounter()
        .subscribe((time: string) => {
          this.counter = time;
          if (time === '00:00:00' || time.includes('-')) {
            this.counter = this.translateService.instant('countdown.ended');
            this.counterCooldownSubscription?.unsubscribe();
            this.isCompleted = true;
            this.item = { ...this.item, upgrading: true };
            this.checkCooldownIsActive(false);
          }
          if (this.counter.includes(':')) {
            const getHours = this.counter.split(':')[0];
            if (this.currentLocation === 'tournaments' && Number(getHours) <= 12) {
              this.checkCooldownIsActive();
            }
          }
        });
  }

  ////////////// Counter interval //////////////

  private startCounter(): Observable<string> {
    return interval(1000).pipe(
      map((num: number) => {
        return this.checkCounter();
      })
    );
  }

  ////////////// Check counter //////////////

  private checkCounter(): string {
    return this.timerService.countDown(this.endTimestamp * 1000);
  }

  /*-------------------------------------------------------------------------*\
    $Check Cooldown status
  /*-------------------------------------------------------------------------*/

  private checkCooldownIsActive(status = true): void {
    if (Number(this.item?.tournamentInfo?.endTimestamp) > 0) {
      if (this.item.isReduced) {
        this.cooldownActive = false;
        this.isCooldownActive.emit(false);
      } else {
        this.cooldownActive = status;
        this.isCooldownActive.emit(status);
      }
    }

    if (!status) {
      this.cooldownActive = false;
      this.isCooldownActive.emit(false);
    }
  }

}
