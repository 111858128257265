<h2>
    {{ (data.textData.title) | translate:{ tournamentName: ('tournaments.' + this.data?.tournament?.name | translate) } }}
    <app-close-button customClass="custom-close"></app-close-button>
</h2>

<figure *ngIf="data.nft">
    <img src="{{ data.nft.metadata?.uriString?.image_game | defaultPet }}" alt="{{ data.nft.metadata?.uriString?.name | defaultPetName }}">
    <figcaption>{{ data.nft.metadata?.uriString?.name | defaultPetName }}</figcaption>
</figure>

<!--<p *ngIf="data.nft">{{ data.textData.message | safe:'html' | translate:{ tournament: this.data.tournament.replace('-', ' ') } }}</p>-->
<p *ngIf="data.nft" [innerHTML]="data.textData.message | translate:{ 
    tournamentName: ('tournaments.' + this.data?.tournament?.name | translate),
    price: data?.price
}"></p>
<p *ngIf="!data.nft">{{ (data.textData.message) | translate }}</p>

<button appPreventDefault class="btn-confirm" (click)="openDialog()">
    {{ (data.textData.button) | translate }}
</button>