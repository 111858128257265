import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '@services/dialog/dialog.service';

@Component({
  selector: 'app-dialog-new-egg',
  templateUrl: './dialog-new-egg.component.html',
  styleUrls: ['./dialog-new-egg.component.scss']
})
export class DialogNewEggComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogNewEggComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.dialogRef.close();
  }

  openInventory(): void {
    this.dialogRef.close();
    if (this.data.presale) {
      this.dialogService.openPetsListDialog('eggs', null, null, true);
    } else {
      this.dialogService.openPetsListDialog('eggs');
    }
  }

  goTo(url: string): void {
    if (url) {
      this.close();
      this.router.navigate([url]);
    }
  }

}
