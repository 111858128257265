import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Apollo, TypedDocumentNode } from 'apollo-angular';
import { EmptyObject, MutationResult } from 'apollo-angular/types';
import { ApolloLink, ApolloQueryResult, DocumentNode, WatchQueryOptions } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';

import { DialogInfoPopComponent } from '@dialogs/dialog-info-pop/dialog-info-pop.component';
import { DialogService } from '@services/dialog/dialog.service';
import { StorageService } from '@services/storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class GraphQLService {

  private account: string | undefined;

  constructor(
    private _apollo: Apollo,
    private dialogService: DialogService,
    private storageService: StorageService
  ) {
    this.account = this.storageService.getAccount()?.toLowerCase();
  }

  getAll(
    query: DocumentNode | TypedDocumentNode<unknown, { [key: string]: unknown; }>,
    varsGroup = {}
  ): Observable<ApolloQueryResult<unknown>> { 
    const variables: { wallet: string | undefined; } = {
      wallet: this.account,
      ...varsGroup
    };
    const queryOptions: WatchQueryOptions<{ [key: string]: unknown; }, unknown> = {
      query,
      variables,
      pollInterval: 10000,// 20000,
      errorPolicy: 'all'
    };

    return this._apollo.watchQuery(queryOptions).valueChanges
      .pipe(
        tap((data: any) => {
          this.checkErrors(data.errors);
        })
      );
  }

  updateOne(
    mutation: DocumentNode | TypedDocumentNode<unknown, EmptyObject>,
    variables = {}
  ): Observable<MutationResult<unknown>> {
    return this._apollo.mutate({ mutation, variables });
  }

  checkErrors(errors = []): void {
    if (errors.length > 0) {
      this.showErrorDialog();
      return;
    }
  }

  onError(): ApolloLink {
    return onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.error(`GRAPHQL: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
     
      if (networkError) {
        console.error(`NETWORK: ${networkError}`);
      }
    });
  } 
  
  private showErrorDialog(): void {
    this.dialogService.openDialog(
      DialogInfoPopComponent,
      'error-dialog-container',
      { type: 'error', phase: 'dialog.database.title', animal: 'dialog.database.message' }
    );
  }
  

}