import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformAddress'
})
export class TransformAddressPipe implements PipeTransform {

  transform(currentAddress: string, size = 4): string {
    return currentAddress.substring(0, size) + '...' + currentAddress.substring(currentAddress.length - size);
  }

}
