<h2>
  <span>
    {{ 
      data.title | translate: {
        name: data?.nft?.metadata?.uriString?.name || 'Eggy',
        machine: data?.machine
      } 
    }}
  </span>
  <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>

<p>
  {{
    data.message | translate: {
      name: data?.nft?.metadata?.uriString?.name || data?.nftName || 'Eggy',
      machine: data?.machine,
      stat: ('stats.' + data?.stat | translate),
      price: data?.price,
      priceBNB: data?.priceBNB,
      amount: data?.amount,
      tickets: data?.tickets,
      time: data?.time,
      timeReduced: data?.timeReduced,
      limit: data?.limit,
      minXP: data?.minXP,
      statGained: data?.statGained,
      currentTournamentType: data?.data?.currentTournamentType
    }
  }}
</p>

<div class="confirmation" *ngIf="data.button">
  <div class="buttons">
    <button appPreventDefault class="button  btn-confirm  cancel" (click)="accept(false)" *ngIf="data.showCancelButton">
      {{ 'dialog.buttonCancel' | translate }}
    </button>
    <button appPreventDefault class="button  btn-confirm  accept" [class.btn-is-hidden]="data?.hideButton" [class.btn-large]="data?.data?.tournamentEnded" (click)="accept(true)">
      {{ data.button | translate }}
    </button>
  </div>
</div>