import { Component, Inject, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Favorite } from '@interfaces/favorite';
import { NFT, NFTTournament } from '@interfaces/nft';
import { DialogService } from '@services/dialog/dialog.service';
import { FavoriteService } from '@services/api/favorite/favorite.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { SortingService } from '@services/sorting/sorting.service';
import { TournamentService } from '@services/contracts/tournament/tournament.service';
import { STATIC_TOURNAMENT } from '@static/tournaments';
import { STATIC_PRELOAD_TIME } from '@static/nft';
import { DialogClaimSelectTypeComponent } from '../dialog-claim-select-type/dialog-claim-select-type.component';

/**
 * 
 * $Count Cooldown active
 * $Dialog
 * $Refresh
 * $Sort
 * $Lang
 * $Favorites
 * $NFT
 * 
 */

@Component({
  selector: 'app-dialog-inventory-tournament',
  templateUrl: './dialog-inventory-tournament.component.html',
  styleUrls: ['./dialog-inventory-tournament.component.scss']
})
export class DialogInventoryTournamentComponent implements OnInit, OnDestroy {

  @ViewChild('sortingMenu') sortingMenu: ElementRef | undefined;

  canClaimAll = false;
  currentLocation = 'tournaments';
  currentTournament = '';
  favorites: number[] = [];
  NFTData: NFT[] = [];
  petType = null;
  showLoader = true;
  showSortMenu = false;

  private activeClass = 'active';
  private itemsCooldownActive: any[] = [];
  private counterByStats: any = {};
  private filteredClaimAllNFT: NFT[] = [];
  private sortBy = 'stats';
  private sortType = 'asc';
  private readonly staticTournament: { [key: string]: string }[] = STATIC_TOURNAMENT;
  private favoritesSubscription: Subscription | undefined;
  private NFTSubscription: Subscription | undefined;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogInventoryTournamentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: DialogService,
    private favoriteService: FavoriteService,
    private nftService: NftService,
    private sortingService: SortingService,
    private tournamentService: TournamentService
  ) { }

  ngOnInit(): void {
    this.petType = this.data.petType;
    this.loadNFT();
    // this.getFavorites();
    this.currentTournament = this.tournamentService.currentTournament;
  }

  ngOnDestroy(): void {
    this.favoritesSubscription?.unsubscribe();
    this.NFTSubscription?.unsubscribe();
  }

  trackByID(index: number, NFT: NFT): string {
    return NFT.tokenId;
  }

  /*-------------------------------------------------------------------------*\
    $Count cooldown active
  /*-------------------------------------------------------------------------*/

  checkCooldownIsActive(item: { [key: string]: boolean | number }): void {
    const getPos = this.itemsCooldownActive.findIndex(((val: any) => val.tokenId === item.tokenId));
    if (getPos !== -1) {
      this.itemsCooldownActive[getPos] = item;
    } else {
      this.itemsCooldownActive.push(item);
    }
  }

  countCooldownActive(): number {
    const countActive = this.itemsCooldownActive.filter((val => val.status)).length;
    return countActive;
  }

  /*-------------------------------------------------------------------------*\
    $Dialog
  /*-------------------------------------------------------------------------*/

  async doClaimAll(): Promise<void> {
    if (this.tournamentService.currentTournamentKey === '' && this.tournamentService.currentTournament === '') {
      this.openDialog();
    } else {
      await this.tournamentService.withdrawAll(this.NFTData);
    }
  }

  openDialog(): void {
    this.dialogService.openDialog(
      DialogClaimSelectTypeComponent,
      'c-dialog-stats',
      {
        title: 'dialog.statClaim.title',
        message: 'dialog.statClaim.message',
        counterByStats: this.counterByStats,
        nftData: this.filteredClaimAllNFT
      }
    );
  }

  /*-------------------------------------------------------------------------*\
    $Refresh
  /*-------------------------------------------------------------------------*/

  refreshData(status: boolean): void {
    if (status) {
      this.loadNFT();
    }
  }

  /*-------------------------------------------------------------------------*\
    $Sort
  /*-------------------------------------------------------------------------*/

  setSorting(params: { [key: string]: string }): void {
    const { sort, sortType } = params;
    this.sortBy = sort;
    this.sortType = sortType;
    this.sortNFT();
  }

  sortingSwitch(): void {
    this.showSortMenu = !this.showSortMenu;
    const doc: any = document;
    this.sortingMenu?.nativeElement.classList.toggle(this.activeClass);
    doc.getElementById('sort-menu').classList.toggle(this.activeClass);
  }

  private sortNFT(): void {
    this.sortingService.sortBy = this.sortBy;
    this.sortingService.sortType = this.sortType;
    this.NFTData = this.sortingService.sortNFT(this.NFTData);
    if (this.sortBy === 'stats') {
      this.NFTData = this.sortingService.sortByFavorites(this.NFTData, this.favorites);
    }
  }

  /*-------------------------------------------------------------------------*\
    $Favorites
  /*-------------------------------------------------------------------------*/

  private getFavorites(): void {
    this.favoritesSubscription = this.favoriteService.getAllFavorites()
        .pipe(
          finalize(() => console.log('FAVORITES', this.favorites))
        )
        .subscribe((favorites: any) => {
          const getFavorites = this.getFavoritesID(favorites);
          this.favorites = getFavorites;
          this.sortNFT();
        });
  }

  private getFavoritesID(favorites: Favorite[]): number[] {
    if (favorites.length > 0) {
      return favorites.map((favorite: Favorite) => favorite.tokenId);
    }
    return [];
  }

  /*-------------------------------------------------------------------------*\
    $NFT
  /*-------------------------------------------------------------------------*/

  ////////////// Check claim all //////////////

  private checkClaimAll(result: any): boolean {
    const currentUrl = this.router.url;
    if (currentUrl.includes('/play/tournaments/')) {
      if (this.NFTData.length > 0) {
        const totalNFT = this.NFTData.length;
        const usedNFT = this.NFTData.filter((nft: any) => nft.tournamentInfo.endTimestamp !== null).length;
        return (totalNFT === usedNFT);
      }
      return false;
    }

    const sizeObj = Object.keys(result).length;
    let count = 0;
    for (let prop in result) {
      const val = result[prop];
      if (val === 0) {
        count ++;
      }
    }
    return (sizeObj !== count);
  }

  ////////////// load NFT //////////////

  private loadNFT(): void {
    this.showLoader = true;
    this.NFTSubscription = this.loadAllNFTTournament()
        .subscribe((nftData: NFTTournament[]) => {
          if (this.tournamentService.currentTournamentKey === '') {
            this.NFTData = nftData;
          } else {
            this.NFTData = nftData.filter((nft: NFTTournament) => nft.tournamentInfo.tournamentType === this.tournamentService.currentTournamentKey);
          }

          if (this.NFTData.length > 0) {
            this.getFavorites();
          }

          this.counterByStats = this.counterByTournamentType();
          // this.canClaimAll = this.checkClaimAll(this.counterByStats);

          setTimeout(() => {
            const resultCooldownActive = this.countCooldownActive();
            this.canClaimAll = this.checkClaimAll(this.counterByStats) && resultCooldownActive === 0;
          }, 1500);

          console.log('TOURNAMENTS: Load NFT', this.NFTData);
          setTimeout(() => this.showLoader = false, STATIC_PRELOAD_TIME);
        });
  }

  private loadAllNFTTournament(): Observable<NFTTournament[]> {
    return this.nftService.loadAllNFTTournament();
  }

  ////////////// Counter claim NFT //////////////

  private counterByTournamentType(): { [key: string]: number } {
    const result: { [key: string]: number } = {};

    this.staticTournament.forEach((item: any) => {
      result[item.type] = 0;
    });

    this.filteredClaimAllNFT = this.NFTData.filter((nft: any) => nft.tournamentInfo.endTimestamp !== null);

    this.filteredClaimAllNFT.forEach((nft: any) => {
      const tournamentType = nft.tournamentInfo.tournamentType;
      const getData = this.staticTournament.find((item: any) => item.key === tournamentType);
      let stat: any = getData ? getData.type : 0;
      result[stat] += 1;
    });

    console.log('NFT: Counter claim all. ', result);
    return result;
  }

}
