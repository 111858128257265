import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// External Dependencies
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core'
// import { HttpLinkModule } from 'apollo-angular-link-http';

import { ComponentsModule } from '@modules/components/components.module';
import { NGXTranslateModule } from '@modules/translate/translate.module';
import { SharedModule } from '@modules/shared/shared.module';
import { MaterialModule } from '@modules/material/material.module';
import { environment } from '@environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ApolloModule,
    // HttpLinkModule,
    NGXTranslateModule,
    ComponentsModule,
    MaterialModule,
    SharedModule,
  ],
  exports: [
    NGXTranslateModule,
    ComponentsModule,
    MaterialModule,
    SharedModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.apiGraphQL
          })
        }
      },
      deps: [HttpLink]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
