import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription, finalize, take } from 'rxjs';

import { NFT, NFTTournament } from '@interfaces/nft';
import { BracketTournament, ResultTournament, Tournament } from '@interfaces/tournaments/tournament';
import { TournamentRouter } from '@interfaces/tournaments/tournament-router';
import { TournamentMatchService } from '@services/api/tournament-match/tournament-match.service';

/**
 * 
 * $NFT
 * $Router
 * $Tournament
 * 
 */

@Component({
  selector: 'app-dialog-game-results',
  templateUrl: './dialog-game-results.component.html',
  styleUrls: ['./dialog-game-results.component.scss']
})
export class DialogGameResultsComponent implements OnInit, OnDestroy {

  public currentNFT: NFTTournament | undefined;
  public brackets: BracketTournament | undefined;
  public totalBrackets = 0;
  public NFTList: NFT[] = [];
  public showLoader = true;
  
  private routerInfoTournament: TournamentRouter = {};
  private currentInfoTournamentSubscription: Subscription | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<DialogGameResultsComponent>,
    private tournamentMatchService: TournamentMatchService,
  ) { }

  ngOnInit(): void {
    this.routerInfoTournament = this.getRouterData();
    this.NFTList = this.tournamentMatchService.getPlayers();

    if (this.NFTList.length > 0) {
      this.NFTList.map((nft: NFT) => {
        this.getInfoTournament(nft?.tokenId);
      });
    } else {
      this.NFTList.push(this.data.nft);
      this.getInfoTournament(this.data.nft?.tokenId);
    }
  }

  ngOnDestroy(): void {
    this.currentInfoTournamentSubscription?.unsubscribe();
  }

  close(): void {
    this.dialogRef.close();
  }

  trackByID(index: number, bracket: ResultTournament): string {
    return bracket.id;
  }

  /*---------------------------------------------------------------*\
    $NFT
  /*---------------------------------------------------------------*/

  ////////////// NFT Image ////////////// 

  getImage(tokenId: string | number): string | null {
    if (typeof tokenId === 'number') {
      let getImage = this.NFTList.filter((nft: NFT) => Number(nft.tokenId) === tokenId)[0];
      if (getImage?.metadata?.uriString) {
        return getImage?.metadata?.uriString?.image_game;
      }
      return null;
    }
    return null;
  }

  ////////////// Add Medal ////////////// 

  getMedal(key: string, isWinner: boolean): string | null {
    if (key === 'final') {
      return (isWinner) ? 'gold' : 'silver';
    }
    if (key === 'semifinals') {
      return (isWinner) ? 'bronze' : null;
    }
    return null;
  }

  ////////////// NFT Stats ////////////// 

  getStats(tokenId: string | number): NFT | null {
    if (typeof tokenId === 'number') {
      const getNFT = this.NFTList.filter((nft: NFT) => Number(nft.tokenId) === tokenId)[0];
      return getNFT ?? null;
    }
    return null;
  }

  /*---------------------------------------------------------------*\
    $Router
  /*---------------------------------------------------------------*/

  private getRouterData(): TournamentRouter {
    const { name, type } = this.tournamentMatchService.selectedTypeTournament;
    return { name, type };
  }

  /*---------------------------------------------------------------*\
    $Tournament
  /*---------------------------------------------------------------*/

  ////////////// Tournament Info //////////////  

  private getInfoTournament(tokenId: string): void {
    this.currentInfoTournamentSubscription = this.tournamentMatchService.getLastTournament(tokenId)
        .pipe(
          take(1),
          finalize(() => this.showLoader = false)
        ).subscribe((tournament: any) => {
          if (tournament.brackets) {
            this.getBrackets(tournament.brackets);
          }
          console.log('TOURNAMENTS: Results', tournament);
        });
  }

  private getBrackets(brackets: BracketTournament): void {
    if (Object.keys(brackets).length > 0) {
      this.brackets = brackets;
      this.totalBrackets = Object.keys(brackets).length;
    }
  }

}
