import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NFT } from '@interfaces/nft';
import { TournamentRouter } from '@interfaces/tournaments/tournament-router';
import { HttpService } from '@services/http/http.service';
import { StorageService } from '@services/storage/storage.service';
import { BracketTournament, ResultTournament } from '@interfaces/tournaments/tournament';

/**
 * 
 * $Api
 * $Players
 * $Filter
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class TournamentMatchService {

  public selectedTypeTournament: TournamentRouter = {};
  private account: string | undefined;
  private playersTournament: NFT[] = [];
  private readonly collection = 'tournaments';

  constructor(
    private httpService: HttpService,
    private storageService: StorageService
  ) {
    this.account = this.storageService.getAccount()?.toLowerCase();
  }

  /*------------------------------------------------------------------------------------*\
    $Api
  /*------------------------------------------------------------------------------------*/

  public getActiveTournaments(): Observable<Object> {
    return this.httpService.getAll(this.collection);
  }

  public getActiveTournament(keccak256ID: string): Observable<Object> {
    return this.httpService.getOne(`${ this.collection }/${ keccak256ID }`);
  }

  public getLastTournament(tokenId: string | undefined): Observable<Object> {
    return this.httpService.getOne(`${ this.collection }/lastCreatureTournament/${ tokenId }`);
  }

  /*------------------------------------------------------------------------------------*\
    $Players
  /*------------------------------------------------------------------------------------*/

  public getPlayers(): NFT[] {
    return this.playersTournament;
  }

  public setPlayers(nftList: NFT[]): void {
    this.playersTournament = nftList;
  }

  /*------------------------------------------------------------------------------------*\
    $Filter
  /*------------------------------------------------------------------------------------*/

  public filterByNFTWinner(tokenId: number, brackets: BracketTournament): ResultTournament | undefined {
    const bracketOrder = ['final', 'semifinals', 'playoff'];
    for (const bracket of bracketOrder) {
      const filteredResult: ResultTournament[] = (brackets as any)[bracket].filter(
        (match: ResultTournament) => match.creatureA.tokenId === tokenId || match.creatureB.tokenId === tokenId
      );

      if (filteredResult.length > 0) {
        return { bracket, ...filteredResult[0] };
      }
    }

    return undefined;
  }

}
