<ng-container [ngTemplateOutlet]="tplStep1" *ngIf="currentStep === 1"></ng-container>
<ng-container [ngTemplateOutlet]="tplStep2" *ngIf="currentStep === 2"></ng-container>

<!-- Templates -->

<ng-template #tplStep1>
  <h2>
    {{ 'dialog.presale.step1.title' | translate }}
    <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
  </h2>
  <div class="container">
    <p translate>dialog.presale.step1.message</p>
    <ul class="types-list">
      <li [class.is-selected]="selectedType?.id === i" (click)="selectType(type, i)" *ngFor="let type of petTypesList; let i = index">
        <strong>{{ type }}</strong>
        <img src="/assets/images/game/eggs/egg-{{ type }}.png" alt="{{ type }} Egg">
      </li>
    </ul>
    <button class="btn-confirm  btn-next" (click)="nextStep()" [disabled]="selectedType?.id === undefined" translate>
      buttons.confirm
    </button>
  </div>
</ng-template>

<ng-template #tplStep2>
  <h2>
    <img class="ico-back" src="/assets/icons/icon-back-arrow.svg" alt="{{ 'buttons.back' | translate }}" (click)="goBack()">
    {{ 'dialog.presale.step2.title' | translate }}
    <img class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
  </h2>
  <div class="container">
    <!--<p translate>dialog.presale.step2.message</p>-->
    <div class="price-container">
      <ul class="presale-list">
        <li [class.is-selected]="selectedPresale === 0" (click)="selectPresale(0)">
          <h3 translate>dialog.presale.types.basic</h3>
          <img class="icon-egg" src="/assets/images/game/eggs/egg-{{ selectedType.type }}.png" alt="{{ selectedType.type }} Egg">
          <div class="presale-info">
            <h4 translate>dialog.presale.pay</h4>
            <div class="price-row">
              <span class="txt-price">
                <img class="icon-bnb" src="/assets/images/logo-bnb.svg" alt="BNB">
                {{ presaleInfo.basic.busdInBNB | fromWei | number: '1.2-2' }}$
              </span>
              <mat-icon class="ico-add">add</mat-icon>
              <span class="txt-price">
                <img class="icon-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
                {{ presaleInfo.basic.busdInBAMBOO | fromWei | number: '1.0-0' }}$
              </span>
            </div>
            <h4 [title]="'tooltip.moreinfopedigreeandtickets' | translate"  (click)="goToExternalLink('')">
              {{ 'dialog.presale.get' | translate }}   
              <!--<img class="ico-tutorial" src="/assets/icons/menu/tutorial.svg" alt="Tutorial">-->
            </h4>            
            <div class="price-row">
              <!--
              <span class="txt-price  txt-ticket">
                <img class="icon-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
                {{ presaleInfo.basic.ticketAmount | fromWei | number: '1.0-0' }}
              </span>
              -->
              <span class="txt-price">
                {{ 'experience.pedigree' | translate }} >
                {{ presaleInfo.basic.minPedigree }} 
              </span>
            </div>
          </div>
          <button class="btn-confirm  btn-next" [disabled]="selectedPresale === 0">
            {{ (selectedPresale === 0 ? 'buttons.selected' : 'buttons.select') | translate }}
          </button>
        </li>
        <li [class.is-selected]="selectedPresale === 1" (click)="selectPresale(1)">
          <h3 translate>dialog.presale.types.normal</h3>
          <img class="icon-egg" src="/assets/images/game/eggs/egg-{{ selectedType.type }}.png" alt="{{ selectedType.type }} Egg">
          <div class="presale-info">
            <h4 translate>dialog.presale.pay</h4>
            <div class="price-row">
              <span class="txt-price">
                <img class="icon-bnb" src="/assets/images/logo-bnb.svg" alt="BNB">
                {{ presaleInfo.normal.busdInBNB | fromWei | number: '1.2-2' }}$
              </span>
              <mat-icon class="ico-add">add</mat-icon>
              <span class="txt-price">
                <img class="icon-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
                {{ presaleInfo.normal.busdInBAMBOO | fromWei | number: '1.0-0' }}$
              </span>
            </div>
            <h4 [title]="'tooltip.moreinfopedigreeandtickets' | translate"  (click)="goToExternalLink('')">
              {{ 'dialog.presale.get' | translate }}   
              <!--<img class="ico-tutorial" src="/assets/icons/menu/tutorial.svg" alt="Tutorial">-->
            </h4>
            <div class="price-row">
              <span class="txt-price  txt-ticket">
                <img class="icon-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
                {{ presaleInfo.normal.ticketAmount | fromWei | number: '1.0-0' }}
              </span>
              <span class="txt-price">
                {{ 'experience.pedigree' | translate }} >
                {{ presaleInfo.normal.minPedigree }}
              </span>
            </div>
          </div>
          <button class="btn-confirm  btn-next" [disabled]="selectedPresale === 1">
            {{ (selectedPresale === 1 ? 'buttons.selected' : 'buttons.select') | translate }}
          </button>
        </li>
        <li [class.is-selected]="selectedPresale === 2" (click)="selectPresale(2)">
          <h3 translate>dialog.presale.types.premium</h3>
          <img class="icon-egg" src="/assets/images/game/eggs/egg-{{ selectedType.type }}.png" alt="{{ selectedType.type }} Egg">
          <div class="presale-info">
            <h4 translate>dialog.presale.pay</h4>
            <div class="price-row">
              <span class="txt-price">
                <img class="icon-bnb" src="/assets/images/logo-bnb.svg" alt="BNB">
                {{ presaleInfo.premium.busdInBNB | fromWei | number: '1.2-2' }}$
              </span>
              <mat-icon class="ico-add">add</mat-icon>
              <span class="txt-price">
                <img class="icon-bamboo" src="/assets/images/bamboo/logo-bamboo.svg" alt="Bamboo">
                {{ presaleInfo.premium.busdInBAMBOO | fromWei | number: '1.0-0' }}$
              </span>
            </div>
            <h4 [title]="'tooltip.moreinfopedigreeandtickets' | translate"  (click)="goToExternalLink('')">
              {{ 'dialog.presale.get' | translate }}   
              <!--<img class="ico-tutorial" src="/assets/icons/menu/tutorial.svg" alt="Tutorial">-->
            </h4>            
            <div class="price-row">
              <span class="txt-price  txt-ticket">
                <img class="icon-ticket" src="/assets/icons/icon-ticket.svg" alt="Ticket">
                {{ presaleInfo.premium.ticketAmount | fromWei | number: '1.0-0' }}
              </span>
              <span class="txt-price">
                {{ 'experience.pedigree' | translate }} >
                {{ presaleInfo.premium.minPedigree }}
              </span>
            </div>
          </div>
          <button class="btn-confirm  btn-next" [disabled]="selectedPresale === 2">
            {{ (selectedPresale === 2 ? 'buttons.selected' : 'buttons.select') | translate }}
          </button>
        </li>
      </ul>
    </div>
    <button class="btn-confirm  btn-next" (click)="buySpecieEgg()" [disabled]="selectedPresale === null" translate>
      buttons.buy.buy
    </button>
  </div>
</ng-template>