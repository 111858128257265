import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { getNFTStageInWallet } from '@graphql/queries/pet/query.graphql';
import { DialogService } from '@services/dialog/dialog.service';
import { GraphQLService } from '@services/graphql/graphql.service';
import { DialogPresaleComponent } from '@pages/home/components/dialog/dialog-presale/dialog-presale.component';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResultsComponent implements OnInit, OnDestroy {

  @Input() customClass = '';
  @Input() isPresale = false;
  @Input() isResultTournament = false;
  @Input() textData = '';
  @Input() type = '';

  public NFTSize = 0;
  private NFTSubscription: Subscription | undefined;
  private typesGroup: string[] = ['incubator','playground','school','gym'];

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private graphQLService: GraphQLService
  ) { }

  ngOnInit(): void {
    if (this.type.length > 0) {
      this.loadNFT();
    }
  }

  ngOnDestroy(): void {
    this.NFTSubscription?.unsubscribe();
  }

  async goTo(): Promise<void> {
    this.dialogService.closeAll();
    if (this.isPresale) {
      this.dialogService.openDialog(DialogPresaleComponent, 'confirmation-dialog-container');
    } else {
      await this.router.navigate(['/play/hashapon/']);
    }
  }

  openDialog(): void {
    this.dialogService.openPetsListDialog(this.router.url);
  }

  private loadNFT(): void {
    const stage: number = this.typesGroup.findIndex(val => val === this.type);
    this.NFTSubscription = this.graphQLService.getAll(getNFTStageInWallet, { ageStage: stage })
        .subscribe((data: any) => {
          this.graphQLService.checkErrors(data.errors);
          const usersInventories = data.data.usersInventories;
          if (usersInventories.length > 0) {
            this.NFTSize = data.data.usersInventories[0].petsInWallet?.length;
          }
        });
  }

}
