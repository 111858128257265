import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConnectionService } from '@services/connection/connection/connection.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { OracleApiService } from '@services/oracle-api/oracle-api.service';
import { UtilsService } from '@services/utils/utils.service';
import { PetShopContract, TicketERC20Contract } from '@static/abi';
import { contractAddresses } from '@static/contract-address';

@Component({
  selector: 'app-dialog-buy-process',
  templateUrl: './dialog-buy-process.component.html',
  styleUrls: ['./dialog-buy-process.component.scss']
})
export class DialogBuyProcessComponent implements OnInit {

  public isLoaded = false;
  public tickets: { [key: string]: any } = {
    minTickets: 0,
    myTickets: 0
  };
  public prices: { [key: string]: any } = {};

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private connectionService: ConnectionService,
    private nftService: NftService,
    private oracleApiService: OracleApiService,
    private utilsService: UtilsService
  ) { }

  async ngOnInit(): Promise<void> {
    if (!this.data.buyWithTickets) {
      await this.getPrices();
    } else {
      await this.getTickets();
      await this.getTicketsByAddress();
    }
  }

  close(approve = false): void {
    this.dialogRef.close(approve);
  }

  private async getPrices(): Promise<void> {
    const eggPrice: any = await this.nftService.getPrices(contractAddresses.petShop);
    const priceBamboo = await this.oracleApiService.convertBUSDToBAMBOO(eggPrice['usdAmountForBAMBOO']);
    const priceBNB = await this.oracleApiService.convertBUSDToBNB(eggPrice['usdAmountForBNB']);
    this.prices = {
      bamboo: priceBamboo,
      bambooDollars: eggPrice['usdAmountForBAMBOO'],
      bnb: priceBNB,
      bnbDollars: eggPrice['usdAmountForBNB']
    };
    this.isLoaded = true;
    console.log('Prices: ', eggPrice);
    console.log('Prices: Bamboo & BNB ', this.prices);
  }

  private async getTickets(): Promise<void> {
    const respTickets = await this.nftService.getTickets();
    if (respTickets) {
      this.tickets.minTickets = Number(this.utilsService.fromWei(respTickets.amountOfTicketsForEgg)).toFixed();
      console.log('NFT: Tickets', respTickets);
    }
  }

  private async getTicketsByAddress(): Promise<void> {
    const userAddress = this.connectionService.getWalletAddress();
    if (userAddress) {
      const respTickets = await this.nftService.getTicketsByAddress(userAddress);
      if (respTickets) {
        this.tickets.myTickets = Number(this.utilsService.fromWei(respTickets));
        this.isLoaded = true;
        console.log('NFT: Tickets Address ', this.tickets.myTickets);
      }
    }
  }

}
