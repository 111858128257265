<div class="dialog-container">
    <h2>
        {{ 'header.selectPlayer' | translate }}
        <app-close-button customClass="select-close"></app-close-button>
    </h2>
    
    <app-no-results *ngIf="NFTData?.length === 0 && !showLoader"></app-no-results>
    <app-loader *ngIf="showLoader"></app-loader>
    
    <div class="items-container">
        <ul [class.is-centered]="NFTData.length === 3" *ngIf="NFTData.length > 0 && !showLoader">
            <li [id]="player.tokenId" *ngFor="let player of NFTData; let i = index; trackBy: trackByID">
                <app-nft-card-player [data]="data" [player]="player">
                    <button class="btn-play">
                        {{ 'landing.navigation.play' | translate }}
                    </button>
                </app-nft-card-player>
            </li>
        </ul>
    </div>
</div>