import { Injectable } from '@angular/core';
import { waitForTransaction } from '@wagmi/core';

import { DialogConfirmationComponent } from '@dialogs/dialog-confirmation/dialog-confirmation.component';
import { DialogInfoComponent } from '@dialogs/dialog-info/dialog-info.component';
import { DialogInfoPopComponent } from '@dialogs/dialog-info-pop/dialog-info-pop.component';
import { IEvolutionInfo } from '@interfaces/evolution-info';
import { NFT } from '@interfaces/nft';
import { ConnectionService } from '@services/connection/connection/connection.service';
import { DialogService } from '@services/dialog/dialog.service';
import { NftService } from '@services/contracts/nft/nft.service';
import { TokenService } from '@services/contracts/token/token.service';
import { UtilsService } from '@services/utils/utils.service';
import { WalletMessagesService } from '@services/wallet-messages/wallet-messages.service';
import { contractAddresses } from '@static/contract-address';
import {
  ConfigStorageContract,
  CreatureContract,
  GymContract,
  IncubatorContract,
  OraclePricesContract,
  PlaygroundContract,
  SchoolContract
} from '@static/abi';

/**
 * 
 * $Start Dialog
 * $Get experience
 * $Get NFT limit
 * $Deposit NFT
 * $Get Method 
 * $Deposit Gym Methods
 * 
 */

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  private contractAdresses: string[] = [];
  private currentNFT: any;
  private currentPriceStage = 0;
  private currentStage = 0;
  private machine: any = null;
  private paidMode: any = null;
  private stageAddress: any;
  private userAddress = '';
  private readonly openedMyPetsClass = 'fix-center';
  private readonly AbiGroup: { [key: string]: any }[] = [
    { price: 0, type: IncubatorContract, address: contractAddresses.incubator },
    { price: 0, type: PlaygroundContract, address: contractAddresses.park },
    { price: 0, type: SchoolContract, address: contractAddresses.school },
    { price: 0, type: GymContract, address: contractAddresses.gym },
  ];

  constructor(
    private connectionService: ConnectionService,
    private dialogService: DialogService,
    private nftService: NftService,
    private tokenService: TokenService,
    private utilsService: UtilsService,
    private walletMessagesService: WalletMessagesService
  ) {
    this.contractAdresses = [
      contractAddresses.incubator,
      contractAddresses.park,
      contractAddresses.school,
      contractAddresses.gym
    ];
  }

  /*------------------------------------------------------------------------------------*\
    $Start Dialog
  /*------------------------------------------------------------------------------------*/

  /**
   * 
   * @param animal 
   * @param machine Machine number of the gym where you want add the pet
   * @param paidMode You can choose(Experience or Tokens). Only valid for the Gym
   * @param amount Number of times you can train your pet on a machine
   * 
   */

  async startDialog(
    nft: any,
    machine = null,
    paidMode: string | null | undefined = null,
    amount = 0
  ): Promise<void> {
    this.depositGymInfo(machine, paidMode);
    
    this.currentNFT = nft;
    this.currentStage = parseInt(nft.ageStage, 0);
    this.stageAddress = this.contractAdresses[this.currentStage];
    this.userAddress = this.connectionService.getWalletAddress();
    
    const stagePrice: any = await this.nftService.getPrices(this.stageAddress);
    const priceBNB = this.utilsService.fromWei(stagePrice.usdAmountForBNB);
    this.currentPriceStage = await this.connectionService.readContract(contractAddresses.oraclePrices, OraclePricesContract.abi, 'getBNBAmountByStable', [stagePrice.usdAmountForBNB.toString()]);
    
    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'deposit.age_' + this.currentStage + '.title', 
        message: 'deposit.age_' + this.currentStage + '.text', 
        button: 'actions.confirm',
        machine: this.machine,
        nft: this.currentNFT,
        price: this.utilsService.getPriceDollars(stagePrice.usdAmountForBAMBOO),
        priceBNB,
        start: true,
        amount
      }
    );
    confirmationDialog.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        try {
          const respApprove: boolean = await this.tokenService.tokenApprovement(
            this.stageAddress, this.userAddress, contractAddresses.bamboo, this.currentPriceStage.toString()
          );
          if (respApprove) {
            const respNFTApprove: boolean = await this.tokenService.nftCheckAllowance(
              this.stageAddress, contractAddresses.pet
            );
            if (respNFTApprove) {
              confirmationDialog.close();
              await this.depositNFT();
            }
          }
        } catch (error: unknown) {
          this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
          confirmationDialog.close();
          this.dialogService.openDialog(
            DialogInfoPopComponent,
            ['error-dialog-container', this.openedMyPetsClass],
            { type: 'error', phase: 'startError', animal: nft }
          );
        }
      }
    });
  }

  /*------------------------------------------------------------------------------------*\
    $Get experience
  /*------------------------------------------------------------------------------------*/

  async getAmountOfExpForGym(): Promise<any> {
    return await this.connectionService.readContract(
      contractAddresses.gym, GymContract.abi, 'amountOfExpForGym', []
    );
  }

  async startExperienceDialog(
    nft: any,
    machine = null,
    paidMode: string | null | undefined = null,
    amount = 0
  ): Promise<void> {
    this.depositGymInfo(machine, paidMode);
    
    this.currentNFT = nft;
    this.currentStage = parseInt(nft.ageStage, 0);
    this.stageAddress = this.contractAdresses[this.currentStage];
    this.userAddress = this.connectionService.getWalletAddress();
        
    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'deposit.age_' + this.currentStage + '.title', 
        message: 'deposit.age_' + this.currentStage + '.text', 
        button: 'actions.confirm',
        machine: this.machine,
        nft: this.currentNFT,
        amount
      }
    );
    confirmationDialog.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        try {
          confirmationDialog.close();
          await this.depositNFT();
        } catch (error: unknown) {
          this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
          confirmationDialog.close();
          this.dialogService.openDialog(
            DialogInfoPopComponent,
            ['error-dialog-container', this.openedMyPetsClass],
            { type: 'error', phase: 'startError', animal: nft }
          );
        }
      }
    });
  }

  /*------------------------------------------------------------------------------------*\
    $Get NFT limit
  /*------------------------------------------------------------------------------------*/

  async getPetLimit(stage: number): Promise<any> {
    const contractAddress = this.AbiGroup[stage].address;
    return await this.connectionService.readContract(
      contractAddresses.configStorage, ConfigStorageContract.abi, 'getPetLimit', [contractAddress]
    );
  }

  /*------------------------------------------------------------------------------------*\
    $Deposit NFT
  /*------------------------------------------------------------------------------------*/

  private async depositNFT(): Promise<void> {
    console.log('DEPOSIT NFT: Selected TokenID', this.currentNFT.tokenId);

    const dialogInfo = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'deposit.acceptTransaction.age_' + this.currentStage + '.title', 
        message: 'deposit.acceptTransaction.age_' + this.currentStage + '.message', 
        // button: 'actions.confirm'
      }
    );
    try {
      const method = this.getMethod();
      const tx = await this.connectionService.writeContract(
        this.AbiGroup[this.currentStage].address,
        this.AbiGroup[this.currentStage].type.abi,
        method.method,
        method.params,
        this.currentPriceStage.toString()
      );
      const resp = await waitForTransaction(tx);
      if (resp) {
        dialogInfo.close();
        this.depositIsSuccess();
      }
    } catch (error: any) {
      this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
      const message = this.walletMessagesService.getMessage(error.message, 'upgrading.errorForwarding.text');
      console.error('DEPOSIT NFT', error.message);
      dialogInfo.close();
      this.dialogService.openDialog(
        DialogInfoComponent,
        ['error-dialog-container', this.openedMyPetsClass],
        {
          type: 'error',
          title: 'upgrading.errorForwarding.title',
          nft: this.currentNFT,
          message
        }
      );
    }
  }

  private async depositIsSuccess(): Promise<void> {
    const stagePrice: any = await this.nftService.getPrices(this.stageAddress);
    const priceBNB = this.utilsService.fromWei(stagePrice.usdAmountForBNB);
    let evolutionInfo: IEvolutionInfo = await this.nftService.getEvolutionInfo(this.stageAddress);

    const confirmationDialog = this.dialogService.openDialog(
      DialogConfirmationComponent,
      ['confirmation-dialog-container', this.openedMyPetsClass],
      {
        title: 'deposit.depositSuccess.age_' + this.currentStage + '.title', 
        message: 'deposit.depositSuccess.age_' + this.currentStage + '.message', 
        // button: 'actions.confirm',
        machine: this.machine,
        nft: this.currentNFT,
        price: this.utilsService.getPriceDollars(stagePrice.usdAmountForBAMBOO),
        priceBNB,
        time: evolutionInfo.time,
        timeReduced: evolutionInfo.timeReduced
      }
    );
    // this.dialogService.openDialog(DialogInfoPopComponent, 'success-dialog-container', { type: 'success', phase: 'depositSuccess', animal: this.currentNFT });
    this.machine = null;
    this.paidMode = null;
    this.nftService.removeSavedNFT(Number(this.currentNFT.tokenId));
  }

  /*------------------------------------------------------------------------------------*\
    $Get Methods
  /*------------------------------------------------------------------------------------*/

  private getMethod(): any {
    const paidModeOptions: { [key: string]: any } = {
      experience: {
        method: 'depositWithExperience',
        params: [this.currentNFT.tokenId, this.machine]
      },
      tokens: {
        method: 'depositWithTokens',
        params: [this.currentNFT.tokenId, this.machine]
      }
    };
    switch(this.currentStage) {
      case 0:
        return {
          method: 'deposit',
          params: [this.currentNFT.tokenId]
        };
      case 1:
        return {
          method: 'parkDeposit',
          params: [this.currentNFT.tokenId]
        }
      case 2:
        return {
          method: 'schoolDeposit',
          params: [this.currentNFT.tokenId]
        }
      case 3:
        return {
          method: paidModeOptions[this.paidMode].method,
          params: paidModeOptions[this.paidMode].params
        }
    }
  }

  /*------------------------------------------------------------------------------------*\
    $Deposit Gym Methods
  /*------------------------------------------------------------------------------------*/

  private depositGymInfo(machine: number | null, paidMode: string | null): void {
    if (machine !== null && paidMode !== null) {
      this.machine = machine;
      this.paidMode = paidMode;
    }
  }

}
