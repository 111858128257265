import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';

import { NFT } from '@interfaces/nft';
import { NftService } from '@services/contracts/nft/nft.service';
import { STATIC_PRELOAD_TIME } from '@static/nft';

@Component({
  selector: 'app-dialog-select-player',
  templateUrl: './dialog-select-player.component.html',
  styleUrls: ['./dialog-select-player.component.scss']
})
export class DialogSelectPlayerComponent implements OnInit, OnDestroy {

  public showLoader = true;
  public NFTData: NFT[] = [];
  private NFTSubscription: Subscription | undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private NFTService: NftService,
  ) { }

  ngOnInit(): void {
    this.loadNFT();
  }

  ngOnDestroy(): void {
    this.NFTSubscription?.unsubscribe();
  }

  trackByID(index: number, NFT: NFT): string {
    return NFT.tokenId;
  }

  /*--------------------------------------------------------------------------------*\
   * $Load NFT
  /*--------------------------------------------------------------------------------*/

  private loadNFT(): void {
    this.NFTSubscription = this.loadNFTList()
        .subscribe((nftData: NFT[]) => {
          this.NFTData = nftData; 
          setTimeout(() => this.showLoader = false, STATIC_PRELOAD_TIME);
          console.log('TOURNAMENTS: Load NFT', this.NFTData);
        });
  }

  private loadNFTList(): Observable<NFT[]> {
    return this.NFTService.selectNFT();
  }

}
