import { Injectable } from '@angular/core';
import { WalletErrorMessage } from '@interfaces/wallet-connect';
import { STATIC_WALLET_MESSAGES } from '@static/wallet-messages';

@Injectable({
  providedIn: 'root'
})
export class WalletMessagesService {

  private readonly walletMessagesStatic: WalletErrorMessage[] = STATIC_WALLET_MESSAGES;

  constructor() { }

  getMessage(walletErrorMessage: string, customErrorMessage = ''): string {
    const findMessage = this.walletMessagesStatic.find((item: WalletErrorMessage) => walletErrorMessage.includes(item.value));
    if (findMessage) {
      return findMessage.key;
    }
    return customErrorMessage;
  }

}
