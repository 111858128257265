<h2 [class.is-error]="data.type === 'error'">
  <span translate [translateParams]="{ name: nft.metadata.uriString?.name }" *ngIf="nft?.metadata; else tplTitle">
    {{ title }}
  </span>
  <img *ngIf="data.type !== 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-red.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
  <img *ngIf="data.type === 'error'" class="ico-close" src="/assets/icons/buttons/ico-close-white.svg" alt="{{ 'actions.close' | translate }}" (click)="close()">
</h2>
<p translate [translateParams]="{ name: nft.metadata.uriString?.name }" *ngIf="nft?.metadata; else tplMessage">
  {{ message }}
</p>

<!-- Templates -->

<ng-template #tplTitle>
  <span translate>{{ title }}</span>
</ng-template>

<ng-template #tplMessage>
  <p translate>{{ message }}</p>
</ng-template>
